import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews-and-ratings',
  templateUrl: './reviews-and-ratings.component.html',
  styleUrls: ['./reviews-and-ratings.component.css']
})
export class ReviewsAndRatingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
