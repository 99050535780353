import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pg-hostel-list',
  templateUrl: './pg-hostel-list.component.html',
  styleUrls: ['./pg-hostel-list.component.css']
})
export class PgHostelListComponent implements OnInit {
  @Input() allListings: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
