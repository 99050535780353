<div class="mb-dashboard">
    <div class="container">
      
      <div class="mb-profile-card">
        <h2>CIBIL Score</h2>
        <div class="mb-edit-profile">

          <form class="row g-3">
            <div class="mb-profile-name">
              <div class="row g-2">
                <div class="col-12">
                  <label for="PAN Number">PAN Number</label>
                  <input type="text" class="form-control" placeholder="PAN Number" aria-label="PAN Number">
                </div>
                <div class="col-12">
                  <label for="Name">Name</label>
                  <input type="text" class="form-control" placeholder="Name" aria-label="Name">
                  <small><i>As per PANCARD</i></small>
                </div>
                <div class="col-12">
                  <label for="Name">Mobile Number</label>
                  <input type="text" class="form-control" placeholder="Mobile Number" aria-label="Mobile Number">
                  <small><i>Linked with PANCARD</i></small>
                </div>
                <div class="col-12">
                  <label for="Required Loan Amount">Required Loan Amount</label>
                  <input type="text" class="form-control" placeholder="Required Loan Amount" aria-label="Required Loan Amount">
                </div>
                <div class="col mb-attached">
                  <button class="btn btn-primary"><i class="ri-add-line"></i> Choose File</button>
                  <h6>(Attach PAN CARD) - jpeg image , 20kb size</h6>
                </div>
                <div class="col-12">
                  <button class="btn btn-success rounded-pill">Submit</button>
                  <p class="btn btn-light rounded-pill addbtn mb-0"><i class="ri-add-line"></i> Add</p>
                </div>
              </div>
              <div class="overlay"></div>
              <div class="mb-cibil-popup">
                <span id="closePopup" class="close-btn">&times;</span>
                <div class="row g-2">
                  <div class="col-12">
                    <label for="PAN Number">PAN Number</label>
                    <input type="text" class="form-control" placeholder="PAN Number" aria-label="PAN Number">
                  </div>
                  <div class="col-12">
                    <label for="Name">Name</label>
                    <input type="text" class="form-control" placeholder="Name" aria-label="Name">
                    <small><i>As per PANCARD</i></small>
                  </div>
                  <div class="col-12">
                    <label for="Name">Mobile Number</label>
                    <input type="text" class="form-control" placeholder="Mobile Number" aria-label="Mobile Number">
                    <small><i>Linked with PANCARD</i></small>
                  </div>
                  <div class="col-12">
                    <label for="Required Loan Amount">Required Loan Amount</label>
                    <input type="text" class="form-control" placeholder="Required Loan Amount" aria-label="Required Loan Amount">
                  </div>
                  <div class="col mb-attached">
                    <button class="btn btn-primary"><i class="ri-add-line"></i> Choose File</button>
                    <h6>(Attach PAN CARD) - jpeg image , 20kb size</h6>
                  </div>
                  <div class="col-12">
                    <button class="btn btn-success rounded-pill">Submit</button>
                  </div>
                </div>
              </div>
            </div>
            
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="all-leads">
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div class="mb-3">
                          <h3>CIBIL SCORES </h3>
                            <div class="right-search">
                              <input type="text" class="form-control mb-3 rounded-4"placeholder="Search">
                                <label for="Sort by:">Sort by:</label>
                                <select class="form-control  rounded-4 mt-1">
                                    <option>Monthly</option>
                                    <option>Weekly</option>
                                    <option>Daily</option>
                                </select>
                            </div>
                        </div>
                       
                        <div class="table-head-fixed">
                            <table class="table table-striped">
                              <thead class="table-dark">
                                  <tr>
                                      <th><input type="checkbox" class="form-check-input"></th>
                                      <th>Client Name</th>
                                      <th>Date Added</th>
                                      <th>Mobile Number</th>
                                      <th>PAN Number</th>
                                      <th>Required Loan Amount</th>
                                      <th>Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td><input type="checkbox" class="form-check-input"></td>
                                      <td>Priya Kumar</td>
                                      <td>15-03-2024</td>
                                      <td>81475 35541</td>
                                      <td>AAAPZ12XXX</td>
                                      <td>25,80,000/-</td> 
                                      <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                                  <tr>
                                    <td><input type="checkbox" class="form-check-input"></td>
                                    <td>Priya Kumar</td>
                                    <td>15-03-2024</td>
                                    <td>81475 35541</td>
                                    <td>AAAPZ12XXX</td>
                                    <td>25,80,000/-</td> 
                                    <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                  </tr>
                              </tbody>
                            </table>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
      
    </div>
    
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>