import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interior-design-enquiry',
  templateUrl: './interior-design-enquiry.component.html',
  styleUrls: ['./interior-design-enquiry.component.css']
})
export class InteriorDesignEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
