<ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center my-3" href="dashboard.html">
        <div class="sidebar-brand-icon">
            <img src="assets/img/apple-touch-icon.png" alt="">
        </div>
        <div class="sidebar-brand-text">
            <img src="assets/img/logo.png" alt="log" class="img-fluid">
        </div>
    </a>

    <!-- <ng-container *ngFor="let menu of menuOptions">
        
            <li class="nav-item" [ngClass]="{'active': menu.active}">
                <a  class="nav-link" (click)="openMenuItem(menu)">
                    <img  class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                    {{menu.label}}
                    
                </a>
            </li>
            <ng-container class="list_inner" *ngIf="menu.submenuList" [ngStyle]="{'display': menu.active ? 'block': 'none'}">
                <li class="nav-item" *ngFor="let submenu of menu.submenuList" [ngClass]="{'active': submenu.active}">
                    <img  class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;

                    <a class="nav-link" (click)="openSubmenuItem(menu,submenu)">{{submenu.label}}</a>
                </li>
            </ng-container>
        
    </ng-container> -->
  <ng-container *ngFor="let menu of menuOptions">
    <li class="nav-item" [ngClass]="{'active': menu.active}">
        <a class="nav-link" (click)="openMenuItem(menu)">
            <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
            {{menu.label}}
            <span *ngIf="menu.label === 'Nexus AddOn'" (click)="isNexusAddonOpen = !isNexusAddonOpen">
                <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}"></i>
            </span>
        </a>

        <ul *ngIf="menu.label === 'Nexus AddOn' && isNexusAddonOpen" class="submenu-list">
            <li *ngFor="let submenu of menu.submenuList" class="nav-item"  [ngClass]="{'active': submenu.active}">
                <a class="nav-link" (click)="openSubmenuItem(menu, submenu)">
                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;

                    {{submenu.label}}
                </a>
            </li>
        </ul>
    </li>
</ng-container>

    
   
    <!-- <li class="nav-item" *ngFor="let menu of menuOptions">
        <a class="nav-link">
            <i class="ri-dashboard-fill"></i>
            <span> {{menu.label}}</span>
        </a>
          
    </li> -->
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle">
            <i class="ri-arrow-left-s-line"></i>
        </button>
    </div>
</ul>