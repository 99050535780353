import { Component, OnInit, ElementRef, ViewChild, NgZone, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SeoService } from 'src/app/common/services/seo.service';
import { HttpParams } from '@angular/common/http';
import { SharePopUpComponent } from '../share-pop-up/share-pop-up.component';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
import { RequestSiteVisitPopupComponent } from '../request-site-visit-popup/request-site-visit-popup.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-project-listings',
  templateUrl: './project-listings.component.html',
  styleUrls: ['./project-listings.component.css']
})
export class ProjectListingsComponent implements OnInit {
  private cityTextRef: ElementRef;
  getPlanId:any;
  @ViewChild('cityEntryBox') cityEntryBox!: ElementRef;
  cities :any=[];
  @ViewChild('cityTextRef') set getCityEle(cityTextRef: ElementRef) {
    this.cityTextRef = cityTextRef;
    if (this.cityTextRef && this.lookupModel.isBrowser) {
     // this.prepareCityAutoComplete();
    // if(!this.cities.length && this.searchInt){
      //console.log(this.lookupModel.getCurrentLocation());
      // let obj = this.lookupModel.getCurrentLocation();
      // let latlang = {lat:obj.latitude,lng:obj.longitude};
      // this.selectedCity.cityName = obj.city;
      if(!this.cities || !this.cities.length){
     let lat = this.queryParams.lat.split(",");
     let lng = this.queryParams.long.split(",");
     let loc = this.queryParams.location.split(",");
     let pin = this.queryParams.zip.split(",");
     for(var x=0;x<lat.length;x++){
     let obj={
       cityName: !x ? this.queryParams.city:loc[x-1],
       macroCity: !x ? this.queryParams.city:loc[x-1],
       cityBounds: !x ? JSON.parse(this.queryParams.cityBounds):"",
       zip: pin[x],
       lat: lat[x],
       long: lng[x],
     }
     this.cities.push(obj);
     }
     //console.log(this.cities);
      this.geoCoder = new google.maps.Geocoder;
      this.requestPlace={
        componentRestrictions: {
          country: this.lookupModel.selectedCountry.twoDigitCountryCode,
        },
        types: this.cities.length ? ['geocode'] : ['(cities)'],
        bounds: this.cities[0].cityBounds,
        strictBounds: true
      }
      this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, this.requestPlace);
      this.cityAutocomplete.addListener('place_changed', this.changeEvent);
    }
     // this.getAddress(latlang); 
  }
    //}
  }
  private localityTextRef: ElementRef;
  private requestPlace :any;
  subCatName:any=[];
  searchboxFocus:boolean=true;
  searchMore: boolean;
  searchInt : boolean=true;
  @ViewChild('localityTextRef') set getLocalityEle(localityElement: ElementRef) {
    if (localityElement && this.lookupModel.isBrowser) {
      this.localityTextRef = localityElement;
    }
  }
  private geoCoder;
  private cityAutocomplete: any;
  private localityAutocomplete: any;
  isMobileLayout: boolean = false;
  mobilePopup:Boolean = false;
  cityBounds: any;
  firstTwoValues : any[] = [];
  lastValues:any[] = [];
  selectedcodeType: string;
  listingContents: number = 0;
  nearByListingContents: number = 0;
  minDistance: Number = 1000;
  maxDistance: Number = 20000;
  listings: Array<any> = [];
  catList: Array<any> = [];
  attrList: Array<any> = [];
  subCatList: Array<any> = [];
  selBudgetCount:any=[];
  bugetList: Array<any> = [];
  selectedCity: any;
  propertyObj:any;
  selectedLocality: any;
  selectedProp:boolean=false;
  selectedProjectIds: string[] = [];
  selectedPropCount:any;
  properties:any;

  filterForm: FormGroup;
  selectedCategory: any;
  selectedSubCat: any;
  selectedBudget: any;
  selectedAttribute: any;
  selectedModule: any;
  showViewFlag: boolean = false;
  p: number = 1;
  count:number =0;
  queryParams: any;
  vendorTypes:[];
  sortValue: string = '';
  groupedRecords: any[];
  allListings: any[] = [];
  selectedSubCatList  = [];
  selAttributeProperty = [];
  listingLength: any;
  attributeCols: string;
  text: any = '';
  textNew: any = '';
  urlHeading: any;
  showMoreProperties: Boolean = false;
  isDropdownVisible: boolean = false;
  isSubCategoryDisplay:boolean = false;
  isBudgetDisplay:boolean = false;
  morePropertyListing:any = {};
  showFullContent: boolean[] = [];
  copyMessage:string="";
  favoriteActive:boolean=false;
  favoriteStates: { [key: string]: boolean } = {};
  isFixed = false;


  constructor(
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private fb: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.listings.forEach(() => this.showFullContent.push(false));
  }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    this.getPlanId=this.lookupModel.getLoggedUserPersonalDetails().planId;
    if (this.isMobileLayout) {
      if (!this.cities || !this.cities.length) {
        let lat = this.queryParams.lat.split(",");
        let lng = this.queryParams.long.split(",");
        let loc = this.queryParams.location.split(",");
        let pin = this.queryParams.zip.split(",");
        for (var x = 0; x < lat.length; x++) {
          let obj = {
            cityName: !x ? this.queryParams.city : loc[x - 1],
            macroCity: !x ? this.queryParams.city : loc[x - 1],
            cityBounds: !x ? JSON.parse(this.queryParams.cityBounds) : "",
            zip: pin[x],
            lat: lat[x],
            long: lng[x],
          }
          this.cities.push(obj);
        }
      }
    }
    this.selectedCategory = this.lookupModel.getCategory();
    this.catList = this.lookupModel.getCategoryList();
    if (!this.catList || !this.selectedCategory || !this.selectedCategory.categoryId) {
      this.fetchDetailsFromUlr(this.queryParams).subscribe((rsp) => {
        if(rsp) {
          this.loadDetails();
        }
      }, (err) => {
        console.log("coming into error",err);
        this.router.navigate(['dashboard']);
        return;
      });
    } else {
      this.loadDetails();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.checkScroll();
  }

  checkScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.isFixed = scrollPosition > 100; 
  }
 

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  ngAfterViewInit() {
    
  }
  enableSubCategoryMenu() : void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isBudgetDisplay = false
    this.isSubCategoryDisplay = !this.isSubCategoryDisplay
  }
  enableBudgetMenu() : void {
    this.attrList.forEach((obj) => {
      //delete obj['isAttrDisplay'];
      obj.isAttrDisplay=false;
    })
    this.isSubCategoryDisplay = false
    this.isBudgetDisplay = !this.isBudgetDisplay
  }

  enableFilters(findex:number): void {
    this.isSubCategoryDisplay = false
    this.isBudgetDisplay = false
    this.attrList.forEach((obj ,index) => {
      findex != index ? obj.isAttrDisplay=false : "";
    })
    this.attrList[findex]['isAttrDisplay'] = !this.attrList[findex]['isAttrDisplay']
  }

  getHighlightElements(locationHighlightsNew) : string {
    let firstNonEmptyHighlight: { key: string; value: any } | null = null;
    const highlights = Object.entries(locationHighlightsNew || {}) as [string, any][];

    const foundHighlight = highlights.find(
      ([key, value]: [string, any]) => value && value !== null
    );
    
    if (foundHighlight) {
      firstNonEmptyHighlight = { key: foundHighlight[0], value: foundHighlight[1] };
    }

    return firstNonEmptyHighlight.value;
  }

  morePopup(property?:any): void {
    this.morePropertyListing = property
    this.mobilePopup = !this.mobilePopup
  }

  groupByAttributeName(attributes: any[]) {
    debugger;
    return attributes.reduce((result, currentValue) => {
      const attributeName = currentValue.attributeName;
      if (!result[attributeName]) {
        result[attributeName] = {
          attributeId: currentValue.attributeId,
          properties: []
        };
      }
      result[attributeName].properties.push(currentValue);
      return result;
    }, {});
  }

  filterBySubCategory(index: any,getListings?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    this.subCatList[index].isSelected = !this.subCatList[index].isSelected;
    //subCat['isSelected'] = !subCat['isSelected'];
    if(this.subCatList[index].isSelected){
      this.subCatName.push(this.subCatList[index].subCategoryName);
    } else {
      var eleInd = this.subCatName.indexOf(this.subCatList[index].subCategoryName);
      this.subCatName.splice(eleInd,1);
    }
    //this.selSubCatCount=this.subCatList[index].isSelected ? this.selSubCatCount + 1 : this.selSubCatCount - 1;
    console.log("getlistings is",this.subCatList[index]);
    if (getListings) {
      this.getPropertyListings();
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if(this.cityEntryBox && !this.searchboxFocus){
    if (!this.cityEntryBox.nativeElement.contains(event.target)) {
      // Click occurred outside the element
      this.searchboxFocus=true;
      this.searchMore = this.cities.length>1 && this.searchboxFocus ? true:false;
    }
  }
  }
  startSearch():void{
    this.searchboxFocus=false;
    this.searchMore = this.cities.length>1 && this.searchboxFocus ? true:false;
  }
  removeEle(index:number){
    this.cities.splice(index, 1);
    this.searchInt = false;
    if(this.cities.length<4){
      this.cityTextRef.nativeElement.disabled=null;
      }
    if(!this.cities.length){
      this.cityTextRef.nativeElement.placeholder="Search by Location, Project Name";
      //this.cityAutocomplete.unbind("bounds");
      this.cityAutocomplete.setTypes(['(cities)']);
      //this.cityAutocomplete.setBounds({});
      this.cityAutocomplete.setOptions({
        strictBounds: false,
      });
    }
  }
  changeEvent=():void =>{
    // this.ngZone.run(() => {
       //get the place result
       debugger;
       let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

       //verify yt67
       if (!place || place.geometry === undefined || place.geometry === null) {
         return;
       }
       if (place.geometry && place.geometry.location) {
         let viewport = place.geometry.viewport;
         this.selectedCity = {
           cityName: place.name,
           macroCity: place.address_components[1].long_name,
           cityBounds: place.geometry.viewport,
           zip: '',
           lat: place.geometry.location.lat(),
           long: place.geometry.location.lng(),
 
         }
         this.cityBounds = place.geometry.viewport;
        // this.cityAuto.setValue(place.name);
         this.cities.push(this.selectedCity);
         this.city.setValue("");
         this.cityTextRef.nativeElement.value="";
       if(this.cities.length==1){
         this.cityTextRef.nativeElement.placeholder="Add more...";
         this.cityAutocomplete.unbind("bounds");
         this.cityAutocomplete.setTypes(["geocode"]);
         this.cityAutocomplete.setBounds(viewport);
         this.cityAutocomplete.setOptions({
           strictBounds: true,
         });
       }
       if(this.cities.length==4){
         this.cityTextRef.nativeElement.disabled="disabled";
         //this.cityTextRef.nativeElement.style="{background:'#c1c1c1'}";
       }
     }
     this.getAddress({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}).subscribe((rsp) => {
      this.getPropertyListings();
    });
       //this.getAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
       // this.submit();
     //});
 }

//  private baseUrl = 'https://pillarblocks.com/associate/';
//  url(prop: any, queryParams: any): string {
//    console.log("query params", queryParams);
//    const baseUrl = 'https://pillarblocks.com/projects/'+queryParams.city+'/'+prop.projectName+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+prop.projectId;
//    let httpUrl = baseUrl;
//   //  if (queryParams) {
//   //    let params = new HttpParams();
//   //    for (let key in queryParams) {
//   //      if (queryParams.hasOwnProperty(key)) {
//   //        params = params.set(key, queryParams[key]);
//   //      }
//   //    }
//   //    httpUrl += `?${params.toString()}`;
//   //  }
//    return httpUrl;
//  }
 shareSingleProp(property:any){
  const projectId=property.projectId;
  const baseUrl = 'https://pillarblocks.com/projects/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+projectId;
  const modelRef =this.modalService.open(SharePopUpComponent);
  modelRef.componentInstance.url = baseUrl

}
 favoriteSingleProp(property:any){
  this.favoriteStates[property.projectId] = true;
  this.selectedProjectIds.push(property.projectId);

  this.favoriteActive=true;
  const args={
    associateId:this.lookupModel.getLoggedUser().associateId,
    selectedProjectIds:this.selectedProjectIds
  }
  // alert(JSON.stringify(args))

  this.rsbService.favoriteProjects(args).subscribe((res)=>{
    // alert(res)
  })
  this.router.navigate(['favorite-projects'])

}
 choosePlanForShare(property:any){
  const firstName=this.lookupModel.getLoggedUser().personalDetails.firstName;
  const propImage=property.propertyPreviewImage

  // this.router.navigate(['choosePlan'])
  Swal.fire({
    title: "Hello!"+firstName,
    html: 'Please Subscribe any <span style="color:red">Plan</span> for sharing of properties',
    // imageUrl: propImage,
    imageWidth: 400,
    imageHeight: 200,
    imageAlt: "Custom image",
     confirmButtonText: 'Plans'

  }).then((result) => {
    if (result.isConfirmed) {
      this.router.navigate(['/choosePlan']);
    }
  });
}

cancelSelection() {
  this.selectedProjectIds = [];
  this.selectedPropCount = 0;
  this.selectedProp = false;
  this.listings.forEach(property => {
    property.selected = false;
  });
}
 
  fetchDetailsFromUlr(params: any): Observable<any> {
    return Observable.create(observer => {
      this.rsbService.getActiveCategories(params.module).subscribe((rsp) => {
        if(rsp.statusCode === 0 && rsp.contents){
          this.catList = rsp.contents;
          this.lookupModel.setCategoryList(this.catList);
          const cat = this.catList.find(item => item.categoryId === params.cat);
          if(cat) {
            this.selectedCategory = cat;
            this.lookupModel.setCategory(cat);

            if (params.subcat) {
              const subCatArray = this.queryParams.subcat.split(',');
              this.subCatName =[];
              //this.selSubCatCount=subCatArray.length;
              this.selectedCategory.subCategory.forEach((item,index) => {
                if (subCatArray.includes(item.subCategoryId) === true) {
                  this.subCatName.push(item.subCategoryName);
                  item["isSelected"] = true;
                }
              });
              if (this.queryParams.subcat) {
                this.lookupModel.setSubCategory(this.queryParams.subcat && this.queryParams.subcat.includes(',') ? this.queryParams.subcat.split(',') : [this.queryParams.subcat]);
              }
            }
            if (this.queryParams.city) {
              const city = { cityName: this.queryParams.location, macroCity: this.queryParams.city, zip: this.queryParams.zip, cityBounds: null, lat: 0, long: 0 };
              if (this.queryParams.lat) {
                city.lat = parseFloat(this.queryParams.lat);
                city.long = parseFloat(this.queryParams.long);
              }
              this.lookupModel.setCity(city);
  
            }
            observer.next('success');
            observer.complete();
          } else {
            observer.error();
          }
        } else {
          this.catList = [];
          observer.error();
        }
      }, (err) => observer.error());
    });
  }

  loadDetails(): void {
    this.selectedModule =  this.lookupModel.getModuleList().find((obj) => {
      return obj.moduleId === this.queryParams.module;
    }) ;
    if(this.queryParams.subcat) {
      const subCatArray = this.queryParams.subcat.split(',');
      this.subCatName =[];
      //this.selSubCatCount=subCatArray.length;
      this.selectedCategory.subCategory.forEach((item,index) => { 
       if(subCatArray.includes(item.subCategoryId) === true) {
          item["isSelected"] = true;
          this.subCatName.push(item.subCategoryName);
       }
      });
      if(this.queryParams.subcat) {
         this.lookupModel.setSubCategory(this.queryParams.subcat && this.queryParams.subcat.includes(',') ? this.queryParams.subcat.split(','): [this.queryParams.subcat]);
      }
      
      //console.log("subCategoryArray  is",this.selectedCategory.subCategory);
    }
    this.selectedcodeType = this.lookupModel.getCountry().codeType;
    //this.subCatList = [{ subCategoryName: 'All ' + this.selectedCategory.categoryName, subCategoryId: '' }];
    this.subCatList = this.selectedCategory.subCategory;
    this.selectedCity = this.lookupModel.getCity();
    if (this.selectedCity) {
      this.cityBounds = this.selectedCity.cityBounds;
    }
    if (this.lookupModel.getLocality()) {
      this.selectedLocality = this.lookupModel.getLocality();
    }
    this.filterForm = this.fb.group({
      cat: [this.selectedCategory ? this.selectedCategory.categoryId : ''],
      subCat: [this.selectedSubCat ? this.selectedSubCat.subCategoryId : ''],
      //city: [this.selectedCity && this.selectedCity.cityName ? this.selectedCity.cityName : ''],
      city:[''],
      locality: [this.selectedLocality ? this.selectedLocality.localityName : ''],
      budget: [''],
      beds: ['']
    });
    this.getFilterProperties();
    this.getPropertyListings();
    this.locality.disable();
    this.showViewFlag = true;
    this.formChanges();
  }

  formChanges(): void {
    this.cat.valueChanges.subscribe(val => {
      if (val) {
        this.selectedCategory = this.catList.find(cat => cat.categoryId === val);
        this.lookupModel.setCategory(this.selectedCategory);
        this.subCatList = [{ subCategoryName: 'All ' + this.selectedCategory.categoryName, subCategoryId: '' }];
        this.subCatList = this.subCatList.concat(this.selectedCategory.subCategory);
        this.subCat.reset();
        this.selectedSubCat = null;
        // this.getFilterProperties();
        this.getPropertyListings();
      }
    });

    this.subCat.valueChanges.subscribe(val => {
      // if (val) {
      this.selectedSubCat = this.subCatList.find(cat => cat.subCategoryId === val);
      this.lookupModel.setSubCategory(this.selectedSubCat);
      this.getFilterProperties();
      this.getPropertyListings();
      // }
    });

    // this.city.valueChanges.subscribe((val) => {
    //   if (!val) {
    //     this.selectedCity = {};
    //     this.getPropertyListings();
    //     // this.toastrService.warning('Please enter city');
    //   }
    // });

    this.locality.valueChanges.subscribe((val) => {
      if (!val) {
        this.selectedLocality = null;
        this.getPropertyListings();
      }
    });

    this.beds.valueChanges.subscribe(val => {
      if (val) {
        this.selectedAttribute = val
        this.getPropertyListings();
      } else {
        this.selectedAttribute = null;
        this.getPropertyListings();
      }
    });

    this.budget.valueChanges.subscribe(val => {
      if (val) {
        this.selectedBudget = this.bugetList.find(budget => budget.budgetId === val);
        this.getPropertyListings();
      } else {
        this.selectedBudget = null;
        this.getPropertyListings();
      }
    });
  }

  getLocationLength(obj): number {
    let count = 0;
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '' && key != "aboutLocality") {
        count++;
      }
    }
    
      // this.getFirstTwoNonNullValues(obj)
      // this.getLastNonNullValues(obj);
    return count;
  }

  getFirstTwoNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        if(count <2) { 
        this.firstTwoValues.push({ key: key, value: obj[key] });
        }
        count++;
        if (count === 2) {
          break; // Exit the loop after finding the first two non-null values
        }
      }
    }
  }
  selectedCheckProp(property: any) {
    // alert(JSON.stringify(property));
this.propertyObj=property;
    console.log(property);
    // this.selectedProp = this.listings.some(p => p.selected);
    const selectedListingId = property.projectId; 

    if (property.selected) {
      if (!this.selectedProjectIds.includes(selectedListingId)) {
        this.selectedProjectIds.push(selectedListingId);
      }
    } else {
      const index = this.selectedProjectIds.indexOf(selectedListingId);
      if (index !== -1) {
        this.selectedProjectIds.splice(index, 1);
      }
    }
    this.selectedPropCount = this.selectedProjectIds.length; 

    this.selectedProp = this.selectedProjectIds.length > 0;
    // alert(this.selectedProjectIds)
    this.lookupModel.setSelectedPropListingIDs(this.selectedProjectIds);
  

  }
  sharePopUp(){
    if(this.selectedProjectIds.length>1){
      const baseUrl = 'https://pillarblocks.com/projects/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+this.selectedProjectIds.join(",");
      const modelRef =this.modalService.open(SharePopUpComponent);
      modelRef.componentInstance.url = baseUrl
      // alert(" gettingg"+baseUrl)
    }
    else{
    const replaceStr=this.propertyObj.projectName;
     const projectName=replaceStr.replace(/ /g, '-');
    const baseUrl = 'https://pillarblocks.com/projects/'+this.queryParams.city+'/'+projectName+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+this.selectedProjectIds.join(",");
    const modelRef =this.modalService.open(SharePopUpComponent);
    modelRef.componentInstance.url = baseUrl
    // alert(" gettingg"+baseUrl)

    }

  }
  favorites(){
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      selectedProjectIds:this.selectedProjectIds
    }
    // alert(JSON.stringify(args))

    this.rsbService.favoriteProjects(args).subscribe((res)=>{
      // alert(res)
    })
  }
  copyLink() {
    let baseUrl: string;
    if(this.selectedProjectIds.length>1){
      baseUrl = 'https://pillarblocks.com/projects/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+this.selectedProjectIds.join(",");
      
    }
    else{
    const replaceStr=this.propertyObj.projectName;
    const projectName=replaceStr.replace(/ /g, '-');
    baseUrl = 'https://pillarblocks.com/projects/'+this.queryParams.city+'/'+projectName+'/'+this.lookupModel.getLoggedUser().associateId+'?projectId='+this.selectedProjectIds.join(",");
   
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(baseUrl).then(() => {
        this.copyMessage = 'Link copied!'; 
        this.clearCopyMessageAfterDelay(); 
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = baseUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.copyMessage = 'Link copied!'; 
      this.clearCopyMessageAfterDelay(); 
    }

    // navigator.clipboard.writeText(baseUrl).then(() => {
    // this.copyMessage="Link Copied!"
    // }).catch(err => {
    //   console.error('Could not copy text: ', err);
    // });
  }
  clearCopyMessageAfterDelay() {
    setTimeout(() => {
      this.copyMessage = ''; 
    }, 2000); 
  }
  

  getLastNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        count++;
        if (count >= 2) {
          this.lastValues.push({ key: key, value: obj[key] });// Exit the loop after finding the first two non-null values
        }
      }
    }
  }
  
  

  getFilterProperties(): void {
    this.rsbService.getAllAttribtesForWebsiteFilters().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.attrList = rsp.contents;
        this.attrList.map(item => item.setAttrCount=[]);
        const attr = this.attrList.find(item => item.isDefault);
        if (attr) {
          this.beds.setValue(attr.attributePropertyId);
        }
      } else {
        this.attrList = [];
      }
    });
    this.rsbService.getActiveBudgetsForFilters().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.bugetList = rsp.contents;
        const budget = this.bugetList.find(item => item.isDefault);
        if (budget) {
          this.budget.setValue(budget.budgetId);
        }
      } else {
        this.bugetList = [];
      }
    });
  }

  prepareCityAutoComplete(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, {
        componentRestrictions: {
          country: this.lookupModel.selectedCountry ? this.lookupModel.selectedCountry.twoDigitCountryCode : ''
        },
        // bounds: this.cityBounds,
        strictBounds: true
      });
      this.cityAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

          //verify result
          if (!place || place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.selectedCity = {
            cityName: place.name,
            macroCity: place.address_components[1].long_name,
            cityBounds: place.geometry.viewport,
            zip: '',
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng(),
          };
          this.getAddress({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}).subscribe((rsp) => {
            this.getPropertyListings();
          });
          // this.locality.reset();
          // if (this.localityAutocomplete) {
          //   this.localityAutocomplete.bounds = this.cityBounds;
          // } else {
          //   this.prepareLocalityAutoComplete();
          // }
        });
      });
    });
  }

  prepareLocalityAutoComplete(): void {
    this.locality.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.localityAutocomplete = new google.maps.places.Autocomplete(this.localityTextRef.nativeElement, {
        componentRestrictions: {
          country: this.lookupModel.selectedCountry ? this.lookupModel.selectedCountry.twoDigitCountryCode : ''
        },
        bounds: this.cityBounds,
        strictBounds: true
      });
      this.localityAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.localityAutocomplete.getPlace();

          //verify result
          if (!place || place.geometry === undefined || place.geometry === null) {
            return;
          }
          // this.cityBounds = place.geometry.viewport;
          this.locality.setValue(place.name);
          this.selectedLocality = {
            localityName: place.name,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          };
          this.getPropertyListings();
        });
      });
    });
  }
  toggleContent(index: number) {
    this.showFullContent[index] = !this.showFullContent[index];
  }

  openProperty(property: any): void {
    this.lookupModel.setProperty(property);
    this.lookupModel.setCategory(this.selectedCategory);
    this.lookupModel.setSubCategory(this.selectedSubCat);
    if (this.selectedLocality && this.selectedLocality.localityName) {
      this.lookupModel.setLocality(this.selectedLocality);
    }
    this.lookupModel.setCity({ name: this.city.value, bounds: this.cityBounds });
    const text = property.projectName.replaceAll(' ', '-')
    this.router.navigate(
      ['project-description', text],
      {
        queryParams: {
          projectId: property.projectId,
          cat: property.categoryId,
          subcat: property.subCategoryId,
          module: property.moduleId,
          country: property.countryId ? property.countryId : this.lookupModel.getCountry().codeId,
          city:property.city,
          area:property.area,
          pincode:property.pincode,
        }
      }
    );
  }

  updateScrollPos(event: any): void {
    if(event.endReached) {
    let listingIds = this.listings.map((obj) => {
      return obj.listingId
    })
    console.log('User is near bottom uppending rows');
    //let newList = this.allListings.slice(this.listings.length, this.listings.length + 20);
    //this.listings = this.listings.concat(newList);
    //this.cdr.detectChanges();
    if(this.cities.length){
    let location="",pincode="",lat="",long="";
    this.cities.filter((item,index)=>{ 
        index ? location = location ? location + "," + item.cityName : item.cityName :"";
        pincode = pincode ? pincode + "," + item.zip : item.zip;
        lat = lat ? lat + "," + item.lat : item.lat;
        long = long ? long + "," + item.long : item.long;
     })
    let list = []
    const args = {
      moduleId: [this.selectedModule.moduleId],
      categoryId: this.cat.value,
      subCategoryId: this.getSubCategoryFilterValues(),
      attributes: this.getAttributeFilterValues(),
      budgets: this.getBudgetFilterValues(),
      priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
      city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
      location: location,//this.selectedCity ? "" : this.queryParams.location,
      area: this.locality.value,
      countryId: this.lookupModel.getCountry().codeId,
      latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
      longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
      max: this.selectedLocality ? 3000 : 0,
      macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
      zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
      listings: listingIds,
      allSubCat: this.selectedCategory.subCategory,
      customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
      skipListingRecords: this.listingContents,
      nearBySkipListingRecords: this.nearByListingContents,
      minDistance: this.minDistance,
      maxDistance: this.maxDistance,
      sortValue: this.sortValue
    };
    args['listings'] = listingIds;
    this.rsbService.getListingsBasedOnFilters(args).subscribe((rsp) => {
      if (rsp.contents && rsp.contents.length > 0) {
        list = rsp.contents;
        this.listingContents += list.length;
        // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
        //   list.push(rsp.nearByLocationsContents);
        // }
        // this.listings = this.getText(list);
  
      }
      if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
        this.nearByListingContents += rsp.nearByLocations.length;
        list = list.concat(rsp.nearByLocations);
        this.minDistance = rsp.maxDistance;
        this.maxDistance = rsp.maxDistance + 20000;
      }
  
  
      this.listings = this.listings.concat(list);
  
    })
  }
}
    }

  getPropertyListings(event?:any): void {
    if(event?.endReached) {
      let listingIds = this.listings.map((obj) => {
        return obj.listingId
      })
    }
    if(this.cities.length){
    let location="",pincode="",lat="",long="";
    this.cities.filter((item,index)=>{ 
        index ? location = location ? location + "," + item.cityName : item.cityName :"";
        pincode = pincode ? pincode + "," + item.zip : item.zip;
        lat = lat ? lat + "," + item.lat : item.lat;
        long = long ? long + "," + item.long : item.long;
     })
    const args = {
      moduleId: [this.queryParams.module],
      categoryId: this.cat.value,
      subCategoryId: this.getSubCategoryFilterValues(),
      attributes: this.getAttributeFilterValues(),
      budgets: this.getBudgetFilterValues(),
      priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
      city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
      location:location,//this.selectedCity ? "" : this.queryParams.location,
      area: this.locality.value,
      macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
      countryId: this.lookupModel.getCountry().codeId,
      latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
      longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
      max: this.selectedLocality ? 3000 : 0,
      zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
      listings: this.listings,
      customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
      minDistance: this.minDistance,
      maxDistance: this.maxDistance,
      allSubCat: this.selectedCategory.subCategory,
      skipListingRecords: this.listingContents,
      nearBySkipListingRecords: this.nearByListingContents,
      sortVal: this.sortValue
    };
    this.listings = [];
    this.rsbService.getProjectsBasedOnFilters(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        let list = [];
        if (rsp.projects && rsp.projects.length > 0) {

          list = rsp.projects;
          this.groupedRecords = list.map(record => {
            debugger
            return {
              ...record,
              groupedAttributes: this.groupByAttributeName(record.attributes)
            };
          });
          this.groupedRecords.filter(re=>{
            if(re.locationHighlights){
            let obj= JSON.parse(JSON.stringify(re.locationHighlights));
            delete obj.aboutLocality;
            re.locationHighlightsNew = obj;
            }
          });
          this.groupedRecords = this.groupedRecords.sort((a, b) => a.priceMin - b.priceMin);
          console.log(this.groupedRecords)
          this.listingContents = list.length;
          if(this.isMobileLayout) {
          this.groupedRecords.forEach((property) => {
            property.highlightElements = this.getHighlightElements(property.locationHighlightsNew)
          })
        }
          //alert(list.length);
          // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
          //   list.push(rsp.nearByLocationsContents);
          // }
          // this.listings = this.getText(list);
    
        }
        if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
          this.nearByListingContents = rsp.nearByLocations.length
          list = list.concat(rsp.nearByLocations);
          this.minDistance = rsp.maxDistance;
          this.maxDistance = rsp.maxDistance + 20000;
        }
        this.allListings = [...list];
        this.listingLength = rsp.count;
        if (this.listingLength > 0) {
          this.showMoreProperties = true
        }
        //alert("getting here");
        this.listings = list;
        this.listings = this.listings.sort((a, b) => a.price - b.price);
        this.count = rsp.count;
        let text = '';
        if (this.beds.value !== 'abc' && this.selectedAttribute) {
          text += this.selectedAttribute.propertyName + this.selectedAttribute.attributeName + ' ';
        }
        if (this.subCat.value) {
          text += this.selectedCategory.categoryName + ' ' + this.selectedSubCat.subCategoryName + '\'s';
        }
        else if (this.listings.length > 0) {
          text += this.subCatList[0].subCategoryName + ' properties';
        } else {
          text += 'properties';
        }
        text += ' available for ' + this.lookupModel.getModule().moduleName
        if(this.selectedCity) {
          text += ' at ' + this.selectedCity.name;
        }

        this.prepareKeywords(text);
        
        this.router.navigate(
          ['project', this.city.value ? this.city.value : 'all', this.selectedCategory.categoryName],
          {
            queryParams: {
              cat: this.selectedCategory.categoryId,
              subcat: this.getSubCategoryFilterValues().toString(),
              module:this.queryParams.module,
              location:location,// this.city.value ? this.city.value : this.queryParams.location,
              city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.macroCity : this.queryParams.city,
              zip: pincode,//rsp.zip ? rsp.zip : this.queryParams.zip,
              country: this.lookupModel.getCountry().codeId,
              cityBounds:JSON.stringify(this.cities[0].cityBounds),
              // loc: this.locality.value ? this.locality.value : null,
              lat: lat,//this.selectedCity ? this.selectedCity.lat:"",
              long: long,//this.selectedCity ? this.selectedCity.long:"",
            }
          }
        );
      }
    });
  }
  }

  getIcon(key: string): string {
    switch (key) {
      case 'banks':
        return 'assets/img/bankIcon.svg';
      case 'hospitals':
        return 'assets/img/hospital-fill.svg';
      case 'colleges':
        return 'assets/img/school-fill.svg';
        case 'transport':
        return 'assets/img/truck-fill.svg';
        case 'touristSpots':
        return 'assets/img/tourist-fill.svg';
        case 'hotelResorts':
        return 'assets/img/Food.svg';
        case 'ResidentialComplex':
          return 'assets/img/apartments-fill.svg';
          case 'mallsMultiplex':
            return 'assets/img/apartments-fill.svg';
            case 'shoppingCenter':
            return 'assets/img/apartments-fill.svg';
          
        
      default:
        return undefined;
    }
  }

  
  prepareKeywords(text: string): void {
    let keywords = [];
    if (this.selectedCategory) {
      keywords.push(this.selectedCategory.categoryName);
    }
    if (this.selectedSubCat) {
      keywords.push(this.selectedSubCat.subCategoryName);
    }
    if (this.city.value) {
      const cityText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      const cityText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      keywords.push(cityText1);
      keywords.push(cityText2);
    }
    if (this.locality.value) {
      const locText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      const locText2 =  'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      keywords.push(locText1);
      keywords.push(locText2);

    }
    keywords.push(text);
    let keyWordText = keywords.join(', ');
    this.seoService.updateTag('keywords', keyWordText);
  }

  getText(listings: Array<any>): Array<any> {
    listings.forEach(property => {
      let text = '';
      if (property.attributes) {
        property.attributes.forEach(attr => {
          if (attr.attributeName.search('Bed') !== -1) {
            text += attr.attributePropertyName + ' Bed, ';
          }
          if (attr.attributeName.search('Bath') !== -1) {
            text += attr.attributePropertyName + 'Bath, ';
          }
        })
      }
      text += property.propertySize + ' ' + property.measurementName;
      property.bedBathText = text;
    });

    return listings;
  }

  removeFilter(filedName: string): void {
    switch (filedName) {
      case 'subCat': this.subCat.setValue(''); break;
      case 'city': this.city.setValue(''); break;
      case 'locality': this.locality.setValue(''); break;
      case 'budget': this.budget.setValue(''); break;
      case 'beds': this.beds.setValue(''); break;
    }
  }

  sortBy(filedName: string, event: any): void {
    switch (filedName) {
      case 'price':
        if (event.target.value === "1") {
          this.groupedRecords = this.listings.sort((a, b) => b.priceMin - a.priceMin);
        } else {
          this.groupedRecords = this.listings.sort((a, b) => a.priceMin - b.priceMin);
        }
        break;
    }
  }

  filterByBudget(index: any, getListings ?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.bugetList[index]);
    //budget.isSelected = !budget.isSelected;
    this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    if(this.bugetList[index].isSelected){
      this.selBudgetCount.push(this.bugetList[index].budgetName);
    } else {
      var eleInd = this.selBudgetCount.indexOf(this.bugetList[index].budgetName);
      this.selBudgetCount.splice(eleInd,1);
    }
    //this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    //this.selBudgetCount = this.bugetList[index].isSelected ? this.selBudgetCount+1 : this.selBudgetCount-1;
    if(getListings) {
      this.getPropertyListings();
    }
  }

  filterByAttribute(pindex: any,cindex:any, getListings ?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.attrList[pindex].attributeProperties[cindex]);
    //attribute.isSelected = !attribute.isSelected;
    this.attrList[pindex].attributeProperties[cindex].isSelected = !this.attrList[pindex].attributeProperties[cindex].isSelected; 
   // this.attrList[pindex].setAttrCount = this.attrList[pindex].attributeProperties[cindex].isSelected ? this.attrList[pindex].setAttrCount +1 : this.attrList[pindex].setAttrCount-1;
    if(this.attrList[pindex].attributeProperties[cindex].isSelected){
      this.attrList[pindex].setAttrCount.push(this.attrList[pindex].attributeProperties[cindex].swatchName);
    } else {
      var eleInd = this.attrList[pindex].setAttrCount.indexOf(this.attrList[pindex].attributeProperties[cindex].swatchName);
      this.attrList[pindex].setAttrCount.splice(eleInd,1);
    }
    if(getListings) {
      this.getPropertyListings();
    }
  }
  

  contactOwner(property: any): void {
    const selectedProjectId = property.projectId;
    const modalRef = this.modalService.open(SignupPopupComponent, { size: 'lg' });
      modalRef.componentInstance.projectId = selectedProjectId;
  
    modalRef.result.then((rsn) => {
      // alert("getting rsn " + JSON.stringify(rsn)); 
        const customerId = rsn?.contents?.[0]?.customerId;  
  
      if (rsn.statusCode === 0 && customerId) {
        const projectId = property.projectId;
        const date = rsn.date;
          this.rsbService.addProjectsFavourite(customerId, projectId, date, false).subscribe((res) => {
          if (res.statusCode === 0) {
            Swal.fire({
              title: "Success",
              text: "Thank you for your request. We are working hard to find the best deals for you. Shortly you will find a confirmation in your Account",
              icon: "success",
              confirmButtonText: 'Continue'
            });
          }
        });
      } else {
        console.error('Unable to extract customerId or statusCode is not 0.');
      }
    }).catch((error) => {
      console.log('Modal dismissed with error:', error);
    });
  }
  
  
  requestSiteVisit(property:any){
    const selectedProjectId = property.projectId;
    const modelRef=this.modalService.open(RequestSiteVisitPopupComponent, {size: 'lg', });
    modelRef.componentInstance.projectId=selectedProjectId

    modelRef.componentInstance.responseEventEmmiter.subscribe((response) => {
      // alert("getting response"+JSON.stringify(response)); 
      if(response){
        
          const customerId=response.customerId;
          const projectId=property.projectId;
          const date=response.date;
        
        this.rsbService.addProjectsFavourite(customerId,projectId,date,false).subscribe((res)=>{
          // alert("getting addListingsFavourites"+res)
        })
      }
    });



  }
  EMI(property:any){
    const selectedProjectId = property.projectId;
    const modelRef=this.modalService.open(SignupPopupComponent, {size: 'lg', });
       modelRef.componentInstance.projectId=selectedProjectId
       modelRef.result.then((response) => {
        // alert("getting response"+JSON.stringify(response)); 
        if(response.statusCode===0){
          const customerId=response?.contents?.[0]?.customerId;
          const projectId=property.projectId;
          const date=response.date;
          this.rsbService.addProjectsFavourite(customerId,projectId,date,true).subscribe((res)=>{
            if(res.statusCode===0){
              Swal.fire({
                title: "<h1 style='color:black;font-size:30px'>Congratulations!</h1>",
                html: "You Can Avail <span style='color:red;'>2 Months EMI</span> Welcome Offer.",
                confirmButtonText:"Submit",
                confirmButtonColor:"#b1cd32",
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                }
              });
            }
            
          })
       

      }})

   
  }
  // openCalender() {
  //   Swal.fire({
  //     title: 'Select a Date and Time',
  //     html: `<input type="datetime-local" id="datetime-input" class="swal2-input">`,
  //      confirmButtonText: 'Confirm',
  //     focusConfirm: false,
  //     showCancelButton: true,
  //     preConfirm: () => {
  //       const datetimeValue = (document.getElementById('datetime-input') as HTMLInputElement).value;
  //       return datetimeValue;
  //     }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const selectedDateTime = result.value;
  //       console.log('Selected Date and Time:', selectedDateTime);
  //       ('Selected Date and Time:'+selectedDateTime)
  //     }
  //   });
  // }
  addToUserListing(property): void {
    const args = {
      projects: property.projectId,
      listingCustomerId: this.lookupModel.getProperty().vendorId,
      vendorMobile:property.vendorDetails.mobileNumber,
      vendorEmail:property.vendorDetails.email
    };
    // this.rsbService.addProjectToAccount(args).subscribe((rsp) => {
    //   if (rsp.statusCode === 0) {
    //       this.router.navigate(['account/my-listing'])
    //   }
    // });
  }
  getVendorDetails(property:any): void {
    this.lookupModel.setProperty(property);
  }

  getAddress(latLng: { lat: number, lng: number }, loadCity: boolean = false): Observable<any> {
    return Observable.create((observer) => {
      this.geoCoder.geocode({ 'location': latLng }, (results, status) => {
        if (status === 'OK') {
          //console.log(results);
          const postAddress = results.find(item => item.types.indexOf('postal_code') !== -1);
          //console.log(postAddress);
          if (postAddress) {
            const postalCode = postAddress.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
            if(postalCode) {
              this.selectedCity.zip = postalCode.long_name;
            this.cities.length ? this.cities[this.cities.length-1].zip=postalCode.long_name:"";
            }
            //console.log('FULL CITY ADDRESS :: ' , this.selectedCity);
          }
          // if(!this.cities.length){
          //   this.selectedCity.lat = latLng.lat;
          //   this.selectedCity.long = latLng.lng;          
          //   this.selectedCity.cityBounds = postAddress.geometry.viewport; 
          //   let boundsCur = this.selectedCity.cityBounds;
          //   this.cities.push(this.selectedCity)
          //   //console.log(this.fullCity);
          //   this.cityTextRef.nativeElement.placeholder="Add more...";
          //   this.cityAutocomplete.unbind("bounds");
          //   this.cityAutocomplete.setTypes(["geocode"]);
          //   this.cityAutocomplete.setBounds(boundsCur);
          // }
        }
        observer.next();
        observer.complete();
      });
    });
  }

  
  getBudgetFilterValues(): any[] {
    let budgetFil = [];
    this.bugetList.forEach(item => {
      if (item.isSelected) {
        const newFil = {
          from: item.min,
          to: item.max
        };
        budgetFil.push(newFil);
      }
    });
    //console.log('Budget Filters', budgetFil);
    return budgetFil;
  }
  
  getSubCategoryFilterValues(): any[] {
    //("getting here 1");
    let subCategoryFil = []
    this.selectedCategory.subCategory.forEach(prop => {
      if (prop.isSelected) {
        subCategoryFil.push(prop.subCategoryId)
      }
    });
    // alert("getting here");
    this.selectedSubCatList = subCategoryFil;
    //alert(this.selectedSubCatList.length);
    return subCategoryFil;
  }
  
  getAttributeFilterValues(): any[] {
    let attributeFil = [];
    this.selAttributeProperty = [];
    //this.selectedAttribute = this.queryParams.attribute;
    this.attrList.forEach(attr => {
      let attributeList = [];
      attr.attributeProperties.forEach(prop => {
        if (prop.isSelected) {
          // alert("getting here");
          attributeList.push(prop.propertyId);
          this.selAttributeProperty.push(prop.propertyId);
          //this.selectedAttribute.push(prop.propertyId);
        }
      });
      if (attributeList.length > 0) {
        const newAttr = {
          attribute: attributeList
        };
        attributeFil.push(newAttr);
      }
    });
    return attributeFil;
  }
  
  get cat() { return this.filterForm.get('cat'); }
  get subCat() { return this.filterForm.get('subCat'); }
  get city() { return this.filterForm.get('city'); }
  get locality() { return this.filterForm.get('locality'); }
  get budget() { return this.filterForm.get('budget'); }
  get beds() { return this.filterForm.get('beds'); }
}
