import { MapsAPILoader } from '@agm/core';
import { Component, OnInit, ElementRef, ViewChild, NgZone, ChangeDetectorRef, HostListener, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { BUS_EVENTS, MessageService } from 'src/app/common/events/message.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { ToastrService } from 'ngx-toastr';
import { JsonpInterceptor } from '@angular/common/http';
@Component({
  selector: 'app-search-properties',
  templateUrl: './search-properties.component.html',
  styleUrls: ['./search-properties.component.css']
})
export class SearchPropertiesComponent implements OnInit,AfterViewInit {
  private cityTextRef: ElementRef;
  @ViewChild('cityEntryBox') cityEntryBox!: ElementRef;
  private cityAutocomplete: any;
  private requestPlace: any;
  currentCoordinates: any;
  searchboxFocus: boolean = true;
  searchMore: boolean;
  searchInt: boolean = true;
  selectedModule: any;
  bugetList:any = [];
  selectedCountry: any;
  moduleSubscription: Subscription;
  private messageService: MessageService;
  selectedProperty: any;
  private cityText: ElementRef;
  private geoCoder;
  mapRestriction: any;
  selectedCategory: any;
  changeCurrentLocationCountry: boolean = false;

  cityBounds: any;
  pincode:any;
  localityAutoComplete: any;
  categoryList: Array<any> = [];
  subCatFullList: Array<any> = [];
  typePG = false;
  selectedSubCats: string[] = [];
  isDropDownVisible:boolean=false;
  cityAutoComplete:any;
  visibleDropdown:boolean=false;
  isMobileLayout:boolean=false;
  imageSrc: string | ArrayBuffer | null = '';




  searchForm:FormGroup;
  @ViewChild('cityText') set getCityEle(cityElement: ElementRef) {
    this.cityTextRef = cityElement;
    if (this.cityTextRef && this.lookupModel.selectedCountry && this.lookupModel.isBrowser) {
      if (!this.cities.length && this.searchInt) {
        //console.log(this.lookupModel.getCurrentLocation());
        let obj = this.lookupModel.getCurrentLocation();
        let latlang = { lat: obj.latitude, lng: obj.longitude };
        this.fullCity.cityName = obj.city;
        this.requestPlace = {
          componentRestrictions: {
            country: this.lookupModel.selectedCountry.twoDigitCountryCode,
          },
          types: ['(cities)'],
          //bounds: this.cities.length ? this.cityBounds : [], //no need
          //strictBounds: true //no need
        }
        if (!this.geoCoder) {
          this.loadMapsApi();
        } else {
          this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, this.requestPlace);
          this.cityAutocomplete.addListener('place_changed', this.changeEvent);
          this.getAddress(latlang);
        }
      }
      //this.prepareAutoComplete();
    }
  }

  loadMapsApi(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, this.requestPlace);
      // this.cityAutocomplete.addListener('place_changed', this.changeEvent);
      //this.prepareAutoComplete();
    });
  }

  changeEvent = (): void => {
    // this.ngZone.run(() => {
    //get the place result
    let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

    //verify yt67
    if (!place || place.geometry === undefined || place.geometry === null) {
      return;
    }
    if (place.geometry && place.geometry.location) {
      let viewport = place.geometry.viewport;
      this.fullCity = {
        cityName: place.name,
        macroCity: place.address_components[1].long_name,
        cityBounds: place.geometry.viewport,
        zip: '',
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),

      }
      this.cityBounds = place.geometry.viewport;
      this.cityAuto.setValue(place.name);
      this.cities.push(this.fullCity)
      this.city.setValue("");
      if (this.cities.length == 1) {
        this.cityTextRef.nativeElement.placeholder = "Add More Locations...";
        this.cityAutocomplete.unbind("bounds");
        this.cityAutocomplete.setTypes(["geocode"]);
        this.cityAutocomplete.setBounds(viewport);
        this.cityAutocomplete.setOptions({
          strictBounds: true,
        });
      }
      if (this.cities.length == 4) {
        this.cityTextRef.nativeElement.disabled = "disabled";
        //this.cityTextRef.nativeElement.style="{background:'#c1c1c1'}";
      }
    }
    this.getCitiesList();
    this.getAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
    // this.submit();
    //});
  }

  getCitiesList(): string {
    if (this.cities.length > 0) {
      const cities: any = this.cities?.map((obj) => {
        return obj.cityName;
      })
      return cities
    } else {
      return null;
    }
  }

  loadCurrentLocation(): void {
    const addr = this.lookupModel.getPositionAddress();
    this.changeCurrentLocationCountry = false;
    if (addr && addr.city) {
      this.city.setValue(addr.city);
      // this.cityAuto.setValue(addr.city);
      // this.submit();
      const selectedCountry = this.lookupModel.getCountry();
      if (addr.country !== selectedCountry.twoDigitCountryCode && addr.country !== selectedCountry.threeDigitCountryCode) {
        const countryList = this.lookupModel.getCountryList();
        let country = countryList.find(country => country.twoDigitCountryCode === addr.country);
        if (!country) {
          country = countryList.find(country => country.threeDigitCountryCode === addr.country);
        }
        if (country) {
          this.lookupModel.setCountry(country);
          this.changeCurrentLocationCountry = true;
          //this.messageService.sendMessage(new Message(BUS_EVENTS.LOCATION_CHANGE, country.codeId));
        }
      }
    } else {
      this.currentCoordinates = this.lookupModel.getCurrentPosition();
      if (this.currentCoordinates) {
        this.getAddress(this.currentCoordinates, true);
      } else {
        this.city.reset();
        this.cityAuto.reset();
        this.toastrService.warning('Please enable location.', 'Warning');
      }
    }
  }

  getAddress(latLng: { lat: number, lng: number }, loadCity: boolean = false) {
    // if(latLng)
    this.geoCoder.geocode({ 'location': latLng }, (results, status) => {
      if (status === 'OK') {
        // console.log(results);
        const postAddress = results.find(item => item.types.indexOf("locality") !== -1); //'postal_code'
        const postAddresspin = results.find(item => item.types.indexOf("postal_code") !== -1);
        //console.log(postAddress)
        if (postAddress) {
          this.fullCity.macroCity = postAddress.address_components[2].long_name;
          const city = postAddress.address_components.find(comp => comp.types.indexOf('locality') !== -1);
          if (city && loadCity) {
            this.city.setValue(city.long_name);
            this.cityAuto.setValue(city.long_name);
            this.fullCity.cityName = city.long_name;
            this.fullCity.cityBounds = postAddress.geometry.viewport;
            //this.submit();
          }
          const postalCode = postAddresspin.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.fullCity.zip = postalCode.long_name;
            this.cities.length ? this.cities[this.cities.length - 1].zip = postalCode.long_name : "";
          }
          // console.log('FULL CITY ADDRESS :: ' , this.fullCity);
          if (!this.cities.length) {
            this.fullCity.lat = latLng.lat;
            this.fullCity.long = latLng.lng;
            this.fullCity.cityBounds = postAddress.geometry.viewport;
            //let boundsCur = this.fullCity.cityBounds;
            this.cities.push(this.fullCity)
            //console.log(this.fullCity);
            this.cityTextRef.nativeElement.placeholder = "Add more...";
            this.cityAutocomplete.unbind("bounds");
            this.cityAutocomplete.setTypes(["geocode"]);
            this.cityAutocomplete.setBounds(this.fullCity.cityBounds);
          }
        }
      }
    });
  }

  fullCity = {
    cityName: '',
    cityBounds: {},
    zip: '',
    macroCity: '',
    lat: 0,
    long: 0
  };
  // private localityText: ElementRef;
  // @ViewChild('localityText') set localityEle(lclElement: ElementRef) {
  //   if (lclElement) {
  //     this.localityText = lclElement;
  //   }
  // }
  cities: Array<any> = [];
  currentCity: any;
  occupancyTypes: any = [];
  private landmarkText: ElementRef;
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (this.cityEntryBox && !this.searchboxFocus) {
      if (!this.cityEntryBox.nativeElement.contains(event.target)) {
        // Click occurred outside the element
        this.searchboxFocus = true;
        this.searchMore = this.cities.length > 1 && this.searchboxFocus ? true : false;
      }
    }
  }
  startSearch(): void {
    this.searchboxFocus = false;
    this.searchMore = this.cities.length > 1 && this.searchboxFocus ? true : false;
  }

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public lookupModel: RsbLookupModel,
    private fb: FormBuilder,
    private router: Router,
    private rsbService: RsbService,
    private toastrService: ToastrService

  ) { 
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    this.loadMapsApi();
  }
 

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.searchForm = this.fb.group({
      cat: ['', [Validators.required]],
      city: [ ''],
      cityAuto: [''],

    })
    // this.getListings();
    // this.getCategories();
    this.cat.valueChanges.subscribe((val) => {
      if (val) {
        const cateogry = this.categoryList.find(cat => cat.categoryId === val);
        this.selectedCategory = cateogry;
        this.lookupModel.setCategory(cateogry);
        this.selectedSubCats = [];
      }
    });
    this.selectedCountry = this.lookupModel.getCountry();
    this.selectedModule = this.lookupModel.getModule() ? this.lookupModel.getModule() : { moduleId: this.selectedCountry.moduleId ,moduleName:"Rent"};
    
    // this.moduleSubscription = this.messageService.onMessage(BUS_EVENTS.MODULE_CHANGE, (val) => {
    //   if (val && val !== this.selectedModule.moduleId) {
    //     // this.subCat.reset();
    //     // this.subCat.setValue('');
    //     this.selectedModule = this.lookupModel.getModule();
    //   }
    // });
    if (this.lookupModel.getCountry()) {
    }

    this.selectedModule = this.lookupModel.getModuleList()[0];
    this.rsbService.currentImage.subscribe(image => {
      this.imageSrc = image; // Subscribe to the image source updates
    });
  }
  ngAfterViewInit(): void {
    const module = this.lookupModel.getModuleList();
    if (module[0].moduleId) {
      this.getCategories();
    }
  }
  getBasicAttributesWithAttributeName(): void {
    const args = {
      attributeName: "Occupancy Type"
    }
    this.rsbService.getBasicAttributesBasedOnName(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.occupancyTypes = rsp.contents;
      }

    })
  }

  selectSubcat(event, subindex, type?: String): void {
    // alert("gett hereee");
    if (type == 'property') {
      //const index = this.selectedSubCats.indexOf(event.target.value);
      const checked = event.target.checked
      this.selectedCategory.subCategory[subindex].isSelected = checked;
      if (checked) { //index !== -1
        this.selectedSubCats.push(event.target.value);
      } else {
        this.selectedSubCats.splice(subindex, 1);
      }
    } else {
      const checked = event.target.checked;
      this.occupancyTypes[0].attributeProperties[subindex]["isSelected"] = checked;
      console.log("occupancy types is", this.occupancyTypes[0].attributeProperties);
      if (checked) {
        this.selectedSubCats.push(event.target.value);
      } else {
        this.selectedSubCats.splice(subindex, 1);
      }
    }
  }

  selectPgSubCat(event, subIndex): void {
    //alert("getting here");
    const checked = event.target.checked;
    this.occupancyTypes[0].attributeProperties[subIndex]["isSelected"] = checked;
    console.log("occupancy types is", this.occupancyTypes[0].attributeProperties);
    if (checked) {
      this.selectedSubCats.push(event.target.value);
    } else {
      this.selectedSubCats.splice(subIndex, 1);
    }
  }
  
  submit(){

    let selectedOccpency = []
    if (this.typePG) {
      selectedOccpency = this.occupancyTypes[0]?.attributeProperties?.filter(re => re.isSelected);
    }
    if ((this.searchForm.invalid && !this.typePG) || !this.cities.length || (!selectedOccpency.length  && this.typePG)) { //this.city.value !== this.cityAuto.value
      if (!this.cities.length) {
        this.city.setErrors({ required: true });
        // this.toastrService.warning('Please select city from search dropdown.');
      }
      return;
    } else {
      // this.toastrService.clear();
    }
    let location = "", pincode = "", lat = "", long = "";
    this.cities.filter((item, index) => {
      index ? location = location ? location + "," + item.cityName : item.cityName : "";
      pincode = pincode ? pincode + "," + item.zip : item.zip;
      lat = lat ? lat + "," + item.lat : item.lat;
      long = long ? long + "," + item.long : item.long;
    })
    this.lookupModel.setCity(this.fullCity);
    this.lookupModel.setLocality(null);
    this.lookupModel.setSubCategory(null);
    this.lookupModel.selectedSubCatList = this.selectedSubCats;
    this.lookupModel.setCategoryList(this.categoryList);
    var routeName = "listings"
    if(this.selectedModule.moduleName.toUpperCase().includes("PROJECT")){
      routeName="project"
    } else if(this.selectedModule.moduleName.toUpperCase().includes("HOSTEL")){
      routeName="pg"
    }

    if (routeName !== "pg") {

      this.router.navigate(
        [routeName, this.cities[0].cityName, this.selectedCategory.categoryName.replaceAll(' ', '-')],
        {
          queryParams: {
            cat: this.selectedCategory.categoryId,
            subcat: this.selectedSubCats && this.selectedSubCats.length ? this.selectedSubCats.toString() : '',
            city: this.cities[0].cityName, //this.fullCity.macroCity,
            location: location, //this.city.value,
            zip: pincode, //this.fullCity.zip,
            lat: lat,  //this.fullCity.lat,
            long: long,  //this.fullCity.long,
            module: this.selectedModule.moduleId,
            country: this.lookupModel.getCountry().codeId,
            cityBounds: JSON.stringify(this.cities[0].cityBounds),
            attribute: 'abc'
          }
        });
    } else {
      this.router.navigate(
        [routeName, this.cities[0].cityName ? this.cities[0].cityName : 'all'],
        {
          queryParams: {
            subcat: this.selectedSubCats && this.selectedSubCats.length ? this.selectedSubCats.toString() : '',
            city: this.cities[0].cityName, //this.fullCity.macroCity,
            location: location, //this.city.value,
            zip: pincode, //this.fullCity.zip,
            lat: lat,  //this.fullCity.lat,
            long: long,  //this.fullCity.long,
            module: this.selectedModule.moduleId,
            country: this.lookupModel.getCountry().codeId,
            cityBounds: JSON.stringify(this.cities[0].cityBounds),
            attribute: 'abc'
          }
        });
    }

  }
  toggleDropdown(){
    this.isDropDownVisible=!this.isDropDownVisible;

  }
  profile() : void {
    // window.location.href= APIURL.WEBSITE_URL+'/account/myprofile'
    this.closeDropdown();
    this.router.navigate(['associateMyProfile'])
  }
  profileDropdown(){
    this.visibleDropdown=!this.visibleDropdown
  
  }
  myfavorites(){
    this.closeDropdown();
    // window.location.href= APIURL.WEBSITE_URL+'/account/favorites'
  }
  favoriteListing(){
    this.closeDropdown();
    this.router.navigate(['favorite-listings'])

    // window.location.href= APIURL.WEBSITE_URL+'/account/favorites'
  }

  favoriteProjects(){
    this.closeDropdown();
    this.router.navigate(['favorite-projects'])

    // window.location.href= APIURL.WEBSITE_URL+'/account/favorites'
  }
  favoritePgHostel(){
    this.closeDropdown();
    this.router.navigate(['favorite-hostels'])

    // window.location.href= APIURL.WEBSITE_URL+'/account/favorites'
  }
  getLeadsForProperties(){
    this.closeDropdown();
    this.router.navigate(['leads-properties'])

  }
  getLeadsForProjects(){
    this.closeDropdown();
    this.router.navigate(['leads-projects'])

  }
  getLeadsForPgHostels(){
    this.closeDropdown();
    this.router.navigate(['leads-pgHostels'])

  }
  changepwd(){
    this.closeDropdown();
    this.router.navigate(['change-password'])
    // window.location.href= APIURL.WEBSITE_URL+'/account/changepassword'
  }
  closeDropdown() {
    this.visibleDropdown = false;
  }

  removeEle(index: number) {
    this.cities.splice(index, 1);
    this.searchInt = false;
    this.cityTextRef.nativeElement.disabled = null;
    if (!this.cities.length) {
      this.cityTextRef.nativeElement.placeholder = "Search By City , Location";
      //this.cityAutocomplete.unbind("bounds");
      this.cityAutocomplete.setTypes(['(cities)']);
      //this.cityAutocomplete.setBounds({});
      this.cityAutocomplete.setOptions({
        strictBounds: false,
      });
    }
  }
  getIcon(val: string): string {
    switch (val) {
      case 'Rent':
        return 'assets/img/Rent.svg';
       case 'Buy':
        return 'assets/img/Buy.svg';
       case 'Projects':
        return 'assets/img/Projects.svg';
       case 'PG / Hostel':
         return 'assets/img/Pg&hostel.svg';
       case 'Plot & Land':
         return 'assets/img/Plot & Land.svg';
        case 'Commercial':
          return 'assets/img/Commercial.svg';
          
      
      default:
        return ''; 
    }
  }

  prepareCityAutocomplete(): void {
    if(this.checkReadOnly()) {
      return;
    }
    this.city.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutoComplete = new google.maps.places.Autocomplete(this.cityText.nativeElement, {
      
        types: ['(cities)'],
        //bounds: []
        strictBounds: true
      });
      this.cityAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutoComplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.mapRestriction = {
            latLngBounds: place.geometry.viewport,
            strictBounds: true
          };
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.cityAuto.setValue(place.name);
          // this.locality.reset();
          // this.localityAuto.reset();
          // this.pincode.reset();
          const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.pincode.setValue(postalCode.long_name);
          }
        });
      });
    });
  }

  getActiveBudgets(): void {
    this.rsbService.getActiveBudgetsForFilters(this.selectedModule.moduleId).subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.bugetList = rsp.contents;
      } else {
        this.bugetList = [];
      }
    });
  }
 
  checkReadOnly(): boolean {
    const readonly: boolean = this.selectedProperty && ([1, 2, 3].includes(this.selectedProperty.status));
    return readonly;
  }
  selectModule(module: any): void {
    // this.lookupModel.setSelectedModuleId(module.moduleId)
    this.selectedModule = module;

    this.cat.setValue("");
    //this.selectedCategory ? this.selectedCategory.subCategory = [] : "";
    this.selectedCategory = "";
    this.selectedSubCats = [];
    //this.selectedCategory.subCategory.filter(item=>{ item.isSelected = false; })
    this.lookupModel.setModule(module);
    this.getCategories();
    //this.messageService.sendMessage(new Message(BUS_EVENTS.MODULE_CHANGE, module.moduleId));
  }
  getCategories(): void {
    if (this.selectedModule) {
      this.rsbService.getActiveCategories(this.selectedModule.moduleId).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.categoryList = rsp.contents;
          this.lookupModel.setCategoryList(this.categoryList);
          this.typePG = false;
          if (this.selectedModule?.altModuleName?.toUpperCase().includes("PG")) {
            this.typePG = true;
            this.getBasicAttributesWithAttributeName();
            // this.selectedCategory = this.categoryList[0];
          }
          // this.selectedCategory = rsp.contents[0];
          // const list = this.categoryList.map((item => {
          //   item.subCategory = item.subCategory.map(subCat => {
          //     subCat.categoryId = item.categoryId;
          //     return subCat;
          //   });
          //   return item.subCategory;
          // }));
          // this.subCatFullList = list.concat.apply([], list);
        } else {
          this.categoryList = [];
          this.subCatFullList = [];
        }
      });
    } else {
      this.categoryList = [];
      this.subCatFullList = [];
    }

  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-menu') && !target.closest('.dropdown-toggle')) {
      this.closeDropdown(); 
    }
  }
  get cat() { return this.searchForm.get('cat'); }
  get subCat() { return this.searchForm.get('subCat'); }
  get city() { return this.searchForm.get('city'); }
  get cityAuto() { return this.searchForm.get('cityAuto'); }


}
