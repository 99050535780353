import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/common/events/message.service';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-login-associate',
  templateUrl: './login-associate.component.html',
  styleUrls: ['./login-associate.component.css']
})
export class LoginAssociateComponent implements OnInit {
  @Input() successLink: string;
  loginForm: FormGroup;
  loginSubs: any;
  loginBlocked: boolean = false;
  isMobileLayout:boolean=false;

  loginFailed: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  onlyCountries = [];
  selectedISOCountry;
  passwordError: boolean = false;
  passworderr: string;
  passwordStrength: string;
  loginApplicationForm:boolean=false;
  hidePassword: boolean = true;



  constructor( private router: Router,
    private fb: FormBuilder,
    // activeModal: NgbActiveModal,
    private rsbService: RsbService,
    private lookupModel: RsbLookupModel,
    private messageService: MessageService,
    private toastrService: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.loginForm = this.fb.group({
      loginMobile: ['', [Validators.required]],
      loginPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]]
    });

    const localCountrys = this.lookupModel.getCountryList();
    this.onlyCountries = [];
    localCountrys.forEach(country => {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.onlyCountries.push(isoCountry);
      }
    });
    const country = this.lookupModel.getCountry();
    if (country) {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.selectedISOCountry = isoCountry;
      }
    }

  }
  passwords: string = '';


  
  togglePassword(): void {
    this.hidePassword = !this.hidePassword;
  }
  findCountryIso(countryCode: string): string {
    let isoKey = Object.keys(CountryISO).find(item => CountryISO[item] === countryCode.toLowerCase());
    if (isoKey) {
      return CountryISO[isoKey];
    } else {
      return '';
    }
  }
  forgotPwd(): void {

    this.router.navigate(["forgotpassword"])



  }
 

  onlyNumbersAllowed(event): boolean {
    const charCode = event.which || event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 32) {
      return true;
    }
    return false;

  }

  validatePassword(password) {
    const val = password.target.value;
    if (val.length < 6 || val.length > 10) {
      this.passwordError = true
    } else {
      this.passwordError = false;
    }
  }

  login(): void {
    //alert("getting into login");
    const logMobile = this.loginMobile.value;
    const e164Number = logMobile.e164Number;
    const logMobileNumber = e164Number.replace(logMobile.dialCode, '');
    this.rsbService.associateCustomerLogin(parseInt(logMobile.dialCode), logMobileNumber, this.loginPassword.value).subscribe((rsp) => {
      this.loginFailed = false;

      if (rsp.statusCode === 0) {

        this.router.navigate(['dashboard'])



      }
      else {
        this.loginFailed = true;
        this.toastrService.error("Invalid mobile or password")
        setTimeout(() => {
          this.loginFailed = false
        }, 5000)
        //this.close();
      }
      //  ("going here");
    });
  }

 




  

 

  close(rsn?: any): void {
    //this.activeModal.close(rsn);
  }

  // get mobileOtp() { return this.signupForm.get('mobileOtp'); }
  // get mobile() { return this.signupForm.get('mobile'); }
  // get password() { return this.signupForm.get('password'); }
  // get firstName() { return this.signupForm.get('firstName'); }
  // get email() { return this.signupForm.get('email'); }
  get loginMobile() { return this.loginForm.get('loginMobile'); }
  get loginPassword() { return this.loginForm.get('loginPassword'); }

}
