import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nexus-addon-tables',
  templateUrl: './nexus-addon-tables.component.html',
  styleUrls: ['./nexus-addon-tables.component.css']
})
export class NexusAddonTablesComponent implements OnInit {
  @Input() headers: string[] = []; 
  @Input() data: any[] = []; 
  isMobileLayout:boolean=false;
  constructor() { }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    
  }

}
