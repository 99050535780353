<section class="Property_search">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 property_leftlist">
          <div style="text-align: center; display: flex;">
            <!-- <button class="btn btn-primary m-2" (click)="favoriteListing()"><i class="ri-heart-3-line"></i> Favorite Properties</button>
            <button class="btn btn-primary m-2" (click)="favoriteProjects()"><i class="ri-heart-3-line"></i> Favorite Projects</button>
            <button class="btn btn-success m-2" (click)=" favoritePgHostels()"><i class="ri-heart-3-line"></i> Favorite PG/Hostels</button> -->

            <div>
              <button class="favourites-button  m-2" (click)="favoriteListing()">
                  <span class="icon">
                      <i class="ri-heart-3-line"></i>
                  </span>
                  <span class="text">Favorite Properties</span>
              </button>
              <button class="favourites-button m-2" (click)="favoriteProjects()">
                  <span class="icon">
                      <i class="ri-heart-3-line"></i>
                  </span>
                  <span class="text">Favorite Projects</span>
              </button>
              <button class="favourites-button m-2" (click)=" favoritePgHostels()"  [ngClass]="{'active': isActive}" (click)="toggleActive()">
                  <span class="icon">
                      <i class="ri-heart-3-line"></i>
                  </span>
                  <span class="text">Favorite PG/Hostels</span>
              </button>
          </div>
        </div>
          <div class="d-flex justify-content-between pagination_content">          
            <!-- <div class="heading">
              <h1>Properties in Hyderabad for Buy</h1>
            </div> -->
          </div>
          <div *ngIf="selectedProp">
            <p style="background-color: white; width: 70%; display: flex; justify-content: space-between; align-items: center; padding: 10px;">
                <span>You have selected <b style="font-size: 20px;color: brown;"> {{ selectedPropCount }}</b>&nbsp;properties!</span>
                <span>
                  <i class="ri-links-line" (click)="copyLink()"style="font-size: 18px; margin-left: 30px;"></i>
                    {{copyMessage}}             
                    <i class="ri-share-forward-line" (click)="sharePopUp()" style="font-size: 18px; margin-left: 30px;"></i>
                    <i class="ri-delete-bin-6-line" (click)="cancelSelection()" style="font-size: 18px; margin-left: 30px;"></i>
                    <button class="white-button" (click)="cancelSelection()">Cancel</button>
                  </span>
            </p>
        </div>
          <div class="pb_listItems"  *ngFor="let property of listings; let propIndex = index;">
            <div class="row">
              <div class="col-sm-9 col-lg-9 morder-2">
                <div class="ps-wrap">
                  <div class="pb_listimage">
                    <a (click)="openProperty(property)">
                    <img [src]="property.images && property.images.length && property.images[0] ? property.images[0] : 'assets/img/property_search_image1.png'" alt=""/>
                    <!-- <p class="tag"><span><i class="ri-shield-star-line"></i> Premium</span></p> -->
                  </a>
                  </div>
                 
                  <div class="ps-content">
                    <div class="d-flex justify-content-between">
                      <div class="list_price"></div>
                      <div class="shareicons">
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <input type="checkbox" [(ngModel)]="property.selected" (change)="selectedCheckProp(property)" style="width: 20px; height: 20px;">
                          </li>
                          <ng-container *ngIf="!getPlanId">
                        
                            <li class="list-inline-item">
                              <button class="btn btn-primary"  (click)="choosePlanForShare(property)"> SHARE <i class="bi bi-share-fill"></i></button>
                            </li>
                            </ng-container>
                            <ng-container *ngIf="getPlanId">
                            
                     
                      
                        <li class="list-inline-item">
                          <button class="btn btn-primary" (click)="shareSingleProp(property)"> SHARE <i class="bi bi-share-fill"></i></button>
                        </li>
                        </ng-container>
                        </ul>
                      </div>
                    </div>
                    <h4 class="ps-heading" style="display: inline;cursor: pointer;">{{property.pgName}} </h4><span class="label_color"> in {{property.area}}</span>
                    <div class="sqft_details">
                      <ul class="list-group list-group-horizontal">
                        <li class="list-group-item" *ngFor="let sub of property.attributesNew">
                          <p>{{sub.subCategoryName}}</p>
                          <h6>{{sub.securityDepPerBed}}</h6>
                        </li>
                    
                      </ul>
                    </div>
                    <div>
                      <ng-container *ngIf="!showFullContent[propIndex] && property.pgDescription.length > 100; else shortContent">
                       <p style="font-size: 12px;">{{ property.pgDescription | slice:0:250 }}...<a (click)="toggleContent(propIndex)" style="font-size: 11px;">Read More</a>
                       </p>
                      </ng-container>
                      <ng-template #shortContent>
                        <p style="font-size: 12px;">{{ property.pgDescription }}<a *ngIf="showFullContent[propIndex]" (click)="toggleContent(propIndex)" style="font-size: 11px;">Show Less</a>
                        </p>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <div class="bottomDiv morder-last nearby" *ngIf="property.locationHighlights && getLocationLength(property.locationHighlightsNew) > 0">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <p><img src="assets/img/location.svg" alt="places"> {{getLocationLength(property.locationHighlightsNew)}}  
                        nearby places:</p>
                    </li>
                    <ng-container *ngFor="let item of property.locationHighlightsNew | keyvalue">
                      <ng-container *ngIf="item.value && item.value !== null">
                        <li class="list-inline-item">
                          <p class="bgpink">
                          <img  *ngIf="getIcon(item.key)" [src]="getIcon(item.key)" alt="places">{{ item.value }}
                          </p>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
        
                </div>
              </div>
              <div class="col-sm-3 col-lg-3 morder-1">
                <div class="ps-rightside">
                  <div class="list_price text-center">
                    <h2>{{property.price}}</h2>
                  </div>
                  <ul class="dowloadbrocher list-inline text-center">
                    <li class="list-inline-item">
                      <button class="btnbrocher" (click)="contactOwner(property)">Get Deal</button>
                    </li>
                    <li class="list-inline-item">
                      <button class="reqvisitbtn" (click)="requestSiteVisit(property)">Request Site Visit</button>
                    </li>
                    <li class="list-inline-item">
                      <button class="btn btn-danger text-white">2 Months EMI <span
                          class="label-warning">Free</span></button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> <!---Pb_listItems-->
 
        </div> <!--end  of col-9-->

      </div>
    </div>
  </section>