import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { Router, ActivatedRoute } from '@angular/router';
import { RsbService } from 'src/app/service/rsb.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { SeoService } from 'src/app/common/services/seo.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginpopupComponent } from 'src/app/components/loginpopup/loginpopup.component';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropertyNames } from 'src/app/common/prop.enum';
import { SpecPropertyNames } from 'src/app/common/spec.enum';
declare var jQuery: any;

@Component({
  selector: 'app-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.css']
})
export class ProjectDescriptionComponent implements OnInit {
  propertyNames = PropertyNames;
  specPropertyNames = SpecPropertyNames;
  @ViewChild('tabContainer') tabContainer!: ElementRef;
  @ViewChildren('section') sections!: QueryList<ElementRef>;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  onlyCountries = [];
  signupForm: FormGroup;
  loginForm: FormGroup;
  loginFailed: boolean = false;

  mobileOtpCheck: boolean = false;
  mobileOtpVerified: boolean = false;
  mobileExists: boolean = false;
  mobileOtpSent: boolean = false;
  mobileOtpResent: boolean = false;
  mobileOtpMaxFlag: boolean = false;
  mobileOtpError: boolean = false;
  resendOtpActivated: boolean = false;
  signIn: boolean = true;
  property: any;
  toggle: boolean = false;
  togleOption: boolean = true;
  seeMoreToggle: boolean = false;

  rentTabData: any = [
    {
      tabName: 'OverView',
      tabSection: 'overview',
      sectionApiName: 'propertyDescription',
      active: true,
      IsExists: true
    },
    {
      tabName: 'About Property',
      tabSection: 'project',
      sectionApiName: 'propertyDescription',
      active: false,
      IsExists: true
    },
    {
      tabName: 'More Details',
      tabSection: 'moredetails',
      active: false,
      IsExists: true
    },
    {
      tabName: 'Floor Plan',
      tabSection: 'floorPlan',
      sectionApiName: 'floorPlanImages',
      active: false,
      IsExists: true
    },
    {
      tabName: 'Specifications',
      tabSection: 'specifications',
      sectionApiName: 'specifications',
      active: false,
      IsExists: true
    },
    // {
    //     tabName: 'Amenities',
    //     tabSection:'amenities',
    //     active:false,
    //     IsExists:true
    // },
    {
      tabName: 'Enquire',
      tabSection: 'enquire',
      active: false,
      IsExists: true
    },
    {
      tabName: 'Bank Offers',
      tabSection: 'loans',
      active: false,
      IsExists: true
    },
    {
      tabName: 'Ratings and Reviews',
      tabSection: 'reviews',
      active: false,
      IsExists: true
    },
    {
      tabName: 'Landmarks',
      tabSection: 'landmarks',
      active: false,
      IsExists: true
    }
  ];
  professionalTerminal = [
    {
      name: "  Sai Krishna",
      testModule: "It's a quite interesting Lead Source and decided to sign up with PillarBlocks.We recently closed Couple of leads and a few in the pipeline and Ready for MORE!",
      profession: "MD,",
      location: " North East Infra",
      image: "assets/img/Sai Krishna.jpg",
      bg: "green_bg"

    },
    {
      name: " Prem Sagar Reddy",
      testModule: "Outstanding listing lead source!!PillarBlocks provides FREE listing & Quality leads of exceptional quality with very detailed notes about the Buyer Profiling. Highly recommend it!",
      profession: "MD,",
      location: "SR Fortune City",
      image: "assets/img/Prem Sagar.jpg",
      bg: "lightyellow_bg"

    },
    {
      name: "Venu Bhupal",
      testModule: "Pillarblocks is a Buyer Centric Platform elevating real estate lead lifecycle with an Excellent service in sales. The leads information is complete and the service is great.Highly recommend Pillarblocks!",
      profession: "  MD,",
      location: "RS infra",
      image: "assets/img/Venu Bhoopal.jpg",
      bg: "orange_bg"



    },
    {
      name: "Sachin VG",
      testModule: "  Pillarblocks Leads save valuable time since they filter leads and qualify to gauge real sales.They also take detailed notes before sending the leads and buyers are well informed of Property.",
      profession: " MD,",
      location: "Vision infra",
      image: "assets/img/Sachin.jpg",
      bg: "grey_bg"



    },
    {
      name: "  Manohar A",
      testModule: "So far we have been getting decent leads and the EMI Offer is Amazing.We see almost a closer lead, yes we have to work on the leads, but all open to sell our home.great work by pillarblocks.",
      profession: " MD, ",
      location: " MS Estates",
      image: "assets/img/Manohar A.jpg",
      bg: "pink_bg"



    },
    {
      name: "Amarnath",
      testModule: " I’ve been able to grow my sales and connect with potential buyers with pillarblocks. We have listed all our projects now, kudos to pillarblocks.",
      profession: "MD,",
      location: "Elite Estates",
      image: "assets/img/Amarnath.jpg",
      bg: "pitch_bg"



    },


  ]
  ourServices = {
    serviceName: "Our Services",
    data: [
      {
        name: "Home Interiors & Decors",
        image: "assets/img/interior_design.png",
        content: "Explore The Best Interior Designer For a Modern, Stylish Home. Make Your Home Beautiful and Functional With Expert Home Designers. Call Now."
      },
      {
        name: "Packers & Movers",
        image: "assets/img/packers.png",
        content: "Trusted, top-rated packers & movers team that treat your stuff with care and consideration. We are ready to roll. Get a FREE moving quote today. "
      },
      {
        name: "Rental Agreement",
        image: "assets/img/rent_aggrement.png",
        content: "Create a Simple Contract to Rental Agreement. Free to Customize and Ready in Minutes. Valid for a year or more, Try now Our Rental Agreement."
      },
      {
        name: "Vaastu",
        image: "assets/img/vaastu_info.png",
        content: "Vastu principles ensure the flow of positive energy and good fortune to a property. Keeping property airy, breathable, and clean is the most basic rule of making it Vastu-compliant."
      },

    ]
  }
  toggleOpt() {
    this.toggle = true;
    this.togleOption = false;

  }
  lessToggle() {
    this.toggle = false;
    this.togleOption = true;

  }

  basicAttributes: Array<any> = [];
  additionalAttribues: Array<any> = [];
  newListings: Array<any> = [];
  featuresActuve: boolean = false;
  selectedCategory: any;
  selectedSubCat: any;
  selectedBudget: any;
  selectedISOCountry;
  selectedBeds: any;
  selectedModule: any;
  lat: number;
  long: number;
  zoom: number = 12;
  mapRestriction: any;
  addFavoriteButton: boolean = true;
  activeModel: NgbActiveModal;
  isScrolled: boolean = false;
  tabScrolled: boolean = false;
  floorplanImages: any;

  //signupForm: FormGroup;
  textArray = []

  // @ViewChild('myCarousel') myCarousel: NguCarousel;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
    load: 3,
    slide: 1,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {
      enabled: true,
      height: 1050
    },
    point: {
      visible: false,
      hideOnSingleSlide: true
    }
  }
  carouselItems: any[any] = [];
  mainItems: any[] = [...this.carouselItems]
  additionalAttrs: any[] = [];
  additionalAttrProps: any[] = [];
  enquiresList: any[] = [];
  selectedLocality: any;

  constructor(
    public lookupModel: RsbLookupModel,
    private router: Router,
    private rsbService: RsbService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private seoService: SeoService,
    private toastrService: ToastrService,
    private el: ElementRef,
    private fb: FormBuilder
  ) {
    this.route.queryParams.subscribe((val) => {
      if (!val || !val.projectId) {
        this.router.navigate(['dashboard']);
        return;
      } else {
        this.getPropertyDetails(val);
      }
    });
  }

  ngOnInit(): void {


    if (!this.lookupModel.getLoggedUser()) {
      this.signupForm = this.fb.group({
        mobileOtp: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
        mobile: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]]
      });
    }
    const localCountrys = this.lookupModel.getCountryList();
    this.onlyCountries = [];
    localCountrys.forEach(country => {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.onlyCountries.push(isoCountry);
      }
    });
    const country = this.lookupModel.getCountry();
    if (country) {
      let isoCountry = this.findCountryIso(country.twoDigitCountryCode);
      if (isoCountry) {
        this.selectedISOCountry = isoCountry;
      }
    }
    if (this.lookupModel.getLocality()) {
      this.selectedLocality = this.lookupModel.getLocality();
    }


  }

  findCountryIso(countryCode: string): string {
    let isoKey = Object.keys(CountryISO).find(item => CountryISO[item] === countryCode.toLowerCase());
    if (isoKey) {
      return CountryISO[isoKey];
    } else {
      return '';
    }

  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
    setTimeout(() => {
      (function ($) {

        $(document).ready(function () {
          var popularSlider = $("#Popluar_slider");
          var aboutSlider = $("#about_slider");
          var verified_slider = $('#verified_slider');
          var similar_slider = $('#Similar_slider');
          var map_slider = $('#map_slider');
          var enquireSlider = $("#enquire_carousel");
          var guptaSlider = $("#floorplan");
          var rateReviewSlider = $("#rateRevie_Slider");
          enquireSlider.owlCarousel({
            items: 3, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 2
              }
            },
            //onInitialized: handleInitialized
          });

          guptaSlider.owlCarousel({
            items: 1, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 1
              },
              1000: {
                items: 1
              }
            },
            //onInitialized: handleInitialized
          });
          rateReviewSlider.owlCarousel({
            items: 3, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 2
              }
            },
            //onInitialized: handleInitialized
          });
          map_slider.owlCarousel({
            items: 3, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 2
              }
            },
            // onInitialized: handleInitialized
          });
          aboutSlider.owlCarousel({
            items: 3, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 2
              }
            },
            //onInitialized: handleInitialized
          });
          popularSlider.owlCarousel({
            items: 5, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 4
              }
            },
            //onInitialized: handleInitialized
          });

          verified_slider.owlCarousel({
            items: 5, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 4
              }
            },
            // onInitialized: handleInitialized
          });

          similar_slider.owlCarousel({
            items: 5, // 10 items above 1000px browser width
            margin: 10,
            touchDrag: true,
            pagination: false,
            dots: false,
            nav: true,
            navText: ['<i class="bi bi-arrow-left"></i>', '<i class="bi bi-arrow-right"></i>'],
            responsiveClass: true,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 2
              },
              1000: {
                items: 4
              }
            },
            // onInitialized: handleInitialized
          });



          function handleInitialized(event) {
            if (event.item.index === 0) {
              $(event.target).find('.owl-prev').hide();
            }
          }
        })

        const scrollAmount = 500; // Adjust scroll speed by changing this value

        $(".prev-btn").click(function () {

          $(".menu").animate(
            {
              scrollLeft: `-=${scrollAmount}`,
            },
            "slow"
          );
        });

        $(".next-btn").click(function () {
          $(".menu").animate(
            {
              scrollLeft: `+=${scrollAmount}`,
            },
            "slow"
          );
        });

      })(jQuery);
    }, 2000)


  }

  isSectionVisible(tabSectionApiName: string): any {
    if (tabSectionApiName === undefined) {
      return true;
    }
    const type = typeof this.property[tabSectionApiName];
    if (type === undefined) {
      return true;
    }
    else if (type === 'string') {
      return true;
    }
    else if (type == 'object') {
      const count = this.getLocationLength(this.property[tabSectionApiName]);
      if (count > 0) {
        return true;
      } else {
        return false;
      }
    }
  }



  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {

    // Detect scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.isScrolled = scrollPosition > 1000 && scrollPosition < 7000; // Change 100 to your desired scroll position
    this.tabScrolled = scrollPosition > 1000;
    this.checkVisibleSection()
  }

  private checkVisibleSection(): void {
    this.sections.forEach(section => {
      const rect = section.nativeElement.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isVisible) {
        //console.log("is visible is",section.nativeElement.id);
        this.rentTabData.map((obj) => {
          if (obj.tabSection === section.nativeElement.id) {
            obj.active = true
          } else {
            obj.active = false
          }
        })
      }
    });
  }

  scrollLeft() {
    this.tabContainer.nativeElement.scrollLeft -= 100; // Change the value as needed
  }

  scrollRight() {
    this.tabContainer.nativeElement.scrollLeft += 100; // Change the value as needed
  }

  openProjectProperty(property: any): void {
    //console.log(property)
    this.lookupModel.setProperty(property);
    this.lookupModel.setCategory(this.selectedCategory);
    this.lookupModel.setSubCategory(this.selectedSubCat);
    if (this.selectedLocality && this.selectedLocality.localityName) {
      this.lookupModel.setLocality(this.selectedLocality);
    }
    this.lookupModel.setCity({ name: property.city, bounds: property.cityBoundries });
    const text = property.projectName.replaceAll(' ', '-')
    this.router.navigate(
      ['project-description', text],
      {
        queryParams: {
          projectId: property.projectId,
          cat: property.categoryId,
          subcat: property.subCategoryId,
          module: property.moduleId,
          country: property.countryId ? property.countryId : this.lookupModel.getCountry().codeId,
          city: property.city,
          area: property.area,
          zip: property.pincode,
          pincode: property.pincode,
        }
      }
    );
  }
  seeMore() {
    this.seeMoreToggle = !this.seeMoreToggle;
  }
  getPropertyDetails(property: any): void {
    // const args = {
    //   listingId: property.listingId,
    //   categoryId: property.categoryId,
    //   subCategoryId:property.subCategoryId,
    //   moduleId: [property.moduleId]
    // };
    const args = {
      projectId: property.projectId,
      city: property.city ? property.city : 'Hyderabad',
      area: property.area,
      zip: property.pincode,
      pincode: property.pincode,
      categoryId: property.cat,
      subCategoryId: property.subcat,
      moduleId: ['xwfIKq'],
      customerLoginStatus: 0,
      customerId: "abc"
    };
    this.rsbService.getCustomerProjectsBasedOnProjectId(args).subscribe((rsp) => {
      if (rsp.statusCode === 0 || rsp.statusCode === 3) {
        if (rsp.statusCode === 3) {
          this.addFavoriteButton = false;
          this.toastrService.warning("Selected Property is already in your favorites List, Please check your Favorites list")
        }
        console.log(rsp)
        this.enquiresList = rsp.enquires ? rsp.enquires : [];
        this.rentTabData[6].IsExists = this.enquiresList.length ? true : false;
        this.additionalAttrs = rsp.additionalAttributes;
        this.floorplanImages=rsp.floorPlanImages;
        this.additionalAttrs.forEach(ele=>{
          if(ele.attributes.length){
            let list = [];
            ele.attributes.forEach(ele1=>{
              let list = ele1.attributeProperties.map(re=>re.swatchName).filter(e=>e.toLowerCase() != "no" );
              ele1.swatchNameList = list;
              //if(ele.properties){
                // let sameProp = ele.properties.filter(re=>re.attributeName=ele1.attributeName).map(e=>e.swatchName);
                // list.filter(re => re.attributeName=ele1.attributeName);
                // if(list.length){
                // ele1.swatchNameList=sameProp;
                // list.push(ele1);
                // ele.propertiesNew = list;
                //}
              //}
            });
          this.rentTabData.push({tabName: ele.propertyTabName,tabSection:ele.propertyTabId,active:false,IsExists:true});
          this.additionalAttrProps.push(ele);  
        }
        })
        // this.additionalAttrs.forEach(ele=>{
        //   if(ele.attributes.length){
        //     ele.attributes.forEach(attrEle=>{
        //       if(attrEle.attributeProperties.length){
        //         var swatchNamelist = attrEle.attributeProperties.filter(val=>{return val.swatchName && val.swatchName.toLowerCase()!=="no"})
        //         var swatchName = "";
        //         if(swatchNamelist.length>1)
        //           swatchName = swatchNamelist.reduce((a,b)=>{return a.swatchName+","+b.swatchName;});
        //         else if(swatchNamelist.length==1)
        //           swatchName = swatchNamelist[0].swatchName;
        //        if(swatchName){
        //           attrEle.swatchNamenew = swatchName;
        //          // this.additionalAttrProps.push(attrEle);
        //        }
        //         }
        //   })
        //   }
        // })
        //this.rentTabData[5].IsExists = this.additionalAttrProps.length ? true : false;
        console.log(this.additionalAttrs)
        console.log(this.additionalAttrProps)
        this.property = rsp.projectInfo[0];
        this.rentTabData[4].IsExists = this.property.specifications.length ? true : false;
        this.rentTabData[3].IsExists = this.property.floorPlanImages && this.property.floorPlanImages.length ? true : false;
        this.property.attributes = this.property.attributes.sort((a, b) => a.attributeId - b.attributeId);;
        for (var x = 0; x < this.property.attributes?.length; x++) {
          var count = 0;
          for (var y = 0; y < this.property.attributes?.length; y++) {
            if (this.property.attributes[x].attributeName == this.property.attributes[y].attributeName) {
              count = count + 1;
              if (count > 1) {
                this.property.attributes[x].attributePropertyName = this.property.attributes[x].attributePropertyName + "," + this.property.attributes[y].attributePropertyName;
                this.property.attributes.splice(y, 1);
              }
            }
          }
        }
        // this.property.attributesnew = this.property.attributes.filter((re,index)=>{
        //   let reattributeName = this.property.attributes.find((chk,chkindex)=>{
        //     if(chk.attributeName == re.attributeName && chkindex != index){
        //       chk.index=chkindex
        //       return chk;
        //     }
        //   });
        //   if(reattributeName){
        //   if(reattributeName.index != index)
        //     re.attributePropertyName = re.attributePropertyName +","+ reattributeName.attributePropertyName;
        //     return re;
        //   } else {
        //     return re;
        //   }
        // })
        //console.log(this.property)
        console.log(this.property)
        // if(this.lookupModel.getLoggedUser()) {
        //   const args = {
        //     projects: this.property.projectId,
        //     listingCustomerId: this.lookupModel.getProperty().vendorId,
        //     vendorMobile:this.property.vendorDetails.mobileNumber,
        //     vendorEmail:this.property.vendorDetails.email
        //   };

        //   this.rsbService.addViewListingsToAccount(args).subscribe((rsp) => {

        //   });



        // }
        this.newListings = rsp.newListings ? rsp.newListings : [];
        this.carouselItems = this.newListings;
        this.mainItems = [...this.carouselItems]
        this.lat = parseFloat(this.property.latitude);
        this.long = parseFloat(this.property.longitude);
        this.mapRestriction = {
          latLngBounds: this.property.cityBoundries,
          strictBounds: true
        };

        console.log('After assignment - Lat:', this.lat);
        console.log('After assignment - Long:', this.long);
        console.log('After assignment - Map Restriction:', this.mapRestriction);
        if (typeof this.property.latitude === 'number' && typeof this.property.longitude === 'number') {
          this.lat = this.property.latitude;
          this.long = this.property.longitude;
        } else {
          console.error('Latitude or longitude is not a valid number:', this.property.latitude, this.property.longitude);
        }

        let text = '';
        const basicAttr = rsp.basicAttributes;
        basicAttr?.forEach(bsc => {
          if (bsc.attributeName?.search('Bed') !== -1) {
            text += bsc.attributePropertyName + ' Bed, ';
          } else if (bsc.attributeName?.search('Bath') !== -1) {
            text += bsc.attributePropertyName + ' Bath, ';
          } else if (bsc.attributeName === 'Lease Terms') {
            this.property.leaseTerms = bsc.attributePropertyName;
          } else if (bsc.attributeName === 'Furnished') {
            this.property.furnish = bsc.attributePropertyName;
          }
        });

        const textAttributes = this.property.textAttributes
        if (textAttributes && textAttributes.length) {
          textAttributes.forEach((obj) => {
            const index = this.property.basicAttributeId?.findIndex((value) => {
              return obj.attributeId === value
            })
            if (index > -1) {
              const newObj = {
                attributeName: obj.attributeName,
                attributePropertyName: this.property.basicAttributePropertyId && this.property.basicAttributePropertyId.length ? this.property.basicAttributePropertyId[index] : ''
              }
              if (typeof newObj.attributePropertyName == "object") {
                var dateformate = newObj.attributePropertyName.day + "-" + newObj.attributePropertyName.month + "-" + newObj.attributePropertyName.year;
                newObj.attributePropertyName = dateformate;
              }
              this.textArray.push(newObj)
            }
          })
           //console.log("text array is",this.textArray);
        }

        this.property.images = this.property?.images?.filter((obj) => {
          return obj != '';
        })
        if (this.property.images.length === 0) {
          this.property.images.push('assets/img/list/1.png');
        }
        text += this.property.propertySize + ' ' + this.property.measurementName;
        this.property.bedBathText = text;
        this.basicAttributes = basicAttr;
        this.basicAttributes = this.basicAttributes.concat(this.textArray).filter((obj) => {
          return obj.attributeName != null;
        })
        if (rsp.additionalAttributes.length === 0) {
          this.featuresActuve = false
        } else if (rsp.additionalAttributes[0].attributes.length === 0) {
          this.featuresActuve = false
        }
        else {
          this.additionalAttribues = this.prepareAddlAttr(rsp.additionalAttributes);
        }
        this.property.countryLocale = 'en-IN';
        this.prepareKeywords();
      }
    });
  }

  scrollToSection(sectionId: string, tabName) {
    this.rentTabData.map((obj) => {
      if (obj.tabSection === sectionId) {
        obj.active = true
      } else {
        obj.active = false
      }
    })
    const index = this.rentTabData.findIndex((obj) => {
      return obj.tabSection === sectionId
    })


    const section = this.el.nativeElement.querySelector(`#${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  prepareAddlAttr(additionalAttr: Array<any>): Array<any> {
    additionalAttr.map(prop => {
      if (prop.attributes) {
        prop.properties = [];
        prop.attributes.forEach(attr => {
          if (attr.attributeProperties) {
            attr.attributeProperties.map(item => {
              this.featuresActuve = true;
              item.attributeName = attr.attributeName;
              item.attributeJointId = attr.attributeJointId;
            });
            prop.properties = prop.properties.concat(attr.attributeProperties);
          }
        });
      }
    });

    return additionalAttr.filter(attr => attr.properties && attr.properties.length > 0);
  }

  prepareKeywords(): void {
    let keywords = [];
    if (this.property.categoryName) {
      keywords.push(this.property.categoryName);
    }
    if (this.property.subCategoryName) {
      keywords.push(this.property.subCategoryName);
    }
    if (this.property.city && this.property.categoryName) {
      const cityText1 = this.property.categoryName + ' Properties in ' + this.property.city;
      const cityText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' at ' + this.property.city;
      const cityText3 = this.property.subCategoryName + ' for ' + this.lookupModel.getModule().moduleName + ' at ' + this.property.city;
      keywords.push([cityText1, cityText2, cityText3]);
    }
    if (this.property.area && this.property.categoryName) {
      const locText1 = this.property.categoryName + ' Properties in ' + this.property.area;
      const locText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' at ' + this.property.area;
      const locText3 = this.property.subCategoryName + ' for ' + this.lookupModel.getModule().moduleName + ' at ' + this.property.area;
      keywords.push([locText1, locText2, locText3]);
    }
    let keyWordText = keywords.join(', ');
    this.seoService.updateTag('keywords', keyWordText);
  }

  back(): void {
    const subCat = this.lookupModel.getSubCategory();
    const city = this.lookupModel.getCity();
    this.router.navigate(['listings', city.cityName, subCat.subCategoryName]);
  }

  contactOwner(): void {
    const addListing = () => {
      this.addToUserListing();
    };
    if (this.lookupModel.getLoggedUser().customerId) {
      addListing();
    } else {
      const ref = this.modalService.open(LoginpopupComponent, { size: 'lg' });
      ref.result.then((rslt) => {
        if (rslt) {
          addListing();
        }
      });
    }

  }

  contact(property: any): void {
    const getContact = () => {
      this.getVendorDetails(property);
      this.addToUserListing();
    };
    if (this.lookupModel.getCustomerId()) {
      // if(this.lookupModel.getLoggedUser().activeStatus == 0) {
      //   this.modalService.open(UpdateProfileContactComponent);
      // }
      getContact();
    } else {
      const ref = this.modalService.open(LoginpopupComponent, { size: 'lg' });
      ref.result.then((rslt) => {
        if (rslt) {
          // if(this.lookupModel.getLoggedUser().activeStatus == 0) {
          //   this.modalService.open(UpdateProfileContactComponent);
          // }
          getContact()
        }
      });

    }
  }

  addFavorites(): void {
    this.router.navigate(['wishlist']);
  }

  getLocationLength(obj): number {
    let count = 0;
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        count++;
      }
    }

    // this.getFirstTwoNonNullValues(obj)
    // this.getLastNonNullValues(obj);
    return count;
  }

  resendMobileOtp(mobile: any): void {
    this.mobileOtpResent = false;
    this.mobileOtpVerified = false;
    this.mobileOtpCheck = false;
    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.resendMobileOtp(mobileNumber, parseInt(mobile.dialCode).toString()).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.mobileOtpResent = true;
        this.mobileOtpSent = true;
        this.mobileOtpVerified = false;
      } else if (rsp.statusCode === 2) {
        this.resendOtpActivated = true;
        this.mobileOtpMaxFlag = true;
      }
      else {
        this.mobileOtpError = true;
      }
    }, (err) => {
      this.mobileOtpResent = false;
    });
  }

  sendMobileOtp(mobile: any): void {
    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.sendMobileOtp(mobileNumber, parseInt(mobile.dialCode).toString()).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.mobile.disable();
        this.mobileOtpSent = true;
      } else if (rsp.statusCode === 1) {
        this.mobileOtpMaxFlag = true;
      } else if (rsp.statusCode === 3) {
        this.mobileExists = true;
      }
      else if (rsp.statusCode === 2) {
        this.mobileOtpMaxFlag = true;
      }
      else {
        this.mobileOtpError = true;
      }
    }, (err) => {
      this.mobileOtpSent = false;
    });
  }

  addToUserListing(): void {
    const args = {
      projects: this.property.projectId,
      listingCustomerId: this.property.vendorId,
      vendorMobile: this.property.vendorDetails.mobileNumber,
      vendorEmail: this.property.vendorDetails.email
    };
    this.rsbService.addProjectToAccount(args).subscribe((rsp) => {
      if (rsp.statusCode === 0 || rsp.statusCode === 3) {
        if (rsp.statusCode === 3) {
          this.addFavoriteButton = false;
          this.toastrService.warning("Selected Property is already in your favorites List, Please check your Favorites list")
        } else {
          this.router.navigate(['account/my-listing']);
        }
      }
    });
  }

  getVendorDetails(property: any): void {
    property['vendorId'] = property.customerId;
    this.lookupModel.setProperty(property);


  }




  getLocation(): void {
    if (this.property.latitude && this.property.longitude) {
      const url = 'http://www.google.com/maps/place/' + this.property.latitude + ',' + this.property.longitude;

      window.open(url, '_blank');
      console.log('Google Maps URL :', url);

    }
    else {
      console.error('Latitude or longitude is not available.');
    }
  }
  isContentVisible = false;

  toggleContentAmenities() {
    this.isContentVisible = !this.isContentVisible;
  }
  getIcon(key: string): string {
    switch (key) {
      case 'hotelResorts':
        return 'ri-restaurant-fill';
      case 'hospitals':
        return 'ri-hospital-line';
      case 'transport':
        return 'ri-truck-line';
      case 'banks':
        return 'ri-bank-line';
      case 'colleges':
        return 'ri-school-line';
      case 'shoppingCenter':
        return 'ri-shopping-cart-line';
      case 'ResidentialComplex':
        return 'ri-building-line';
      case 'mallsMultiplex':
        return 'ri-shopping-bag-4-line';
      case 'nearByLocality':
        return 'ri-map-pin-line'
      case 'touristSpots':
        return 'ri-route-line';
      case 'commercialHubs':
        return 'ri-building-2-line';
      default:
        return '';
    }
  }
  getAttrIcon(val: string): string {
    switch (val) {
      case 'Car':
        return 'ri-roadster-fill';
      case 'Bike':
        return 'ri-motorbike-fill';
      case 'Security Staff':
        return 'ri-walk-fill';
      case 'Common Elevator':
        return 'ri-door-closed-line';
      case 'Centralized':
        return 'ri-dvd-fill';
      case 'UPS':
        return 'ri-battery-charge-line';
      case 'Social Space':
        return 'ri-space';
      case 'Out Door':
        return 'ri-door-open-fill';
      case 'Indoor':
        return 'ri-community-line';
      case 'CCTV':
        return 'ri-webcam-line';
      case 'Facility':
        return 'ri-profile-line';



      default:
        return '';
    }
  }
  openProperty(property: any): void {
    this.lookupModel.setProperty(property);
    const title = property.propertyTitle.replaceAll(' ', '-')
    window.open("https://pillarblocks.com/project-description/" + title + "?listingId=" + property.listingId + "&cat=" + property.categoryId + "&subcat=" + property.subCategoryId +
      "&module=" + property.moduleId + "&country=" + property.countryId + "&city=" + property.city +
      "&area=" + property.area + "&zip=" + property.pincode + "&pincode=" + property.pincode)
    // this.router.navigate(
    //   ['property-details', title],
    //   {
    //     queryParams: {
    //       listingId: property.listingId,
    //       cat: property.categoryId,
    //       subcat: property.subCategoryId,
    //       module: property.moduleId,
    //       country: property.countryId,
    //       city:property.city,
    //       area:property.area,
    //       zip:property.pincode,
    //       pincode:property.pincode,
    //     }
    //   }
    // );
  }

  contactDetails(property: any): void {
    const addListing = () => {
      this.addToUserListing();
    };
    if (this.lookupModel.getCustomerId()) {
      addListing();
    } else {
      const ref = this.modalService.open(LoginpopupComponent, { size: 'lg' });
      ref.result.then((rslt) => {
        if (rslt) {
          addListing();
        }
      });
    }
  }

  register(): void {
    this.mobileOtpVerified = false;
    const mobile = this.mobile.value;
    const e164Number = mobile.e164Number;
    const mobileNumber = e164Number.replace(mobile.dialCode, '');
    this.rsbService.verifyMobileOtp(this.mobileOtp.value, this.password.value,
      parseInt(mobile.dialCode),
      mobileNumber,
      mobile.countryCode, this.firstName.value, this.email.value).subscribe((rsp) => {
        this.mobileOtpCheck = true;
        if (rsp.statusCode === 0) {
          this.lookupModel.setProfileDisplayType(0);
          this.mobileOtpVerified = true;
          this.lookupModel.setMobileType(1);
          this.addToUserListing();
        } else {
          this.mobileOtpVerified = false;
        }
      }, (err) => {
        this.mobileOtpSent = false;
      });
  }



  carouselTileLoad(data) {
    let arr = this.carouselItems;
    this.carouselItems = [...this.carouselItems, ...this.mainItems];
  }



  get mobileOtp() { return this.signupForm.get('mobileOtp'); }
  get mobile() { return this.signupForm.get('mobile'); }
  get password() { return this.signupForm.get('password'); }
  get city() { return this.signupForm.get('city'); }
  get firstName() { return this.signupForm.get('firstName') }
  get email() { return this.signupForm.get('email') }

}
