import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-cctv',
  templateUrl: './cctv.component.html',
  styleUrls: ['./cctv.component.css']
})
export class CctvComponent implements OnInit {
  cctvRecords:Array<any> = []
  propertyTypes=["Home","Apartment","Villa","Society","Mall / Complex","Agri Land","Others"]
  ccTvForm:FormGroup;
  isMobileLayout:boolean=false;
  ccTvHeaders = ['Customer Name', 'Project Type', 'Required Budget','Project Location','Mobile Number','Description','Actions'];
  ccTvData: any[] = [];

  

  constructor(private fb:FormBuilder,
    private rsbService:RsbService,
    private lookupModel:RsbLookupModel
  ) { }
  
  ngOnInit(): void {

    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;

    this.getCctv();
    this.ccTvForm=this.fb.group({
      projectType:['',[Validators.required]],
      propType:['',[Validators.required]],
      Budget:['',[Validators.required]],
      location:['',[Validators.required]],
      cName:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required]],
      description:['',[Validators.required]]
      
    })
  }

  getCctv(): void {
    this.rsbService.getCctv().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.cctvRecords = rsp.contents;
        this.ccTvData = rsp.contents.map(record => ({
          'Customer Name': record.cName,
          'Project Type': record.projectType,
          'Required Budget': record.budget,
          'Project Location': record.location,
          'Mobile Number':record.mobile,
          'Description': record.description,
          'Actions': 'delete'
        }));

      }
    })
  }
  submit(){
    if (this.ccTvForm.invalid) {
      Object.values(this.ccTvForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      projectType:this.projectType.value,
      propertyType:this.propType.value,
      budget:this.budget.value,
      location:this.location.value,
      cName:this.cName.value,
      mobile:this.mobile.value,
      email:this.email.value,
      description:this.description.value,
    }
    this.rsbService.enquiryForCcTv(args).subscribe((rsp)=>{
      if(rsp.statusCode===0){
        this.getCctv();
        this.ccTvForm.reset();
      }
      

    })

  }
  get projectType() {return this.ccTvForm.get('projectType')}
  get propType() {return this.ccTvForm.get('propType')}
  get budget() {return this.ccTvForm.get('Budget')}
  get location() {return this.ccTvForm.get('location')}
  get cName() {return this.ccTvForm.get('cName')}
  get mobile() {return this.ccTvForm.get('mobile')}
  get email() {return this.ccTvForm.get('email')}
  get description() {return this.ccTvForm.get('description')}


}

