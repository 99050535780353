<footer class="sticky-footer">
  <div class="container my-auto">
      <div class="copyright text-center my-auto">
          <ul class="nav" style="margin-left: 130px;">
              <li class="nav-item">
                <a class="nav-link" (click)="aboutUs()">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="terms()">Terms & Conditions</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="privacy()">Privacy Policy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="shipping()">Shipping Policy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="Pricing()">Pricing Policy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="contactUs()">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="cancellation()">Cancellation & Refund Policy</a>
              </li>
            </ul>
            <span>All trademarks, logos, and names are properties of their respective owners. Copyright © 2024 - All rights reserved. By Teamwork Innovations Pvt Ltd</span>
      </div>
  </div>
</footer>
