import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-pg-feautures',
  templateUrl: './pg-feautures.component.html',
  styleUrls: ['./pg-feautures.component.css']
})
export class PgFeauturesComponent implements OnInit {

  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter();
  categoryAttrList: Array<any> = [];
  addAttrValues = [];
  expandAllFlag: boolean = false;
  featureForm: FormGroup;
  selectedProperty: any;
  isFormDirty: boolean = false;
  constructor(
    private lookupModel: RsbLookupModel,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getVendorProperty();
    this.featureForm = this.fb.group({});
    this.categoryAttrList = this.lookupModel.getPropertyCategoryList();
    if(this.selectedProperty.listingId === 'abc' && this.selectedProperty.additionalAttributes) {
      this.mapAdditionalAttributes(this.selectedProperty.additionalAttributes);
    } else {
      this.mapAdditionalAttributes();
    }
    if(this.checkReadOnly()) {
      this.featureForm.disable();
    }
  }

  checkReadOnly(): boolean {
    const readonly: boolean = this.selectedProperty && ([1, 2, 3].includes(this.selectedProperty.status));
    return readonly;
  }

  mapAdditionalAttributes(attrValueList ?: any[]): void {
    this.addAttrValues = [];
    this.categoryAttrList.forEach((catAttr) => {
      if (catAttr.type === 0) {
        catAttr.attributes.forEach(attr => {
          const selectedProps = attr.attributeProperties.filter(prop => prop.isSelected);
          let selectedPropValues = [];
          if(selectedProps.length > 0) {
            selectedPropValues = selectedProps.map(prop => prop.propertyId);
          } else if(attrValueList) {
            const valueItem = attrValueList.find(item => item.attributeId === attr.attributeId);
            if(valueItem) {
              selectedPropValues = valueItem.value;
              attr.attributeProperties = attr.attributeProperties.map(prop => {
                if(selectedPropValues.indexOf(prop.propertyId) !== -1 ) {
                  prop.isSelected = true;
                }
                return prop;
              });
            }
          }
          
          let temp = {
            attributeId: attr.attributeId,
            propertyTabId: catAttr.propertyTabId,
            status: catAttr.type ? 1 : 0,
            value: selectedPropValues
          };
          this.addAttrValues.push(temp);
        });
      }
    });
  }
  pageCollaps(id: any): void {
    const rmcollapse = document.querySelectorAll('.collapse.show') as any as Array<HTMLElement>;
    if (rmcollapse) {
      rmcollapse.forEach((element) => {

        element.classList.remove("show");
      })
    }
    const getId = document.querySelector(id) as HTMLElement;
    if (getId) {
      getId.classList.add('show');
    }

  }

  changeAddAttr(event: any, attr: any, tab: any): void {
    let temp = {};
    if (this.addAttrValues.length === 0) {
      temp = {
        attributeId: attr.attributeId,
        propertyTabId: tab.propertyTabId,
        status: tab.type ? 1 : 0,
        value: [event.target.value]
      }
      this.addAttrValues.push(temp);
    } else {
      const index = this.addAttrValues.findIndex((prop) => prop.attributeId === attr.attributeId);
      if (index !== -1) {
        if (attr.displayType === 1) {
          this.addAttrValues[index].value = [event.target.value];
        }
        else if (attr.displayType === 2) {
          if (event.target.checked) {
            this.addAttrValues[index].value.push(event.target.value);
          } else {
            this.addAttrValues[index].value.pop(event.target.value);
          }
        }
        else {
          this.addAttrValues[index].value.push(event.target.value);
        }
      } else {
        temp = {
          attributeId: attr.attributeId,
          propertyTabId: attr.propertyTabId,
          status: tab.type ? 1 : 0,
          value: [event.target.value]
        }
        this.addAttrValues.push(temp);
      }
    }

    this.emitFormChange(true);
  }

  emitFormChange(val: boolean): void {
    if(this.isFormDirty !== val) {
      this.isFormDirty = val;
      this.formChange.emit(val);
    }
  }
  back() {
    this.showNextPage.emit(10)
  }
  submit(): void {
    let propertyInfo = this.lookupModel.getVendorProperty();
    propertyInfo.additionalAttributes = this.addAttrValues;
    if (this.addAttrValues.length > 0) {
      this.lookupModel.setVendorProperty(propertyInfo);
    }
    this.emitFormChange(false);
    this.showNextPage.emit(4);
  }
}
