<section class="login_wrapper">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-6">
                <div class="login_propLeft">
                 
                    <!-- <div class="loginchoose">
                        <h2>Why you should choose us :</h2>
                        <ul>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Post a property for FREE.</p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>List Properties till Sale.</p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Enjoy two months of Cash Back on Home Loan
                                    EMIs!</p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Get home loans easily from the banks you
                                    already use.
                                </p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Sale Your Properties 10X Fast with Profiled
                                    Buyers.
                                </p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Avail Property Services ( Packers & Movers,
                                    Rental Agreement, Interior Design & Vaastu )
                                </p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Connect with Verified Buyers & Tenants.
                                </p>
                            </li>
                            <li>
                                <p><i class="bi bi-check-square-fill"></i>Get Unlimited Enquiries on Your Property.
                                </p>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="logincontainer">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" id="LoginTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#Login" type="button"
                                role="tab" aria-controls="login" aria-selected="true" >
                                Contact Us
                            </button>
                        </li>
                        
                    </ul>

                    <!-- Tab panes -->

                    <div class="tab-content">

                       
                        <div>
                          
                                <form>
                                    <div class="form-group">
                                        <label for="Name" class="form-label">Name<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter First Name"
                                            id="firstName" name="firstName" formControlName="firstName">
                                       
                                    </div>
                                    <div class="form-group">
                                        <label for="Name" class="form-label">Mobile Number<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Your Mobile Number"
                                            id="firstName" name="firstName" formControlName="firstName">
                                       
                                    </div>
                                    <div class="form-group">
                                        <label for="Name" class="form-label">Description<span class="text-danger">*</span></label><br>
                                        <textarea class="form-control"></textarea>
                                       
                                    </div>

                                    
                                
                                    <button type="submit" style="margin-left: 150px;" class="btn btn-primary" type="submit"
                                        >Submit</button>
                                    <!-- <div class="divder my-4"> -->
                                    <!-- <p> Or </p>
                                </div>
                                <button type="button" class="btn btn-light googlebtn"><i class="bi bi-google"></i> Log in with Google</button>
                                <div class="newuser my-4">
                                  <p class="mb-0">Already have an account? <a href="#Login"> LOGIN HERE</a> </p>
                                </div> -->
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

</section>