<div class="container-fluid" *ngIf="!isMobileLayout">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">Land Fencing</h2>
        <form class="agent-profile" [formGroup]="landFencingForm" (ngSubmit)="submit()">
            <div class="form-row mb-2">
                <div class="col-md-4">
                    <label for="Name">Project Location</label><span class="text-danger">*</span>
                    <div class="dropdown-wrapper">
                        <select class="form-control" formControlName="location" name="location">
                            <option value="">Select</option>
                            <option value="Hyderabad">Hyderabad</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Vizag">Vizag</option>
                        </select>
                        <span class="dropdown-icon"></span>
                    </div>
                    <div class="text-danger" *ngIf="landFencingForm.get('location')?.invalid && landFencingForm.get('location')?.touched">
                        Location is required
                    </div>
                    <small class="text-muted"><em>Please select the city</em></small>
                </div>
                <div class="col-md-4">
                    <label for="Name">No Of Acrs</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="acrs" name="acrs" >
                    <div class="text-danger" *ngIf="landFencingForm.get('acrs')?.invalid && landFencingForm.get('acrs')?.touched">
                        Acrs is required
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="Name">Budget</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="Budget" name="Budget" >
                    <div class="text-danger" *ngIf="landFencingForm.get('Budget')?.invalid && landFencingForm.get('Budget')?.touched">
                        Budget is required
                    </div>
                </div>
               
                <div class="col-md-4">
                    <label for="Name">Customer Name</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="cName" name="cName">
                    <div class="text-danger" *ngIf="landFencingForm.get('cName')?.invalid && landFencingForm.get('cName')?.touched">
                        Name is required
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="Name">Mobile Number</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="mobile" name="mobile">
                    <div class="text-danger" *ngIf="landFencingForm.get('mobile')?.invalid && landFencingForm.get('mobile')?.touched">
                        Mobile Number is required
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="Name">Email Id</label><span class="text-danger">*</span>
                    <input type="text" class="form-control" placeholder="Type here" formControlName="email" name="email">
                    <div class="text-danger" *ngIf="landFencingForm.get('email')?.invalid && landFencingForm.get('email')?.touched">
                        Email Id is required
                    </div>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-md-4">
                    <label for="Company Name">Description ( Upto 200 words )</label><span class="text-danger">*</span>
                    <textarea class="form-control" rows="5" id="comment" formControlName="description" name="description"></textarea>
                    <div class="text-danger" *ngIf="landFencingForm.get('description')?.invalid && landFencingForm.get('description')?.touched">
                        Description is required
                    </div>
                </div>
            </div>
            <button class="btn btn-primary pb-save" type="submit">submit</button>
            <!-- <button class="btn  pb-addproject ml-3"><i class="ri-add-line"></i> create another project finance</button> -->
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">Land Fencing </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <app-nexus-addon-tables [headers]="landFencingTableHeaders" [data]="landFencingTableData"></app-nexus-addon-tables>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>
<app-mb-land-fencing *ngIf="isMobileLayout"></app-mb-land-fencing>
