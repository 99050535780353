<p>pg-description works!</p>
<main class="pb-search" *ngIf="property"> 
    <div class="container">
        <div class="row">
            <section>
                <div class="d-flex justify-content-between prop_subcat">
                  <div class="prop_name">
                    <h1 class="text-primary">{{property.pgName}} <button class="btn btn-primary bluebtn">{{property.area}}, {{property.city}}</button></h1>
                    <p><a href="#">  OCCUPENCY TYPE : {{occpencyType}}</a></p>
                  </div>
                  <div class="prop_price">
                    <h2>Per Month <b>9000/-</b> onwards</h2>
                    <!-- <a href="#">Deposit Amount : One Month</a> -->
                </div>
                </div>
            </section>
            <div id="gallery" class="photos-grid-container gallery">
              <div class="main-photo img-box" *ngIf="property.images && property.images.length">
                <a href="{{property.images[0]}}" class="glightbox" data-glightbox="type: image">
                    <img src="{{property.images[0]}}" alt="image" /></a>
            </div>
            <div>
              <div class="sub">
                  <div class="img-box" *ngIf="property.images && property.images.length >1">
                      <a href="{{property.images[1]}}" class="glightbox" data-glightbox="type: image">
                          <img src="{{property.images[1]}}" alt="image" />
                      </a>
                  </div>
                  <div id="multi-link" class="img-box" *ngIf="property.images && property.images.length >2 ">
                      <a href="{{property.images[2]}}" class="glightbox" data-glightbox="type: image">
                          <img src="{{property.images[2]}}" alt="image" />
                          <div class="transparent-box">
                              <div class="caption">
                                  +3 more
                              </div>
                          </div>
                      </a>
                  </div>
              </div>
          </div>
                <div id="more-img" class="extra-images-container hide-element">
                  <a href="assets/img/image1.png" alt="image" class="glightbox" data-glightbox="type: image">
                    <img src="assets/img/image1.png" alt="image" />
                  </a>
                  <a href="assets/img/image2.png" alt="image" class="glightbox" data-glightbox="type: image">
                    <img src="assets/img/image2.png" alt="image" />
                  </a>
                </div>
            </div>
            <div class="prop_btns" style="text-align: center;">
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <button class="btn btn-call" (click)="contact(property)"><i class="bi bi-whatsapp"></i> Schedule Video call</button>
                    </li>
                    <li class="list-inline-item">
                        <button class="btn btn-freesite" (click)="contact(property)">Contact Owner</button>
                    </li>
                </ul>
            </div>
            <div class="detail_list">
                <ul class="list-group list-group-horizontal justify-content-center">
                    <li class="list-group-item" *ngFor="let textarr of textArray">
                        <h3>{{textarr.attributePropertyName}}</h3>
                        <p>{{textarr.attributeName}}</p>
                    </li>
                    <!-- <li class="list-group-item">
                        <h3>Sept 2025</h3>
                        <p>Possession Starts</p>
                    </li>
                    <li class="list-group-item">
                        <h3>₹7.4K/sq.ft</h3>
                        <p>Avg. Price</p>
                    </li>
                    <li class="list-group-item">
                        <h3>1590 sq.ft - 2060 sq.ft</h3>
                        <p>Sizes (Built up Area)</p>
                    </li>
                    <li class="list-group-item">
                      <h3>Sept 2025</h3>
                      <p>Possession Starts</p>
                  </li>
                  <li class="list-group-item">
                      <h3>₹7.4K/sq.ft</h3>
                      <p>Avg. Price</p>
                  </li>
                  <li class="list-group-item">
                      <h3>1590 sq.ft - 2060 sq.ft</h3>
                      <p>Sizes (Built up Area)</p>
                  </li> -->
                </ul>
            </div>
            <div class="col-lg-12">
                <div class="menu-container" [ngClass]="{'scrolled': tabScrolled}" id="overview">
                  <!-- [ngClass]="{'scrolled': tabScrolled}" -->
                    <button class="prev-btn"><i class="ri-arrow-left-s-line"></i></button>
                    <div class="menu">
                        <ul class="menu-list" role="tablist">
                            <li *ngFor="let tab of rentTabData" [ngClass]="{'hide':!tab.IsExists}">
                                <a [ngClass]="{'active':tab.active}" (click)="scrollToSection(tab.tabSection,tab.tabName)" *ngIf="isSectionVisible(tab.sectionApiName)">{{tab.tabName}}</a>
                            </li>
                        </ul>
                    </div>
                    <button class="next-btn"><i class="ri-arrow-right-s-line"></i></button>
                </div>
            </div>
            <div class="col-lg-10 my-5">
                <div class="property_content_wrapper">
                    <div class="prop_overview bg-white p-5 mb-4 rounded-2" id="project" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>About This Property</h2>
                        <!-- <a href="javascript:void(0)" class="text-decoration-underline">View more on Maps <i class="bi bi-chevron-right"></i></a> -->
                    </div>
                    <div class="rating">
                        <ul class="list-inline">
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>1.2K reviews</span></li>
                            <!-- <li class="list-inline-item">
                                <p class="ranking">
                                    Rank 44 /1200 Localities
                                </p>
                            </li> -->
                        </ul>
                    </div>
                    <div>
                      <!-- <p>It’s a 2,3BHK multistorey apartment situated in JP Nagar, Miyapur. It has an area of 1100 sqft onwards . The property is available at a price of Rs. 85 lakhs onwards . Default unfurnished Units. It has piped gas connection for all flats. Other amenities includes community buildings, waste management and disposal, solar energy systems, Elevators, Rain water harvesting, Crazy Cycling & Jogging Track, New Street Solar lighting. This residential complex is under-construction as of now. It is made in way to provide a comfortable living. It is situated in the proximity of all the important facilities.</p> -->
                        <p>{{property.pgDescription}}</p> 
                    </div>
                    </div> <!--About section-->
                    <div class="more_details bg-white p-5 mb-4 rounded-2" id="moredetails" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>More Details</h2>
                        <!-- <a href="javascript:void(0)" class="text-decoration-underline">Download Brochure <i class="bi bi-chevron-right"></i></a> -->
                    </div>
                    <div class="more_listitems">
                        <div class="more_list">
                          <div>
                            <ul class="list-group" style="width:50%;float: left;">
                                <li class="list-group-item" *ngFor="let attribute of property.attributes | slice : 0 : firstHalf">
                                    <div class="left">
                                        <p>{{attribute.attributeName}}</p>
                                        <p class="right">{{attribute.attributePropertyName}}</p>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-group" style="width:50%;">
                              <li class="list-group-item" *ngFor="let attribute of property.attributes | slice : firstHalf : property.attributes.length">
                                  <div class="left">
                                      <p>{{attribute.attributeName}}</p>
                                      <p class="right">{{attribute.attributePropertyName}}</p>
                                  </div>
                              </li>
                          </ul>
                        </div>
                        <br/>
                            <div class="prop_btns" style="text-align: left;">
                              <ul class="list-inline">
                                  <li class="list-inline-item">
                                      <button (click)="contact(property)" class="btn btn-freesite"> Ask For Details</button>
                                  </li>
                                  <!-- <li class="list-inline-item">
                                      <button (click)="contact(property)" class="btn btn-call"><i class="bi bi-share-fill"></i> Share</button>
                                  </li> -->
                                  <li class="list-inline-item">
                                    <button (click)="contact(property)" class="btn btn-call"><i class="bi bi-heart"></i> Save</button>
                                </li>
                              </ul>
                          </div>
                        </div>
                        
                    </div>
                    </div> <!--more section-->
                    <div class="amenities bg-white p-5 mb-4 rounded-2" id="amenities" #section>
                      <div class="heading d-flex justify-content-between align-items-center">
                          <h2>Amenities</h2>
                      </div>
                      <div class="amenities_list">
                          <ul class="list-inline">
                              <li class="list-inline-item" *ngFor="let addattr of property.amenities | slice:0:6">
                                  <p><i  *ngIf="getIcon(addattr.attributePropertyName)"  [class]="getIcon(addattr.attributePropertyName)"></i> <span>{{addattr.attributePropertyName}}</span></p>
                              </li>
                              <p *ngIf="property.amenities.length>6" (click)="toggleContentAmenities()" class="viewmore">View all Amenities <i class="bi bi-chevron-down"></i></p>
                              <div *ngIf="property.amenities.length>6 && isContentVisible" class="more_amenities">
                                  <li class="list-inline-item" *ngFor="let addattr of property.amenities | slice:6:property.amenities.length">
                                      <p><i class="ri-fridge-fill"></i> <span>{{addattr.attributePropertyName}}</span></p>
                                  </li>  
                              </div>
                          </ul>
                      </div>
                      </div> <!--amenities section-->
                      <div class="help_tool bg-white p-5 mb-4 rounded-2"  #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>Occupency</h2>
                        </div>
                        
                        <div class="help_wrapper">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let occ of occupancyTypeList">
                                  <div class="help_details">
                                    <div class="pb_listimage">
                                      <a>  
                                      <img style="height: 160px;width: 100%;" [src]="occ.previewImage && occ.previewImage.length ? occ.previewImage[0] : 'assets/img/help_img.jpg'" alt="">
                                      </a>
                                    </div>
                                      <div class="help_text">
                                          <h3>{{occ.subCategoryName}}</h3>
                                          <table>
                                            <tr>
                                              <td class="text-gray" width="50%">Rent</td>
                                              <td class="text-gray" width="50%">Rooms Available</td>
                                            </tr>
                                            <tr>
                                              <td class="text-gray">{{occ.monthlyRent}}</td>
                                              <td class="text-gray">{{occ.rooms}}</td>
                                            </tr>
                                            <tr>
                                              <td colspan="2"></td>
                                            </tr>
                                            <tr>
                                              <td colspan="2"></td>
                                            </tr>
                                            <tr *ngIf="property.amenities.length">
                                              <td colspan="2"><h3>Amenities</h3></td>
                                            </tr>
                                            <tr>
                                              <td colspan="2">
                                                <span *ngFor="let ame of property.amenities"><i class="text-success ri-checkbox-fill"></i> {{ame.attributePropertyName}} </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="2" align="center"><button (click)="contact(property)" class="btn btn-danger">Contact Owner</button></td>
                                            </tr>
                                          </table>
                                      </div>
                                  </div>
                                </div>
                                
                            </div>
                        </div>
                        
                        </div> <!--Occupency section-->
  
                        <div class="amenities bg-white p-5 mb-4 rounded-2" id="{{add.propertyTabId}}" #section *ngFor="let add of additionalAttrs">
                          <div class="heading d-flex justify-content-between align-items-center">
                              <h2>{{add.propertyTabName}}</h2>
                          </div>
                          <div class="amenities_list food">
                              <ul class="list-inline">
                                  <li class="list-inline-item" *ngFor="let prop of add.attributes">
                                      <p class="text-center"><i *ngIf="getAttrIcon(prop.attributeName)"  [class]="getAttrIcon(prop.attributeName)"></i></p>
                                      <p class="text-center">{{prop.attributeLabel}}</p>
                                      <div>
                                        <span *ngFor="let d of prop.swatchNameList">
                                          <i *ngIf="d.toLowerCase()!='no' && d.toLowerCase()!='not-allowed'" style="font-size: 24px;" class="text-success ri-checkbox-fill"></i> 
                                          <i *ngIf="d.toLowerCase()=='no' || d.toLowerCase()=='not-allowed'" style="border: 1px solid;" class="text-danger ri-close-fill"></i> 
                                          {{d}}
                                      </span>
                                       </div>
                                      <!-- <div><i class="ri-checkbox-fill"></i> {{}}</div> -->
                                  </li>
                                <!-- <li class="list-inline-item">
                                  <p class="text-center"><i class="ri-fridge-fill"></i></p>
                                  <p class="text-center">Meals Provided</p>
                                  <div><i class="ri-checkbox-fill"></i> Veg</div>
                                  <div><i class="ri-checkbox-fill"></i> Non Veg</div>
                              </li>
                              <li class="list-inline-item">
                                <p class="text-center"><i class="ri-fridge-fill"></i></p>
                                  <p class="text-center">Fridge</p>
                            </li> -->
                           
                            </ul>
                          </div>
                          </div> <!--food and kitchen section-->
                          <!-- <div class="amenities bg-white p-5 mb-4 rounded-2" id="amenities" #section>
                            <div class="heading d-flex justify-content-between align-items-center">
                                <h2>House rules</h2>
                            </div>
                            <div class="moreamenities_list">
                                <ul class="list-inline">
                                  <li class="list-inline-item">
                                      <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Gate Closing Time</b> <br/>
                                         <span class="text-gray">No specific time</span> 
                                      </div>
                                  </li>
                                  <li class="list-inline-item">
                                    <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Visitor Entry</b> <br/>
                                         <span class="text-gray">Allowed</span> 
                                      </div>
                                </li>
                                <li class="list-inline-item">
                                  <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Non-Veg Food</b> <br/>
                                         <span class="text-gray">Allowed</span> 
                                      </div>
                              </li>
                              <li class="list-inline-item">
                                <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Opposite Gender</b> <br/>
                                         <span class="text-gray">Allowed</span> 
                                      </div>
                            </li>
                            <li class="list-inline-item">
                              <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Smoking</b> <br/>
                                         <span class="text-gray">Allowed</span> 
                                      </div>
                          </li>
                          <li class="list-inline-item">
                            <div><i class="ri-fridge-fill"></i></div>
                                      <div class="lineHeight">
                                         <b> Drinking</b> <br/>
                                         <span class="text-gray">Allowed</span> 
                                      </div>
                        </li>
  
                                  <p class="viewmore text-primary">View all Amenities <i class="bi bi-chevron-down"></i></p>
                              </ul>
                            </div>
                            </div>  -->
                            <!--House rules section-->
                    
                    
                    </div> <!--media section-->
                    
                    <div class="explore_map bg-white p-5 mb-4 rounded-2" id="locationHighlights" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Explore Neighbourhood -  Map View</h2>
                        <a href="javascript:void(0)" class="text-decoration-underline"> Explore Locality <i class="bi bi-chevron-right"></i></a>
                    </div>
                    <div class="map_rating">
                        <ul class="list-inline">
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>1.2K reviews</span></li>
                            <li class="list-inline-item">
                                <p class="ranking">
                                    Rank 44 /1200 Localities
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="map_embed">
                        <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [restriction]="mapRestriction">
                            <agm-marker [latitude]="lat" [longitude]="long" [markerDraggable]="false"></agm-marker>
                        </agm-map>
                    </div>
                    <!-- <div class="map-aminites">
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Environment</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>3.7</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Commuting</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>3.7</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Places of interest</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>4.8</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                    </div> -->
                    <div id="map_slider" class="owl-carousel about_slider_wrapper owl-loaded owl-drag">
                      <ng-container *ngFor="let item of property.locationHighlights | keyvalue">
                        <ng-container *ngIf="item.value && item.value !== null && item.key !=='aboutLocality'">
                            <div class="item prop_overview">
                                <div class="about_slider_content">
                                    <p> <i class="ri-school-line"></i> </p>
                                    <h3>{{propertyNames[item.key]}}</h3>
                                    <ul class="list-inline" *ngIf="item.value">
                                        <li *ngFor="let value of item.value.split(',')">
                                            <i class="ri-check-line"></i> <span> {{value}} </span>
                                        </li>
                                        <!-- <li>
                                <i class="ri-check-line"></i> <span> Edge Global School </span>
                            </li> -->
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    </div>
                    </div> <!--Map section-->
                 
                    <div class="locality_guide bg-white p-5 mb-4 rounded-2" id="landmarks" #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>Locality Guide</h2>
                            <a href="javascript:void(0)" class="text-decoration-none"> <i class="bi bi-share-fill"></i> Share</a>
                        </div>
                        <div class="guide_wrapper">
                            <div class="photos-grid-container gallery">
                                <div class="main-photo img-box">
                                    <a href="assets/img/prop_img_big.png" class="glightbox" data-glightbox="type: image">
                                        <img src="assets/img/prop_img_big.png" alt="image" /></a>
                                </div>
                                <div>
                                    <div class="sub">
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img1.png" alt="image" />
                                        </a>
                                    </div>
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img2.png" alt="image" />
                                        </a>
                                    </div>
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img3.png" alt="image" />
                                        </a>
                                    </div>
                                    <div id="multi-link" class="img-box">
                                        <a href="assets/img/img2.png" class="glightbox" data-glightbox="type: image">
                                        <img src="assets/img/img2.png" alt="image" />
                                        <div class="transparent-box">
                                            <div class="caption">
                                            +3 more
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                    </div>
                                </div>
                                <div id="more-img" class="extra-images-container hide-element">
                                    <a href="assets/img/image1.png" alt="image" class="glightbox" data-glightbox="type: image">
                                    <img src="assets/img/image1.png" alt="image" />
                                    </a>
                                    <a href="assets/img/image2.png" alt="image" class="glightbox" data-glightbox="type: image">
                                    <img src="assets/img/image2.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="locality_details">
                          <p style="font-size: 12px;">This is a commercial and residential hub in Hyderabad,
                              Telangana. Located at the
                              crossroads of the main city, it has new residential projects coming up in its vicinity.
                              <a (click)="toggleOpt()" *ngIf="togleOption">Read More</a>
  
                          </p>
  
                      </div>
                      <ng-container *ngIf="toggle" class="locality_details">
  
                          <p style="font-size: 12px; color:#606060">A number of TSRTC buses ply through this area
                              regularly, thus,
                              enabling smooth access to the city and its adjacent suburbs. It is also a major stop and
                              pick up point for long distance buses to Chennai Vijayawada, Rajamahendravaram, Guntur,
                              Nellore, Visakhapatnam, and many other prominent places. </p>
                          <p>Real estate</p>
                          <p style="font-size: 12px;color:#606060">There are several apartment and plot projects
                              coming up in the
                              area by renowned real estate developers.</p>
  
                          <p> Social infrastructure </p>
                          <p style="font-size: 12px;color:#606060"> Pallavi International School, CBSE Model School,
                              Krishnaveni
                              Talent School, and many others boast of imparting quality education.</p>
  
                          <p style="font-size: 12px;color:#606060">Sai Sharan Hospitals, Medicare Hospital, Maruthi
                              Hospital,
                              Galaxy Hospitals, Tirumala Multispeciality Hospital, SVS Hospitals, Sri Sai Sagar
                              Hospital, Krishna Sai Multispeciality Hospital,
                              State Bank of India, ICICI Bank, Vijaya Bank, Federal Bank, etc. are some of the major
                              banks that serve this locality.
                              It also boasts of a fair share of other basic social amenities such as ATMs, petrol
                              pumps, bus stops, etc.<a (click)="lessToggle()"
                                  style="color: blue; font-size: 11px;"><u>Show Less</u></a>
                          </p>
  
                      </ng-container>
                        </div> <!--Locality guide-->
                   
                   
                </div>
                <div class="col-lg-2 my-5" *ngIf="!lookupModel.getLoggedUser()">
                  <div class="right_fixed" [ngClass]="{'scrolled': isScrolled}">
                      <form [formGroup]="signupForm" (ngSubmit)="register()">
                          <div class="contact_seller bg-white px-2 py-3 rounded-3 border">
                              <h4 class="badge">Contact Sellers :</h4>
                              <h5>Please share your contact</h5>
                              <div class="mb-3">
                                  <input type="text" name="" class="form-control border-0 border-bottom rounded-0"
                                      placeholder="Name" formControlName="firstName">
                                  <span *ngIf="firstName.touched && firstName.errors && firstName.errors.required"
                                      style="color: red">First Name is requried.</span>
                              </div>
                              <div class="mb-3">
                                  <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                                      [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="false"
                                      [selectFirstCountry]="false" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                                      style="width: 100%;" [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                      [selectedCountryISO]="selectedISOCountry" name="Mobile" formControlName="mobile">
                                  </ngx-intl-tel-input>
                                  <span class="text-danger" *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                      Mobile Number is required.
                                  </span>
                                  <span class="text-danger"
                                      *ngIf="mobile.touched && mobile.errors && mobile.errors.validatePhoneNumber">
                                      Enter Valid Mobile Number.
                                  </span>
                                  <span *ngIf="mobile.valid && mobileExists" style="color:red;" id="mobileexists">
                                      Mobile Already Exists, Please Login
                                  </span>
                                  <span *ngIf="mobile.touched && mobile.errors && mobile.errors.required" style="color:red;"
                                      id="emailerror">
                                      Mobile is required.
                                  </span>
                                  <span
                                      *ngIf="mobile.touched && !mobile.valid && mobile.errors && mobile.errors.validatePhoneNumber"
                                      style="color:red;" id="mobileNotValid">
                                      Enter Valid Mobile Number.
                                  </span>
                                  <span *ngIf="!mobileOtpResent && mobileOtpSent && !mobileOtpVerified && !mobileOtpMaxFlag"
                                      style="color:green;" id="mobileotpsuccessmsg1">
                                      OTP Sent to mobile successfully.
                                  </span>
                                  <span *ngIf="mobileOtpResent && !mobileOtpVerified" style="color:green;"
                                      id="mobileotpsuccessmsg1">
                                      OTP Resent to mobile successfully.
                                  </span>
                                  <span *ngIf="mobileOtpMaxFlag" style="color:red;" id="maxOtpError">
                                      Your OTP sending limit is over for today, please try after 24 hours again.
                                  </span>
                                  <span *ngIf="mobileOtpVerified" style="color:green;" id="emailotpsuccessmsg3">
                                      mobile Verified Successfully
                                  </span>
                                  <span *ngIf="mobileOtpError" style="color:red;" id="exists">
                                      Error in sending OTP. Please try again.
                                  </span>
                                  <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                                  <span class="text-success" *ngIf="success_message">{{success_message}}</span>
                                  <ul class="list-inline sendbtn mt-3">
                                      <li class="list-inline-item">
                                          <button type="button" class="btn btn-default2" *ngIf="!mobileOtpSent"
                                              [disabled]="mobile.invalid" (click)="sendMobileOtp(mobile.value)">
                                              SEND OTP</button>
                                          <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent"
                                              [disabled]="mobile.invalid" (click)="resendMobileOtp(mobile.value)">
                                              RE-SEND
                                              OTP</button>
                                      </li>
      
                                      <li class="list-inline-item">
                                          <p (click)="changeMobile()" class="change_number">Change
                                              Mobile Number</p>
                                          <!-- <button type="button" class="btn btn-warning resend"
                                      (click)="changeMobile()">Change
                                      Mobile Number</button> -->
                                      </li>
                                  </ul>
                              </div>
      
                              <div class="mb-3">
                                  <input type="text" class="form-control border-0 border-bottom rounded-0" placeholder="OTP"
                                      name="mobileOtp" formControlName="mobileOtp">
                                  <p *ngIf="mobileOtp.touched && mobileOtp.errors && mobileOtp.errors.required"
                                      style="color:red;" id="emailOtperror">
                                      OTP is required.
                                  </p>
                                  <p *ngIf="mobileOtpCheck && !mobileOtpVerified" style="color:red;" id="mobileOtperror2">
                                      OTP is incorrect.
                                  </p>
      
                              </div>
      
                              <div class="mb-3">
                                  <input type="text" name="email" formControlName="email"
                                      class="form-control border-0 border-bottom rounded-0" placeholder="Email" name="email"
                                      formControlName="email">
                                  <p *ngIf="email.touched && email.errors && email.errors.required" style="color:red;"
                                      id="emailRequired">
                                      Email is required.
                                  </p>
                                  <p *ngIf="email.errors && email.errors.email" style="color:red;" id="emailerror">
                                      Enter a valid Email.
                                  </p>
                              </div>
      
      
                              <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="agree" checked>
                                  <label class="form-check-label" for="agree">
                                      I agree to be contacted by agents.
                                  </label>
                              </div>
                              <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="Loans">
                                  <label class="form-check-label" for="Loans">
                                      I am interested in Home Loans
                                  </label>
                              </div>
                              <div class="condetails">
                                  <button class="btn btn-primary">Get contact details</button>
                              </div>
                          </div>
                      </form>
                      <!-- <div class="saveproperty bg-white px-2 py-3 rounded-3 border mt-3 text-center">
                              <h2>Save this property for now & view it anytime.</h2>
                              <button class="btn btn-outline-primary rounded-pill"><i class="bi bi-heart"></i> save</button>
                          </div>
                          <button class="btn btn-share"><i class="bi bi-share"></i> Share</button> -->
                  </div>
              </div>
        </div>
    </div>
  
  </main><!-- End #main -->
  