import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mb-loans',
  templateUrl: './mb-loans.component.html',
  styleUrls: ['./mb-loans.component.css']
})
export class MbLoansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
