import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rent-table-list',
  templateUrl: './rent-table-list.component.html',
  styleUrls: ['./rent-table-list.component.css']
})
export class RentTableListComponent implements OnInit {
  @Input() allListings: any[];

  constructor() { }

  ngOnInit(): void {
    console.log("allListings from parrent of my profile",JSON.stringify(this.allListings));
  }

}
