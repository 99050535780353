import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
export interface IMenuOption {
  label: string;
  active: boolean;
  pageView:number;
  sideMenuicon: string
}
@Component({
  selector: 'app-rent-sidebar',
  templateUrl: './rent-sidebar.component.html',
  styleUrls: ['./rent-sidebar.component.css']
})
export class RentSidebarComponent implements OnInit {
  menuOptions: Array<IMenuOption> = [
    {
      label: 'Location',
      active: true,
      pageView:1,
      sideMenuicon: 'ri-map-pin-line'
    },
    {
      label: 'Property Details',
      active: false,
      pageView:2,
      sideMenuicon: "ri-information-2-line"
    },
    {
      label: 'Features',
      active: false,
      pageView:3,
      sideMenuicon: 'ri-file-settings-line'


    },
    {

      label: 'Gallery',
      active: false,
      pageView:4,
      sideMenuicon: "ri-image-add-fill"
    },
   
    {

      label: 'Property Highlights',
      active: false,
      pageView:6,
      sideMenuicon: "ri-hotel-line"

    },
    {

      label: 'Location Highlights',
      active: false,
      pageView:7,
      sideMenuicon: "ri-map-pin-range-line"

    },
    
    {

      label: 'Publish',
      active: false,
      pageView:9,
      sideMenuicon: "ri-share-forward-2-fill"

    }
    
    
  ]
  subMenuOpenFlag: boolean = false;
  pageView:number = 1;
  constructor(private router: Router,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.rsbService.data$.subscribe(data => {
      this.pageView = data;
      this.openMenuItem(this.pageView)
    });
  }
  openMenuItem(pageView:number): void {
    this.menuOptions.map((obj) => {
      if(obj.pageView === pageView) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    })
  }

  home(): void {
    this.router.navigate(['dashboard'])
  }


}
