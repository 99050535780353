<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="d-inline-block mr-3 mb-4">  Interior Design Enquiry</h2>
        <form class="agent-profile">
            <div class="form-row mb-2">
                <div class="col">
                    <label for="Name">Project Type</label>
                    <select class="form-control">
                        <option value="">Select</option>
                        <option value="">1bhk</option>
                        <option value="">2bhk</option>
                        <option value="">3bhk</option>      
                    </select>
                </div>
                <div class="col">
                    <label for="Name">Required Budget</label>
                    <input type="text" class="form-control" placeholder="Type here">
                </div>
                <div class="col">
                    <label for="Name">Project Location</label>
                    <select class="form-control">
                        <option value="">Select</option>
                        <option value="">Hyderabad</option>
                        <option value="">Mumbai</option>
                        <option value="">Vizag</option>      
                    </select>
                    <small class="text-muted"><em>Please select the city</em></small>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-md-4">
                    <label for="Company Name">Description ( Upto 200 words )</label>
                    <textarea class="form-control" rows="5" id="comment"></textarea>
                </div>
            </div>
            <button class="btn btn-primary pb-save">submit</button>
            <button class="btn  pb-addproject ml-3"><i class="ri-add-line"></i> create another project finance</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">PROJECT FINANCES </h3>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th><input type="checkbox"></th>
                                        <th>Project Type</th>
                                        <th>Date Added</th>
                                        <th>Project Location</th>
                                        <th>Required Budget</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox"></td>
                                        <td>Priya Kumar</td>
                                        <td>15-03-2024</td>
                                        <td>Hyderabad</td>
                                        <td>25,80,000/-</td>  
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>