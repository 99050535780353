
<div class="modal" tabindex="-1" role="dialog" *ngIf="showModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Date and Time</h5>
          <button type="button" class="close" (click)="closeModal()">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="datetime-local" #input (change)="onChange(input.value)">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
          <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
  