import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-project-finance',
  templateUrl: './project-finance.component.html',
  styleUrls: ['./project-finance.component.css']
})
export class ProjectFinanceComponent implements OnInit {
  projectFinanceRecords:Array<any> = []
  propertyTypes=["Home","Apartment","Villa","Society","Mall / Complex","Agri Land","Others"]
  projectFinanceForm:FormGroup;
  isMobileLayout:boolean=false;
  projectFinanceTableHeaders = [ 'Project Type', 'Required Budget','Project Location','Description','Actions'];
  projectFinanceTableData: any[] = [];

  

  constructor(private fb:FormBuilder,
    private rsbService:RsbService,
    private lookupModel:RsbLookupModel
  ) { }
  
  ngOnInit(): void {

    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;

    this.getProjectFinance();
    this.projectFinanceForm=this.fb.group({
      projectType:['',[Validators.required]],
      Budget:['',[Validators.required]],
      location:['',[Validators.required]],
      description:['',[Validators.required]]
      
    })
  }

  getProjectFinance(): void {
    this.rsbService.getProjectFinance().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.projectFinanceRecords = rsp.contents;
        this.projectFinanceTableData = rsp.contents.map(record => ({
          // 'Customer Name': record.cName,
          'Project Type': record.projectType,
          'Required Budget': record.reqBudget,
          'Project Location': record.location,
          'Description': record.comment,
          'Actions': 'delete'
        }));

      }
    })
  }
  submit(){
    if (this.projectFinanceForm.invalid) {
      Object.values(this.projectFinanceForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      customerId:this.lookupModel.getLoggedUser().associateId,
      projectType:this.projectType.value,
      budget:this.budget.value,
      location:this.location.value,
      description:this.description.value,
    }
    this.rsbService.addProjectFinance(args).subscribe((rsp)=>{
      if(rsp.statusCode===0){
        this.getProjectFinance();
        this.projectFinanceForm.reset();
      }
      

    })

  }
  get projectType() {return this.projectFinanceForm.get('projectType')}
  get budget() {return this.projectFinanceForm.get('Budget')}
  get location() {return this.projectFinanceForm.get('location')}
  get description() {return this.projectFinanceForm.get('description')}


}

