<div class="mb-dashboard">
    <div class="container">

        <div class="mb-profile-card">
            <h2>Land Survey</h2>
            <div class="mb-edit-profile">

                <form class="agent-profile" [formGroup]="landSurveyForm" (ngSubmit)="submit()">
                    <div class="mb-profile-name">
                        <div class="row g-2">
                            <div class="col-12">
                                <label for="Name">Project Location</label><span class="text-danger">*</span>
                                <div class="dropdown-wrapper">
                                    <select class="form-control" formControlName="location" name="location">
                                        <option value="">Select</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Vizag">Vizag</option>
                                    </select>
                                    <span class="dropdown-icon"></span>
                                </div>
                                <div class="text-danger" *ngIf="landSurveyForm.get('location')?.invalid && landSurveyForm.get('location')?.touched">
                                    Location is required
                                </div>
                                <small class="text-muted"><em>Please select the city</em></small>
                            </div>
                            <div class="col-12">
                                <label for="Name">No Of Acrs</label><span class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="acrs"
                                    name="acrs">
                                    <div class="text-danger" *ngIf="landSurveyForm.get('acrs')?.invalid && landSurveyForm.get('acrs')?.touched">
                                        Acrs is required
                                    </div>
                            </div>
                            <div class="col-12">
                                <label for="Name">Budget</label><span class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="Budget"
                                    name="Budget">
                                    <div class="text-danger" *ngIf="landSurveyForm.get('Budget')?.invalid && landSurveyForm.get('Budget')?.touched">
                                        Budget is required
                                    </div>
                            </div>
                            <div class="col-12">
                                <label for="Name">Customer Name</label><span class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="cName"
                                    name="cName">
                                    <div class="text-danger" *ngIf="landSurveyForm.get('cName')?.invalid && landSurveyForm.get('cName')?.touched">
                                        Name is required
                                    </div>
                            </div>
                            <div class="col-12">
                                <label for="Name">Mobile Number</label><span class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="mobile" name="mobile">
                                <div class="text-danger" *ngIf="landSurveyForm.get('mobile')?.invalid && landSurveyForm.get('mobile')?.touched">
                                    Mobile Number is required
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="Name">Email Id</label><span class="text-danger">*</span>
                                <input type="text" class="form-control" placeholder="Type here" formControlName="email" name="email">
                                <div class="text-danger" *ngIf="landSurveyForm.get('email')?.invalid && landSurveyForm.get('email')?.touched">
                                    Email Id is required
                                </div>
                            
                            </div>
                           
                            <div class="col-12">
                                <label for="Company Name">Description ( Upto 200 words )</label><span class="text-danger">*</span>
                                <textarea class="form-control" rows="5" id="comment" formControlName="description" name="description"></textarea>
                                <div class="text-danger" *ngIf="landSurveyForm.get('description')?.invalid && landSurveyForm.get('description')?.touched">
                                    Description is required
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-success rounded-pill" type="submit">Submit</button>
                                <p class="btn btn-light rounded-pill addbtn mb-0"><i class="ri-add-line"></i> Add</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <h3>Land Survey</h3>
                                <div class="right-search">
                                    <input type="text" class="form-control mb-3 rounded-4" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control  rounded-4 mt-1">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>

                            <!-- <div class="table-head-fixed">
                                <table class="table table-striped">
                                    <thead class="table-dark">
                                        <tr>
                                            <th><input type="checkbox"></th>
                                            <th>Customer Name</th>
                                            <th>Project Type</th>
                                            <th>Property Type</th>
                                            <th>Required Budget</th>
                                            <th>Project Location</th>
                                            <th>Mobile Number</th>
                                            <th>Email Id</th>
                                            <th>Date Added</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let records of cctvRecords ">
                                            <td><input type="checkbox"></td>
                                            <td>{{records.cName}}</td>
                                            <td>{{records.projectType}}</td>
                                            <td>{{records.propertyType}}</td>
                                            <td>{{records.budget}}</td> 
                                            <td>{{records.location}}</td>  
                                            <td>{{records.mobile}}</td>  
                                            <td>{{records.email}}</td> 
                                            <td>05-09-2024</td> 
                                            <td>{{records.description}}</td> 
                                            <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div> -->
                            <app-nexus-addon-tables [headers]="landsurveyTableHeaders" [data]="landsurveyTableData"></app-nexus-addon-tables>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 pb-4 text-center">
                <small class="copy-right-text">All trademarks, logos and names are properties of their respective
                    owners. Copyright © 2024 - All rights reserved.</small>
            </div>
        </div>

    </div>

    <footer>
        <nav class="navbar fixed-bottom bg-secondary">
            <div class="container-fluid">
                <ul class="nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Terms & Conditions </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Policy</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-white" href="#">Home</a>
                    </li>
                </ul>
            </div>
        </nav>
    </footer>
</div>