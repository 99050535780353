import { Component, OnInit, ElementRef, ViewChild, NgZone, Input, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SeoService } from 'src/app/common/services/seo.service';
import { HttpParams } from '@angular/common/http';
import { SharePopUpComponent } from '../share-pop-up/share-pop-up.component';
import { SignupPopupComponent } from '../signup-popup/signup-popup.component';
import { RequestSiteVisitPopupComponent } from '../request-site-visit-popup/request-site-visit-popup.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pg-listings',
  templateUrl: './pg-listings.component.html',
  styleUrls: ['./pg-listings.component.css']
})
export class PgListingsComponent implements OnInit {
  private cityTextRef: ElementRef;
  getPlanId:any;
  @ViewChild('cityEntryBox') cityEntryBox!: ElementRef;
  cities :any=[];
  @ViewChild('cityTextRef') set getCityEle(cityTextRef: ElementRef) {
    this.cityTextRef = cityTextRef;
    if (this.cityTextRef && this.lookupModel.isBrowser) {
     // this.prepareCityAutoComplete();
    // if(!this.cities.length && this.searchInt){
      //console.log(this.lookupModel.getCurrentLocation());
      // let obj = this.lookupModel.getCurrentLocation();
      // let latlang = {lat:obj.latitude,lng:obj.longitude};
      // this.selectedCity.cityName = obj.city;
      if(!this.cities || !this.cities.length){
     let lat = this.queryParams.lat.split(",");
     let lng = this.queryParams.long.split(",");
     let loc = this.queryParams.location.split(",");
     let pin = this.queryParams.zip.split(",");
     for(var x=0;x<lat.length;x++){
     let obj={
       cityName: !x ? this.queryParams.city:loc[x-1],
       macroCity: !x ? this.queryParams.city:loc[x-1],
       cityBounds: !x ? JSON.parse(this.queryParams.cityBounds):"",
       zip: pin[x],
       lat: lat[x],
       long: lng[x],
     }
     this.cities.push(obj);
     }
     //console.log(this.cities);
      this.geoCoder = new google.maps.Geocoder;
      this.requestPlace={
        componentRestrictions: {
          country: this.lookupModel.selectedCountry.twoDigitCountryCode,
        },
        types: this.cities.length ? ['geocode'] : ['(cities)'],
        bounds: this.cities[0].cityBounds,
        strictBounds: true
      }
      this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, this.requestPlace);
      this.cityAutocomplete.addListener('place_changed', this.changeEvent);
    }
     // this.getAddress(latlang); 
  }
    //}
  }
  private localityTextRef: ElementRef;
  private requestPlace :any;
  subCatName:any=[];
  searchboxFocus:boolean=true;
  searchMore: boolean;
  searchInt : boolean=true;
  @ViewChild('localityTextRef') set getLocalityEle(localityElement: ElementRef) {
    if (localityElement && this.lookupModel.isBrowser) {
      this.localityTextRef = localityElement;
    }
  }
  private geoCoder;
  private cityAutocomplete: any;
  private localityAutocomplete: any;
  cityBounds: any;
  firstTwoValues : any[] = [];
  lastValues:any[] = [];
  selectedcodeType: string;
  listingContents: number = 0;
  nearByListingContents: number = 0;
  minDistance: Number = 1000;
  maxDistance: Number = 20000;
  listings: Array<any> = [];
  catList: Array<any> = [];
  attrList: Array<any> = [];
  amenitiesList: Array<any> = [];
  subCatList: Array<any> = [];
  selBudgetCount:any=[];
  selAmenitiesCount:any=[];
  bugetList: Array<any> = [];
  selectedCity: any;
  selectedLocality: any;
  filterForm: FormGroup;
  selectedCategory: any;
  selectedSubCat: any;
  selectedBudget: any;
  selectedAttribute: any;
  copyMessage:string="";

  selectedModule: any;
  selectedProp:boolean=false;
  selectedHostelIds: string[] = [];
  selectedPropCount:any;
  propertyObj:any;

  properties:any;
  showViewFlag: boolean = false;
  p: number = 1;
  count:number =0;
  queryParams: any;
  vendorTypes:[]
  sortValue: string = ''
  allListings: any[] = [];
  selectedSubCatList  = [];
  selAttributeProperty = [];
  listingLength: any;
  attributeCols: string;
  text: any = '';
  textNew: any = '';
  urlHeading: any;
  showMoreProperties: Boolean = false;
  isDropdownVisible: boolean = false;
  isSubCategoryDisplay:boolean = false;
  isBudgetDisplay:boolean = false;
  isAmenitiesDisplay:boolean = false;
  shortenedContent: string;
  combinedContent: any;
  showFullContent: boolean[] = [];
  favoriteActive:boolean=false;
  favoriteStates: { [key: string]: boolean } = {};
  isFixed = false;
  constructor(
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private fb: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.listings.forEach(() => this.showFullContent.push(false));
  }

  ngOnInit(): void {
    this.getPlanId=this.lookupModel.getLoggedUserPersonalDetails().planId;
    this.selectedCategory = this.lookupModel.getCategory();
    this.catList = this.lookupModel.getCategoryList();
      this.loadDetails();
    
  }
  getIcon(key: string): string {
    switch (key) {
      case 'banks':
        return 'assets/img/bankIcon.svg';
      case 'hospitals':
        return 'assets/img/hospital-fill.svg';
      case 'colleges':
        return 'assets/img/school-fill.svg';
        case 'transport':
        return 'assets/img/truck-fill.svg';
        case 'touristSpots':
        return 'assets/img/tourist-fill.svg';
        case 'hotelResorts':
        return 'assets/img/Food.svg';
        case 'ResidentialComplex':
          return 'assets/img/apartments-fill.svg';
          case 'mallsMultiplex':
            return 'assets/img/apartments-fill.svg';
            case 'shoppingCenter':
            return 'assets/img/apartments-fill.svg';
          
        
      default:
        return undefined;
    }
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  ngAfterViewInit() {
    
  }
  enableSubCategoryMenu() : void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isBudgetDisplay = false;
    this.isAmenitiesDisplay = false;
    this.isSubCategoryDisplay = !this.isSubCategoryDisplay;
  }
  enableBudgetMenu() : void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isSubCategoryDisplay = false;
    this.isAmenitiesDisplay = false;
    this.isBudgetDisplay = !this.isBudgetDisplay;
  }
  enableAmenitiesMenu() : void {
    this.attrList.forEach((obj) => {
      obj.isAttrDisplay=false;
    })
    this.isSubCategoryDisplay = false;
    this.isBudgetDisplay = false;
    this.isAmenitiesDisplay = !this.isAmenitiesDisplay;
  }
  enableFilters(findex:number): void {
    this.isSubCategoryDisplay = false
    this.isBudgetDisplay = false
    this.isAmenitiesDisplay = false;
    this.attrList.forEach((obj ,index) => {
      findex != index ? obj.isAttrDisplay=false : "";
    })
    this.attrList[findex]['isAttrDisplay'] = !this.attrList[findex]['isAttrDisplay'];
  }

  filterBySubCategory(index: any,getListings?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    this.subCatList[index].isSelected = !this.subCatList[index].isSelected;
    //subCat['isSelected'] = !subCat['isSelected'];
    if(this.subCatList[index].isSelected){
      this.subCatName.push(this.subCatList[index].subCategoryName);
    } else {
      var eleInd = this.subCatName.indexOf(this.subCatList[index].subCategoryName);
      this.subCatName.splice(eleInd,1);
    }
    //this.selSubCatCount=this.subCatList[index].isSelected ? this.selSubCatCount + 1 : this.selSubCatCount - 1;
    console.log("getlistings is",this.subCatList[index]);
    if (getListings) {
      this.getPropertyListings();
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if(this.cityEntryBox && !this.searchboxFocus){
    if (!this.cityEntryBox.nativeElement.contains(event.target)) {
      // Click occurred outside the element
      this.searchboxFocus=true;
      this.searchMore = this.cities.length>1 && this.searchboxFocus ? true:false;
    }
  }
  }
  startSearch():void{
    this.searchboxFocus=false;
    this.searchMore = this.cities.length>1 && this.searchboxFocus ? true:false;
  }
  removeEle(index:number){
    this.cities.splice(index, 1);
    this.searchInt = false;
    if(this.cities.length<4){
      this.cityTextRef.nativeElement.disabled=null;
      }
    if(!this.cities.length){
      this.cityTextRef.nativeElement.placeholder="Search by Location, Project Name";
      //this.cityAutocomplete.unbind("bounds");
      this.cityAutocomplete.setTypes(['(cities)']);
      //this.cityAutocomplete.setBounds({});
      this.cityAutocomplete.setOptions({
        strictBounds: false,
      });
    }
  }
  changeEvent=():void =>{
    // this.ngZone.run(() => {
       //get the place result
       debugger;
       let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

       //verify yt67
       if (!place || place.geometry === undefined || place.geometry === null) {
         return;
       }
       if (place.geometry && place.geometry.location) {
         let viewport = place.geometry.viewport;
         this.selectedCity = {
           cityName: place.name,
           macroCity: place.address_components[1].long_name,
           cityBounds: place.geometry.viewport,
           zip: '',
           lat: place.geometry.location.lat(),
           long: place.geometry.location.lng(),
 
         }
         this.cityBounds = place.geometry.viewport;
        // this.cityAuto.setValue(place.name);
         this.cities.push(this.selectedCity);
         this.city.setValue("");
         this.cityTextRef.nativeElement.value="";
       if(this.cities.length==1){
         this.cityTextRef.nativeElement.placeholder="Add more...";
         this.cityAutocomplete.unbind("bounds");
         this.cityAutocomplete.setTypes(["geocode"]);
         this.cityAutocomplete.setBounds(viewport);
         this.cityAutocomplete.setOptions({
           strictBounds: true,
         });
       }
       if(this.cities.length==4){
         this.cityTextRef.nativeElement.disabled="disabled";
         //this.cityTextRef.nativeElement.style="{background:'#c1c1c1'}";
       }
     }
     this.getAddress({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}).subscribe((rsp) => {
      this.getPropertyListings();
    });
       //this.getAddress({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
       // this.submit();
     //});
 }

  // fetchDetailsFromUlr(params: any): Observable<any> {
  //   return Observable.create(observer => {
  //     this.rsbService.getActiveCategories(params.module).subscribe((rsp) => {
  //       if(rsp.statusCode === 0 && rsp.contents){
  //         this.catList = rsp.contents;
  //         this.lookupModel.setCategoryList(this.catList);
  //         const cat = this.catList.find(item => item.categoryId === params.cat);
  //         if(cat) {
  //           this.selectedCategory = cat;
  //           this.lookupModel.setCategory(cat);

  //           if (params.subcat) {
  //             const subCatArray = this.queryParams.subcat.split(',');
  //             this.subCatName =[];
  //             //this.selSubCatCount=subCatArray.length;
  //             this.selectedCategory.subCategory.forEach((item,index) => {
  //               if (subCatArray.includes(item.subCategoryId) === true) {
  //                 this.subCatName.push(item.subCategoryName);
  //                 item["isSelected"] = true;
  //               }
  //             });
  //             if (this.queryParams.subcat) {
  //               this.lookupModel.setSubCategory(this.queryParams.subcat && this.queryParams.subcat.includes(',') ? this.queryParams.subcat.split(',') : [this.queryParams.subcat]);
  //             }
  //           }
  //           if (this.queryParams.city) {
  //             const city = { cityName: this.queryParams.location, macroCity: this.queryParams.city, zip: this.queryParams.zip, cityBounds: null, lat: 0, long: 0 };
  //             if (this.queryParams.lat) {
  //               city.lat = parseFloat(this.queryParams.lat);
  //               city.long = parseFloat(this.queryParams.long);
  //             }
  //             this.lookupModel.setCity(city);
  
  //           }
  //           observer.next('success');
  //           observer.complete();
  //         } else {
  //           observer.error();
  //         }
  //       } else {
  //         this.catList = [];
  //         observer.error();
  //       }
  //     }, (err) => observer.error());
  //   });
  // }
  selectedCheckProp(property: any) {
    // alert(JSON.stringify(property));
this.propertyObj=property;
    console.log(property);
    // this.selectedProp = this.listings.some(p => p.selected);
    const selectedListingId = property.hostelId; 

    if (property.selected) {
      if (!this.selectedHostelIds.includes(selectedListingId)) {
        this.selectedHostelIds.push(selectedListingId);
      }
    } else {
      const index = this.selectedHostelIds.indexOf(selectedListingId);
      if (index !== -1) {
        this.selectedHostelIds.splice(index, 1);
      }
    }
    this.selectedPropCount = this.selectedHostelIds.length; 

    this.selectedProp = this.selectedHostelIds.length > 0;
    // alert(this.selectedHostelIds)
    // this.lookupModel.setSelectedPropListingIDs(this.selectedListingIds);
  

  }

  shareSingleProp(property:any){
    const hostelId=property.hostelId;
    const baseUrl ='https://pillarblocks.com/pgHostels/'+property.city+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+property.hostelId;
    const modelRef =this.modalService.open(SharePopUpComponent);
    modelRef.componentInstance.url = baseUrl
  
  }
  sharePopUp(){
    if(this.selectedHostelIds.length>1){
      const baseUrl ='https://pillarblocks.com/pgHostels/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+this.selectedHostelIds.join(",");
      const modelRef =this.modalService.open(SharePopUpComponent);
      modelRef.componentInstance.url = baseUrl
    }
    else{
    // const replaceStr=this.properties.pgName;
    //  const pgName=replaceStr.replace(/ /g, '-');
     const baseUrl ='https://pillarblocks.com/pgHostels/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+this.selectedHostelIds.join(",");
    const modelRef =this.modalService.open(SharePopUpComponent);
    modelRef.componentInstance.url = baseUrl
    }

  }
  copyLink() {
    let baseUrl: string;
    if(this.selectedHostelIds.length>1){
    baseUrl ='https://pillarblocks.com/pgHostels/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+this.selectedHostelIds.join(",");
      
    }
    else{
      const replaceStr=this.propertyObj.pgName;
      const pgName=replaceStr.replace(/ /g, '-');
      baseUrl ='https://pillarblocks.com/pgHostels/'+this.queryParams.city+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+this.selectedHostelIds.join(",");
     
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(baseUrl).then(() => {
        this.copyMessage = 'Link copied!'; 
        this.clearCopyMessageAfterDelay(); 
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = baseUrl;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.copyMessage = 'Link copied!'; 
      this.clearCopyMessageAfterDelay(); 
    }

    // navigator.clipboard.writeText(baseUrl).then(() => {
    // this.copyMessage="Link Copied!"
    // }).catch(err => {
    //   console.error('Could not copy text: ', err);
    // });
  }
  clearCopyMessageAfterDelay() {
    setTimeout(() => {
      this.copyMessage = ''; 
    }, 2000); 
  }
  favorites(){
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      selectedhostelIds:this.selectedHostelIds
    }
    // alert(JSON.stringify(args))

    this.rsbService.favoritePghostel(args).subscribe((res)=>{
      // alert(res)
    })
    this.router.navigate(['favorite-hostels'])
  }

  favoriteSingleProp(property:any){
    this.favoriteStates[property.hostelId] = true;
    this.selectedHostelIds.push(property.hostelId);

    this.favoriteActive=true;
    const args={
      associateId:this.lookupModel.getLoggedUser().associateId,
      selectedHostelIds:this.selectedHostelIds
    }
    // alert(JSON.stringify(args))
  
    this.rsbService.favoritePghostel(args).subscribe((res)=>{
      // alert(res)
    })
  
  }

  loadDetails(): void {
    this.selectedModule =  this.lookupModel.getModuleList().find((obj) => {
      return obj.moduleId === this.queryParams.module;
    }) ;
  
    this.selectedcodeType = this.lookupModel.getCountry().codeType;
    //this.subCatList = [{ subCategoryName: 'All ' + this.selectedCategory.categoryName, subCategoryId: '' }];
    // this.subCatList = this.queryParams.subcat.join(",");
    this.selectedCity = this.lookupModel.getCity();
    if (this.selectedCity) {
      this.cityBounds = this.selectedCity.cityBounds;
    }
    if (this.lookupModel.getLocality()) {
      this.selectedLocality = this.lookupModel.getLocality();
    }
    this.filterForm = this.fb.group({
      subCat: [this.selectedSubCat ? this.selectedSubCat.subCategoryId : ''],
      //city: [this.selectedCity && this.selectedCity.cityName ? this.selectedCity.cityName : ''],
      city:[''],
      locality: [this.selectedLocality ? this.selectedLocality.localityName : ''],
      budget: [''],
      beds: ['']
    });
    this.getFilterProperties();
    this.getPropertyListings();
    this.locality.disable();
    this.showViewFlag = true;
    this.formChanges();
  }

  formChanges(): void {
  

    this.subCat.valueChanges.subscribe(val => {
      // if (val) {
      this.selectedSubCat = this.subCatList.find(cat => cat.subCategoryId === val);
      this.lookupModel.setSubCategory(this.selectedSubCat);
      this.getFilterProperties();
      this.getPropertyListings();
      // }
    });

    // this.city.valueChanges.subscribe((val) => {
    //   if (!val) {
    //     this.selectedCity = {};
    //     this.getPropertyListings();
    //     // this.toastrService.warning('Please enter city');
    //   }
    // });

    this.locality.valueChanges.subscribe((val) => {
      if (!val) {
        this.selectedLocality = null;
        this.getPropertyListings();
      }
    });

    this.beds.valueChanges.subscribe(val => {
      if (val) {
        this.selectedAttribute = val
        this.getPropertyListings();
      } else {
        this.selectedAttribute = null;
        this.getPropertyListings();
      }
    });

    this.budget.valueChanges.subscribe(val => {
      if (val) {
        this.selectedBudget = this.bugetList.find(budget => budget.budgetId === val);
        this.getPropertyListings();
      } else {
        this.selectedBudget = null;
        this.getPropertyListings();
      }
    });
  }

  getLocationLength(obj): number {
    let count = 0;
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '' && key != "aboutLocality") {
        count++;
      }
    }
    
      // this.getFirstTwoNonNullValues(obj)
      // this.getLastNonNullValues(obj);
    return count;
  }

  getFirstTwoNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        if(count <2) { 
        this.firstTwoValues.push({ key: key, value: obj[key] });
        }
        count++;
        if (count === 2) {
          break; // Exit the loop after finding the first two non-null values
        }
      }
    }
  }

  getLastNonNullValues(obj): void {
    let count = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        count++;
        if (count >= 2) {
          this.lastValues.push({ key: key, value: obj[key] });// Exit the loop after finding the first two non-null values
        }
      }
    }
  }

  choosePlanForShare(property:any){
    const firstName=this.lookupModel.getLoggedUser().personalDetails.firstName;
    const propImage=property.propertyPreviewImage
  
    // this.router.navigate(['choosePlan'])
    Swal.fire({
      title: "Hello!"+firstName,
      html: 'Please Subscribe any <span style="color:red">Plan</span> for sharing of properties',
      // imageUrl: propImage,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
       confirmButtonText: 'Plans'
  
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/choosePlan']);
      }
    });
  }
  
  //private baseUrl = 'https://pillarblocks.com/associate/';
  url(prop: any, queryParams: any): string {
    const baseUrl = 'https://pillarblocks.com/pgHostels/'+queryParams.city+'/'+prop.pgName+'/'+this.lookupModel.getLoggedUser().associateId+'?hostelId='+prop.hostelId 
    console.log("query params", queryParams);
    let httpUrl = baseUrl;
    // if (queryParams) {
    //   let params = new HttpParams();
    //   for (let key in queryParams) {
    //     if (queryParams.hasOwnProperty(key)) {
    //       params = params.set(key, queryParams[key]);
    //     }
    //   }
    //   httpUrl += `?${params.toString()}`;
    // }
    return httpUrl;
  }
  
  

  getFilterProperties(): void {
    const subCatArray = this.queryParams.subcat.split(",");
    this.rsbService.getAllPgAttributesForWebsiteFilters(this.queryParams.module).subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        let mainlist = rsp.contents;
        mainlist.map(item => item.setAttrCount=[]);
        this.attrList = mainlist.filter(item => item.attributeName != "Amenities" && item.attributeName != "Occupancy Type");
        let list = mainlist.filter((item) =>  { return item.attributeName == "Amenities" });
        this.amenitiesList = list[0].attributeProperties;
        this.selectedSubCatList = mainlist.filter((item) =>  { return  item.attributeName == "Occupancy Type" });
        this.subCatList = this.selectedSubCatList[0].attributeProperties;
        
        this.subCatList.forEach((obj) => {
          
          if(subCatArray.includes(obj.propertyId)) {
            obj["isSelected"] = true;
            this.subCatName.push(obj.swatchName);
          } 
          
        })
        const attr = this.attrList.find(item => item.isDefault);
        if (attr) {
          this.beds.setValue(attr.attributePropertyId);
        }
      } else {
        this.attrList = [];
      }
    });
    this.rsbService.getActiveBudgetsForFilters().subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.bugetList = rsp.contents;
        const budget = this.bugetList.find(item => item.isDefault);
        if (budget) {
          this.budget.setValue(budget.budgetId);
        }
      } else {
        this.bugetList = [];
      }
    });
  }

  prepareCityAutoComplete(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutocomplete = new google.maps.places.Autocomplete(this.cityTextRef.nativeElement, {
        componentRestrictions: {
          country: this.lookupModel.selectedCountry ? this.lookupModel.selectedCountry.twoDigitCountryCode : ''
        },
        // bounds: this.cityBounds,
        strictBounds: true
      });
      this.cityAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutocomplete.getPlace();

          //verify result
          if (!place || place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.selectedCity = {
            cityName: place.name,
            macroCity: place.address_components[1].long_name,
            cityBounds: place.geometry.viewport,
            zip: '',
            lat: place.geometry.location.lat(),
            long: place.geometry.location.lng(),
          };
          this.getAddress({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}).subscribe((rsp) => {
            this.getPropertyListings();
          });
          // this.locality.reset();
          // if (this.localityAutocomplete) {
          //   this.localityAutocomplete.bounds = this.cityBounds;
          // } else {
          //   this.prepareLocalityAutoComplete();
          // }
        });
      });
    });
  }

  prepareLocalityAutoComplete(): void {
    this.locality.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.localityAutocomplete = new google.maps.places.Autocomplete(this.localityTextRef.nativeElement, {
        componentRestrictions: {
          country: this.lookupModel.selectedCountry ? this.lookupModel.selectedCountry.twoDigitCountryCode : ''
        },
        bounds: this.cityBounds,
        strictBounds: true
      });
      this.localityAutocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.localityAutocomplete.getPlace();

          //verify result
          if (!place || place.geometry === undefined || place.geometry === null) {
            return;
          }
          // this.cityBounds = place.geometry.viewport;
          this.locality.setValue(place.name);
          this.selectedLocality = {
            localityName: place.name,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          };
          this.getPropertyListings();
        });
      });
    });
  }

  openProperty(property: any): void {
    this.lookupModel.setProperty(property);
    this.lookupModel.setCategory(this.selectedCategory);
    this.lookupModel.setSubCategory(this.selectedSubCat);
    if (this.selectedLocality && this.selectedLocality.localityName) {
      this.lookupModel.setLocality(this.selectedLocality);
    }
    this.lookupModel.setCity({ name: this.city.value, bounds: this.cityBounds });
    const text = property.pgName.replaceAll(' ', '-');
    console.log("text is",text);
    console.log("property is",property);
    console.log("this.selectedModule",this.selectedModule);
    this.router.navigate(
      ['pg-description', text],
      {
        queryParams: {
          hostelId: property.hostelId ? property.hostelId : "tzAo7E",
          cat: property.categoryId,
          subcat: property.subCategoryId,
          module: this.selectedModule.moduleId,
          country: property.countryId ? property.countryId : this.lookupModel.getCountry().codeId,
          city:property.city,
          area:property.area,
          pincode:property.pincode,
        }
      }
    );
  }

  updateScrollPos(event: any): void {
    if(event.endReached) {
    let listingIds = this.listings.map((obj) => {
      return obj.listingId
    })
    console.log('User is near bottom uppending rows');
    //let newList = this.allListings.slice(this.listings.length, this.listings.length + 20);
    //this.listings = this.listings.concat(newList);
    //this.cdr.detectChanges();
    if(this.cities.length){
    let location="",pincode="",lat="",long="";
    this.cities.filter((item,index)=>{ 
        index ? location = location ? location + "," + item.cityName : item.cityName :"";
        pincode = pincode ? pincode + "," + item.zip : item.zip;
        lat = lat ? lat + "," + item.lat : item.lat;
        long = long ? long + "," + item.long : item.long;
     })
    let list = []
    const args = {
      moduleId: [this.selectedModule.moduleId],
      subCategoryId: this.getSubCategoryFilterValues(),
      attributes: this.getAttributeFilterValues(),
      budgets: this.getBudgetFilterValues(),
      priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
      city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
      location: location,//this.selectedCity ? "" : this.queryParams.location,
      area: this.locality.value,
      countryId: this.lookupModel.getCountry().codeId,
      latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
      longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
      max: this.selectedLocality ? 3000 : 0,
      macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
      zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
      listings: listingIds,
      allSubCat: this.selectedCategory.subCategory,
      customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
      skipListingRecords: this.listingContents,
      nearBySkipListingRecords: this.nearByListingContents,
      minDistance: this.minDistance,
      maxDistance: this.maxDistance,
      sortValue: this.sortValue
    };
    args['listings'] = listingIds;
    this.rsbService.getHostelsForFilters(args).subscribe((rsp) => { 
      if (rsp.contents && rsp.contents.length > 0) {
        list = rsp.contents;
        this.listingContents += list.length;
        // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
        //   list.push(rsp.nearByLocationsContents);
        // }
        // this.listings = this.getText(list);
  
      }
      if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
        this.nearByListingContents += rsp.nearByLocations.length;
        list = list.concat(rsp.nearByLocations);
        this.minDistance = rsp.maxDistance;
        this.maxDistance = rsp.maxDistance + 20000;
      }
  
  
      this.listings = this.listings.concat(list);
      // For example, show only the first 100 characters
      this.combinedContent = this.listings.join(' ');
      this.shortenedContent = this.combinedContent.substring(0, 100) + '...';
      this.showFullContent;
    })
  }
}
    }
    toggleContent(index: number) {
      this.showFullContent[index] = !this.showFullContent[index];
    }
  getPropertyListings(event?:any): void {
    debugger;
    if(event?.endReached) {
      let listingIds = this.listings.map((obj) => {
        return obj.listingId
      })
    }
    if(this.cities.length){
    let location="",pincode="",lat="",long="";
    this.cities.filter((item,index)=>{ 
        index ? location = location ? location + "," + item.cityName : item.cityName :"";
        pincode = pincode ? pincode + "," + item.zip : item.zip;
        lat = lat ? lat + "," + item.lat : item.lat;
        long = long ? long + "," + item.long : item.long;
     })
    const args = {
      moduleId: [this.queryParams.module],
      subCategoryId: this.getSubCategoryFilterValues(),
      attributes: this.getAttributeFilterValues(),
      budgets: this.getBudgetFilterValues(),
      priceTypeId: this.selectedBudget ? this.lookupModel.getCountry().codeId : '',
      city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.cityName : this.queryParams.city,
      location:location,//this.selectedCity ? "" : this.queryParams.location,
      area: this.locality.value,
      macroCity: this.selectedCity ? this.selectedCity.macroCity : '',
      countryId: this.lookupModel.getCountry().codeId,
      latitude: lat,//this.selectedLocality ? this.selectedLocality.latitude.toFixed(2) : (this.selectedCity ? this.selectedCity.lat : ''),
      longitude: long,//this.selectedLocality ? this.selectedLocality.longitude.toFixed(2) : (this.selectedCity ? this.selectedCity.long : ''),
      max: this.selectedLocality ? 3000 : 0,
      zip: pincode,//this.selectedCity ? this.selectedCity.zip : this.queryParams.zip,
      listings: this.listings,
      customerListings: this.lookupModel.getLoggedUser() ? this.lookupModel.getLoggedUser().listings : [],
      minDistance: this.minDistance,
      maxDistance: this.maxDistance,
      skipListingRecords: this.listingContents,
      nearBySkipListingRecords: this.nearByListingContents,
      sortVal: this.sortValue
    };
    this.listings = [];
    this.rsbService.getHostelsForFilters(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        let list = [];
        if (rsp.contents && rsp.contents.length > 0) {

          list = rsp.contents;
          this.listingContents = list.length;
          //alert(list.length);
          // if(rsp.nearByLocationsContents && rsp.nearByLocationsContents.length > 0) {
          //   list.push(rsp.nearByLocationsContents);
          // }
          // this.listings = this.getText(list);
    
        }
        if (rsp.nearByLocations && rsp.nearByLocations.length > 0) {
          this.nearByListingContents = rsp.nearByLocations.length
          list = list.concat(rsp.nearByLocations);
          this.minDistance = rsp.maxDistance;
          this.maxDistance = rsp.maxDistance + 20000;
        }
        this.allListings = [...list];
        this.listingLength = rsp.count;
        if (this.listingLength > 0) {
          this.showMoreProperties = true
        }
        //alert("getting here");
        this.listings = list;
        this.listings.filter(re=>{
          re.attributesNew =[];
          if(re.attributes.length){
            for(var a=0;a<re.attributes.length;a++){
            if(re.attributes[a].attributes.length){
              re.attributesNew = re.attributesNew.concat(re.attributes[a].attributes);
            }
          }
          }
          if(re.locationHighlights){
          let obj= JSON.parse(JSON.stringify(re.locationHighlights));
          delete obj.aboutLocality;
          re.locationHighlightsNew = obj;
          }
        });
        this.listings = this.listings.sort((a, b) => a.price - b.price);

        console.log(this.listings)
        this.count = rsp.count;
        let text = '';
        if (this.beds.value !== 'abc' && this.selectedAttribute) {
          text += this.selectedAttribute.propertyName + this.selectedAttribute.attributeName + ' ';
        }
        if (this.subCat.value) {
          text += this.selectedCategory.categoryName + ' ' + this.selectedSubCat.subCategoryName + '\'s';
        }
        else if (this.listings.length > 0) {
          text += this.subCatList[0].subCategoryName + ' properties';
        } else {
          text += 'properties';
        }
        text += ' available for ' + this.lookupModel.getModule().moduleName
        if(this.selectedCity) {
          text += ' at ' + this.selectedCity.name;
        }

        this.prepareKeywords(text);
        this.router.navigate(
          ['pg', this.city.value ? this.city.value : 'all'],
          {
            queryParams: {
              //cat: this.selectedCategory.categoryId,
              subcat: this.getSubCategoryFilterValues().toString(),
              module:this.queryParams.module,
              location:location,// this.city.value ? this.city.value : this.queryParams.location,
              city: this.cities[0].cityName,//this.selectedCity ? this.selectedCity.macroCity : this.queryParams.city,
              zip: pincode,//rsp.zip ? rsp.zip : this.queryParams.zip,
              country: this.lookupModel.getCountry().codeId,
              cityBounds:JSON.stringify(this.cities[0].cityBounds),
              // loc: this.locality.value ? this.locality.value : null,
              lat: lat,//this.selectedCity ? this.selectedCity.lat:"",
              long: long,//this.selectedCity ? this.selectedCity.long:"",
            }
          }
        );
      }
    });
  }
  }

  prepareKeywords(text: string): void {
    let keywords = [];
    if (this.selectedCategory) {
      keywords.push(this.selectedCategory.categoryName);
    }
    if (this.selectedSubCat) {
      keywords.push(this.selectedSubCat.subCategoryName);
    }
    if (this.city.value) {
      const cityText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      const cityText2 = 'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.city.value;
      keywords.push(cityText1);
      keywords.push(cityText2);
    }
    if (this.locality.value) {
      const locText1 = this.selectedCategory.categoryName + ' Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      const locText2 =  'Properties for ' + this.lookupModel.getModule().moduleName + ' in ' + this.locality.value;
      keywords.push(locText1);
      keywords.push(locText2);

    }
    keywords.push(text);
    let keyWordText = keywords.join(', ');
    this.seoService.updateTag('keywords', keyWordText);
  }

  getText(listings: Array<any>): Array<any> {
    listings.forEach(property => {
      let text = '';
      if (property.attributes) {
        property.attributes.forEach(attr => {
          if (attr.attributeName.search('Bed') !== -1) {
            text += attr.attributePropertyName + ' Bed, ';
          }
          if (attr.attributeName.search('Bath') !== -1) {
            text += attr.attributePropertyName + 'Bath, ';
          }
        })
      }
      text += property.propertySize + ' ' + property.measurementName;
      property.bedBathText = text;
    });

    return listings;
  }

  removeFilter(filedName: string): void {
    switch (filedName) {
      case 'subCat': this.subCat.setValue(''); break;
      case 'city': this.city.setValue(''); break;
      case 'locality': this.locality.setValue(''); break;
      case 'budget': this.budget.setValue(''); break;
      case 'beds': this.beds.setValue(''); break;
    }
  }

  sortBy(filedName: string, event: any): void {
    switch (filedName) {
      case 'price':
        if (event.target.value === "1") {
          this.listings = this.listings.sort((a, b) => b.price - a.price);
        } else {
          this.listings = this.listings.sort((a, b) => a.price - b.price);
        }
        break;
    }
  }

  filterByBudget(index: any, getListings ?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.bugetList[index]);
    //budget.isSelected = !budget.isSelected;
    this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    if(this.bugetList[index].isSelected){
      this.selBudgetCount.push(this.bugetList[index].budgetName);
    } else {
      var eleInd = this.selBudgetCount.indexOf(this.bugetList[index].budgetName);
      this.selBudgetCount.splice(eleInd,1);
    }
    //this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    //this.selBudgetCount = this.bugetList[index].isSelected ? this.selBudgetCount+1 : this.selBudgetCount-1;
    if(getListings) {
      this.getPropertyListings();
    }
  }
  filterByAmenities(index: any, getListings ?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.bugetList[index]);
    //budget.isSelected = !budget.isSelected;
    this.amenitiesList[index].isSelected = !this.amenitiesList[index].isSelected;
    if(this.amenitiesList[index].isSelected){
      this.selAmenitiesCount.push(this.amenitiesList[index].swatchName);
    } else {
      var eleInd = this.selAmenitiesCount.indexOf(this.amenitiesList[index].swatchName);
      this.selAmenitiesCount.splice(eleInd,1);
    }
    //this.bugetList[index].isSelected = !this.bugetList[index].isSelected;
    //this.selBudgetCount = this.bugetList[index].isSelected ? this.selBudgetCount+1 : this.selBudgetCount-1;
    if(getListings) {
      this.getPropertyListings();
    }
  }
  filterByAttribute(pindex: any,cindex:any, getListings ?: boolean): void {
    this.minDistance = 1000;
    this.maxDistance = 20000;
    //console.log(this.attrList[pindex].attributeProperties[cindex]);
    //attribute.isSelected = !attribute.isSelected;
    this.attrList[pindex].attributeProperties[cindex].isSelected = !this.attrList[pindex].attributeProperties[cindex].isSelected; 
   // this.attrList[pindex].setAttrCount = this.attrList[pindex].attributeProperties[cindex].isSelected ? this.attrList[pindex].setAttrCount +1 : this.attrList[pindex].setAttrCount-1;
    if(this.attrList[pindex].attributeProperties[cindex].isSelected){
      this.attrList[pindex].setAttrCount.push(this.attrList[pindex].attributeProperties[cindex].swatchName);
    } else {
      var eleInd = this.attrList[pindex].setAttrCount.indexOf(this.attrList[pindex].attributeProperties[cindex].swatchName);
      this.attrList[pindex].setAttrCount.splice(eleInd,1);
    }
    if(getListings) {
      this.getPropertyListings();
    }
  }
  

  contactOwner(property: any): void {
    // alert(JSON.stringify(property))
    const selectedhostelId = property.hostelId;
    const modelRef=this.modalService.open(SignupPopupComponent, {size: 'lg', });
      modelRef.componentInstance.hostelId=selectedhostelId
      modelRef.result.then((response) => {
        // alert("getting response"+JSON.stringify(response)); 
        if(response.statusCode===0){
          
            const customerId=response.contents[0].hostelId;
            const hostelId=property.hostelId;
          
          this.rsbService.addPgToFavourite(customerId,hostelId).subscribe((res)=>{
            // alert("getting addListingsFavourites"+res)
            if(res.statusCode===0){
              Swal.fire({
                title: "Success",
                text: "Thank you for your request.We are working hard to find the best deals for you.Shortly you will find a confirmation in your Account",
                icon: "success",
                confirmButtonText: 'Continue'
    
              });
            }
          })
        }
      });
     
  }
  requestSiteVisit(property:any){
    const selectedhostelId = property.hostelId;
    const modelRef=this.modalService.open(RequestSiteVisitPopupComponent, {size: 'lg', });
    modelRef.componentInstance.hostelId=selectedhostelId
    modelRef.componentInstance.responseEventEmmiter.subscribe((response) => {
      // alert("getting response"+JSON.stringify(response)); 
      if(response.statusCode===0){
        
        // alert("expecting response"+response)
          const customerId=response.customerId;
          const hostelId=property.listingId;
          const date=response.date;
        
        this.rsbService.addPgToFavourite(customerId,hostelId,date).subscribe((res)=>{
          // alert("getting addListingsFavourites"+res)
        })
      }
    });



  }
  EMI(){
    Swal.fire({
      title: "<h1 style='color:black;font-size:30px'>Congratulations!</h1>",
      html: "You Can Avail <span style='color:red;'>2 Months EMI</span> Welcome Offer.",
      confirmButtonText:"Submit",
      confirmButtonColor:"#b1cd32",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    });
  }
  openCalender() {
    Swal.fire({
      title: 'Select a Date and Time',
      html: `<input type="datetime-local" id="datetime-input" class="swal2-input">`,
       confirmButtonText: 'Confirm',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const datetimeValue = (document.getElementById('datetime-input') as HTMLInputElement).value;
        return datetimeValue;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedDateTime = result.value;
        console.log('Selected Date and Time:', selectedDateTime);
        ('Selected Date and Time:'+selectedDateTime)
        // You can handle the selected date and time here
      }
    });
  }


  addToUserListing(property): void {
    const args = {
      listings: property.listingId,
      listingCustomerId: this.lookupModel.getProperty().vendorId,
      vendorMobile:property.vendorDetails.mobileNumber,
      vendorEmail:property.vendorDetails.email
    };
    this.rsbService.addListingsToAccount(args).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (this.lookupModel.getLoggedUser().activeStatus) {
          this.router.navigate(['my-listing']);
        } else {
          this.router.navigate(['profile']);
        }
      }
    });
  }
  getVendorDetails(property:any): void {
    this.lookupModel.setProperty(property);
    if (this.lookupModel.getLoggedUser().activeStatus) {
      this.router.navigate(['contact-info']);
    } else {
      this.lookupModel.setRedirection('contact-info')
      this.router.navigate(['profile']);
    }
  }

  getAddress(latLng: { lat: number, lng: number }, loadCity: boolean = false): Observable<any> {
    return Observable.create((observer) => {
      this.geoCoder.geocode({ 'location': latLng }, (results, status) => {
        if (status === 'OK') {
          //console.log(results);
          const postAddress = results.find(item => item.types.indexOf('postal_code') !== -1);
          //console.log(postAddress);
          if (postAddress) {
            const postalCode = postAddress.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
            if(postalCode) {
              this.selectedCity.zip = postalCode.long_name;
            this.cities.length ? this.cities[this.cities.length-1].zip=postalCode.long_name:"";
            }
            //console.log('FULL CITY ADDRESS :: ' , this.selectedCity);
          }
          // if(!this.cities.length){
          //   this.selectedCity.lat = latLng.lat;
          //   this.selectedCity.long = latLng.lng;          
          //   this.selectedCity.cityBounds = postAddress.geometry.viewport; 
          //   let boundsCur = this.selectedCity.cityBounds;
          //   this.cities.push(this.selectedCity)
          //   //console.log(this.fullCity);
          //   this.cityTextRef.nativeElement.placeholder="Add more...";
          //   this.cityAutocomplete.unbind("bounds");
          //   this.cityAutocomplete.setTypes(["geocode"]);
          //   this.cityAutocomplete.setBounds(boundsCur);
          // }
        }
        observer.next();
        observer.complete();
      });
    });
  }

  
  getBudgetFilterValues(): any[] {
    let budgetFil = [];
    this.bugetList.forEach(item => {
      if (item.isSelected) {
        const newFil = {
          from: item.min,
          to: item.max
        };
        budgetFil.push(newFil);
      }
    });
    //console.log('Budget Filters', budgetFil);
    return budgetFil;
  }

  cancelSelection() {
    this.selectedHostelIds = [];
    this.selectedPropCount = 0;
    this.selectedProp = false;
    this.listings.forEach(property => {
      property.selected = false;
    });
  }
  
  getSubCategoryFilterValues(): any[] {
    //alert("getting here 1");
    //alert(this.queryParams.subcat);
    let subCategoryFil = []
    subCategoryFil = this.selectedSubCatList[0]?.attributeProperties?.filter((obj) => {
      return obj.isSelected && obj.isSelected === true;
    }).map((obj) => {
      return obj.propertyId
    })
    //alert(this.selectedSubCatList.length);
    //alert("subCategoryFil is"+JSON.stringify(subCategoryFil));
    return subCategoryFil;
  }
  
  getAttributeFilterValues(): any[] {
    let attributeFil = [];
    this.selAttributeProperty = [];
    //this.selectedAttribute = this.queryParams.attribute;
    this.attrList.forEach(attr => {
      let attributeList = [];
      attr.attributeProperties.forEach(prop => {
        if (prop.isSelected) {
          // alert("getting here");
          attributeList.push(prop.propertyId);
          this.selAttributeProperty.push(prop.propertyId);
          //this.selectedAttribute.push(prop.propertyId);
        }
      });
      if (attributeList.length > 0) {
        const newAttr = {
          attribute: attributeList
        };
        attributeFil.push(newAttr);
      }
    });
    return attributeFil;
  }
  
  get subCat() { return this.filterForm.get('subCat'); }
  get city() { return this.filterForm.get('city'); }
  get locality() { return this.filterForm.get('locality'); }
  get budget() { return this.filterForm.get('budget'); }
  get beds() { return this.filterForm.get('beds'); }
}
