<section class="login_wrapper" *ngIf="!isMobileLayout">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-6">
                <div class="login_propLeft">
                    <div class="heading">
                        <h1>Welcome to <span>Pillar Blocks </span></h1>
                        <p>We make Real Estate Easy for everyone</p>
                    </div>
                    <div class="cashbackimg">
                        <img src="../../../assets/img/login_image.svg" alt="">
                    </div>
                   
                </div>
            </div>
            <div class="col-lg-6">
                <div class="logincontainer">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" id="LoginTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#Login" type="button"
                                role="tab" aria-controls="login" aria-selected="true" >
                                Login
                            </button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button class="nav-link"  data-bs-toggle="tab" data-bs-target="#SignUp" type="button"
                                role="tab" aria-controls="Sign Up" aria-selected="false">
                                Sign Up
                            </button>
                        </li> -->
                    </ul>

                    <!-- Tab panes -->

                    <div class="tab-content">

                        <div class="tab-pane active" id="Login" role="tabpanel" aria-labelledby="Login">
                            <div class="loginscreen">
                               
                                <form name="login" [formGroup]="loginForm" (ngSubmit)="login()" class="loginform">
                                    <div class="form-group">
                                        <label for="email" class="form-label">Phone Number</label><br>
                                        <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                                            [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                                            [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                                            [tooltipField]="TooltipLabel.Name" style="width: 125%;"
                                            [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                            [selectedCountryISO]="selectedISOCountry" name="loginMobile"
                                            formControlName="loginMobile">
                                        </ngx-intl-tel-input>
                                        <!-- <span class="text-danger"
                                            *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                            Mobile Number is required.
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="mobile.touched && mobile.errors && mobile.errors.validatePhoneNumber">
                                            Enter Valid Mobile Number.
                                        </span> -->
                                        <!-- <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                                        <span class="text-success" *ngIf="success_message">{{success_message}}</span> -->

                                    </div>
                                    <div class="form-group pwd mb-0">
                                        <label for="password" class="form-label">Password</label>
                                        <div class="input-group">
                                            <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" id="loginPassword" name="loginPassword" formControlName="loginPassword">
                                            <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                                <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                            </a>
                                        </div>
                                        <span
                                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.required"
                                            style="color:red;" id="emailOtperror">
                                            Password is required.
                                        </span>
                                        <span
                                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.minlength"
                                            style="color:red;" id="passwordminerror">
                                            Password will be minimum of 6 characters.
                                        </span>
                                        <span
                                            *ngIf="loginPassword.touched && loginPassword.errors && loginPassword.errors.maxlength"
                                            style="color:red;" id="passwordmaxerror">
                                            Password will be maximum of 10 characters.
                                        </span>
                                    </div>

                                    <a style="float: right;font-size:13px;margin:5px 0;" (click)="forgotPwd()">Forgot Password</a>

                                    <button class="btn btn-primary" type="submit">Login</button>
                                    <!-- <div class="divder my-4">
                                    <p> Or </p>
                                  </div>
                                  <button type="button" class="btn btn-light googlebtn"><i class="bi bi-google"></i> Log in with Google</button>
                                  <div class="newuser my-4">
                                    <p class="mb-0">New user? <a href="#SignUp">SIGN UP HERE</a> </p>
                                  </div> -->
                                </form>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<div class="mb-dashboard" *ngIf="isMobileLayout">
    
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="mb-login">
              <div class="mb-header-image">
                <img src="assets/mobile/img/mb-login-bg.png" alt="">
              </div>
              <div class="mb-header-text">
                <h1><small> Let’s</small> <strong> Sign In </strong></h1>
                <h5>We make Real Estate Easy for everyone</h5>
              </div>
              <form name="login" [formGroup]="loginForm" (ngSubmit)="login()" class="loginform">

              <div class="mb-loginform">
                <div class="form-floating mb-3 mt-3">
                  <label for="email" class="form-label">Phone Number</label><br>
                  <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="false"
                      [searchCountryFlag]="false" [selectFirstCountry]="false" [maxLength]="15"
                      [tooltipField]="TooltipLabel.Name" style="width: 125%;"
                      [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                      [selectedCountryISO]="selectedISOCountry" name="loginMobile"
                      formControlName="loginMobile">
                  </ngx-intl-tel-input>                </div>
                
                <div class="form-floating mt-3 mb-1">
                  <!-- <input type="text" class="form-control" id="pwd" placeholder="Enter password" name="pswd"> -->
                  <label for="password" class="form-label">Password</label>
                  <div class="input-group">
                      <input type="{{ hidePassword ? 'password' : 'text' }}" toggle class="form-control" id="loginPassword" name="loginPassword" formControlName="loginPassword">
                      <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                          <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                      </a>
                  </div>
                </div>
                <a href="javascript:void(0)" class="d-block mb-3" (click)="forgotPwd()">Forgot password?</a>
                <button  type="submit" class="btn btn-primary mb-loginbtn d-block w-75 m-auto py-3 mt-4">Login</button>
              </div>
              </form>
            </div>
        </div>
    </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
      
    </div>
    
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>