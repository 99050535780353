<div class="mb-dashboard" *ngIf="isMobileLayout">
    <div class="container">
      <div class="mb-profile-card my-3">
        <div class="mb-profile-header">
          <div class="mb_editicon d-flex align-items-center justify-content-between mb-4">
            <h2>Edit Profile</h2>
            <a (click)="backToProfile()"><i class="ri-arrow-left-line"></i> Back</a>
          </div>
        </div>
        <div class="mb-edit-profile">

          <form class="row g-3">
            <div class="mb-profile-name">
              <h3>Personal Information</h3>
            <form [formGroup]="profileForm" (ngSubmit)="submit()" #proForm="ngForm">

              <div class="row g-2">
                <div class="col-6">
                  <input type="text" class="form-control" formControlName="firstName" name="firstName" placeholder="First name" aria-label="First name">
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" formControlName="lastName" name="lastName"  placeholder="Last name" aria-label="Last name">
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" formControlName="bloodGroup" name="bloodGroup" placeholder="Blood Group" aria-label="Blood Group">
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" formControlName="email" name="email" placeholder="Email ID" aria-label="Email ID">
                </div>
                <div class="col-6">
                  <input type="text" class="form-control" formControlName="mobile" name="mobile" placeholder="Mobile Number" aria-label="Mobile Number">
                </div>
                <div class="col-6"> 
                  <input type="text" class="form-control" formControlName="emergencyContact" name="emergencyContact" placeholder="Emergency Contact Number" aria-label="Emergency Contact Number">
                </div>
                <div class="mb-3 text-center">
                    <button class="btn btn-darkgray" type="submit">Update</button>
                </div>
             </div>
            </form>
            </div>
            
            <div class="mb-profile-name">
              <h3>Present Address</h3>
              <form class="agent-profile"  [formGroup]="addressForm" (ngSubmit)="updateAddress()" #addrForm="ngForm">

              <div class="row g-2">

                <div class="col-6">
                    <input type="text" class="form-control" placeholder="House Number / Flat Number" formControlName="houseNumber">
                    <span
                    *ngIf="houseNumber.touched && houseNumber.errors && houseNumber.errors.required"
                    style="color:red;">
                    House Number is required
                     </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Building Name / Apartment Name" formControlName="buildingName">
                    <span
                     *ngIf="buildingName.touched && buildingName.errors && buildingName.errors.required"
                     style="color:red;">
                     Building Name is required
                      </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Locality / Colony / Street Name" formControlName="locality">
                    <span
                    *ngIf="locality.touched && locality.errors && locality.errors.required"
                    style="color:red;">
                    Locality is required
                     </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Village / Town / City" formControlName="village">
                    <span
                    *ngIf="village.touched && village.errors && village.errors.required"
                    style="color:red;">
                    Village is required
                     </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Tahsil / Municipality / City" formControlName="tahsil">
                    <span
                    *ngIf="tahsil.touched && tahsil.errors && tahsil.errors.required"
                    style="color:red;">
                    Tahsil is required
                     </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="District Name" formControlName="district">
                    <span
                    *ngIf="district.touched && district.errors && district.errors.required"
                    style="color:red;">
                    District is required
                     </span>
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="State Name" formControlName="state">
                    <span
                    *ngIf="state.touched && state.errors && state.errors.required"
                    style="color:red;">
                    State is required
                     </span>
                 </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="PIN CODE" formControlName="pincode">
                    <span
                    *ngIf="pincode.touched && pincode.errors && pincode.errors.required"
                    style="color:red;">
                    Pincode is required
                     </span>
                </div>
              </div>
              <div class="col">
                <input type="checkbox" style="width: 20px;height: 20px;" formControlName="sameAsPresent">
                <b>PERMENNENT ADDRESS <span class="text-danger">SAME AS PRESENT ADDRESS</span></b>
            </div>

              <div class="row g-2">

                <div class="col-6">
                    <input type="text" class="form-control" placeholder="House Number / Flat Number" formControlName="permanentHouseNumber">
                    <span
                    *ngIf="permanentHouseNumber.touched && permanentHouseNumber.errors && permanentHouseNumber.errors.required"
                    style="color:red;">
                    Permanent HouseNumber is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Building Name / Apartment Name" formControlName="permanentBuildingName">
                    <span
                    *ngIf="permanentBuildingName.touched && permanentBuildingName.errors && permanentBuildingName.errors.required"
                    style="color:red;">
                    Permanent BuildingName is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Locality / Colony / Street Name" formControlName="permanentLocality">
                    <span
                    *ngIf="permanentLocality.touched && permanentLocality.errors && permanentLocality.errors.required"
                    style="color:red;">
                    Permanent Locality is required
                     </span>               
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Village / Town / City" formControlName="permanentVillage">
                    <span
                    *ngIf="permanentVillage.touched && permanentVillage.errors && permanentVillage.errors.required"
                    style="color:red;">
                    Permanent Village is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Tahsil / Municipality / City" formControlName="permanentTahsil">
                    <span
                    *ngIf="permanentTahsil.touched && permanentTahsil.errors && permanentTahsil.errors.required"
                    style="color:red;">
                    Permanent Tahsil is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="District Name" formControlName="permanentDistrict">
                    <span
                    *ngIf="permanentDistrict.touched && permanentDistrict.errors && permanentDistrict.errors.required"
                    style="color:red;">
                    Permanent District is required
                     </span>                
                    
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="State Name" formControlName="permanentState">
                    <span
                    *ngIf="permanentState.touched && permanentState.errors && permanentState.errors.required"
                    style="color:red;">
                    Permanent State is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="PIN CODE" formControlName="permanentPincode" >
                    <span
                    *ngIf="permanentPincode.touched && permanentPincode.errors && permanentPincode.errors.required"
                    style="color:red;">
                    Permanent Pincode is required
                     </span>               
                </div>
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-darkgray" type="submit">Update</button>
            </div>
              </form>
            </div>
            <div class="mb-profile-name">
              <h3>Bank Account Details</h3>
              <form [formGroup]="bankAccountForm" (ngSubmit)="bankDetails()"  #AccountForm="ngForm">

              <div class="row g-2">

                <div class="col-6">
                    <input type="text"  class="form-control" placeholder="Type here" formControlName="holderName">
                    <span
                    *ngIf="holderName.touched && holderName.errors && holderName.errors.required"
                    style="color:red;">
                    Account Holder Nameis required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Type here" formControlName="accountNumber">
                    <span
                    *ngIf="accountNumber.touched && accountNumber.errors && accountNumber.errors.required"
                    style="color:red;">
                    Account Number Nameis required
                     </span>               
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Type here" formControlName="bankName">
                       
                    <span
                    *ngIf="bankName.touched && bankName.errors && bankName.errors.required"
                    style="color:red;">
                    Bank Name is required   
                     </span>               
                 </div>
                <div class="col-6">
                    <input type="text" class="form-control"  placeholder="Type here" formControlName="ifsc">
                    <span
                    *ngIf="ifsc.touched && ifsc.errors && ifsc.errors.required"
                    style="color:red;">
                    IFSC Code is required
                     </span>                
                </div>
                <div class="col mb-attached">
                    <!-- Hidden File Input -->
                    <input type="file" 
                           #imageInput 
                           accept="image/png, image/gif, image/jpeg" 
                           (change)="uploadBankPassBook($event)" 
                           formControlName="images" 
                           id="fileInput" 
                           class="file-input">
                
                   
                    <h6>(Attach Cancelled cheque / Passbook Copy) jpeg image, 20kb size</h6> 
                </div>
                
              <div class="mb-3 text-center">
                <button class="btn btn-darkgray" type="submit">Update</button>
            </div>
              </div>
            </form>

            </div>
            <div class="mb-profile-name">
              <h3>PAN Card Details</h3>
              <form [formGroup]="panForm" (ngSubmit)="panAccount()">

              <div class="row g-2">
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Type here" formControlName="nameAsPerPan">
                    <span
                    *ngIf="nameAsPerPan.touched && nameAsPerPan.errors && nameAsPerPan.errors.required"
                    style="color:red;">
                    Name is required
                     </span>                
                </div>
                <div class="col-6">
                    <input type="text" class="form-control" placeholder="Type here" formControlName="panNum">
                    <span *ngIf="lookupModel.getLoggedUser().panCardDetails && lookupModel.getLoggedUser().panCardDetails.panCard"><a href={{lookupModel.getLoggedUser().panCardDetails.panCard}} target="_blank">View Pan Card</a></span>

                    <div *ngIf="panForm.get('panNum').invalid && (panForm.get('panNum').dirty || panForm.get('panNum').touched)">
                        <div *ngIf="panForm.get('panNum').errors?.required" class="text-danger">
                          PAN Number is required.
                        </div>
                        <div *ngIf="panForm.get('panNum').errors?.invalidPAN" class="text-danger">
                          PAN Number is invalid.
                        </div>
                      </div>                
                    </div>

                <div class="col mb-attached">
                  <!-- <button class="btn btn-primary"><i class="ri-add-line"></i> Choose File</button> -->
                  <!-- <button class="btn btn-blue" type="button">
                    <input type="file"  #imageInput accept="image/png, image/gif, image/jpeg"
                    (change)="uploadPan($event)" formControlName="images" />
                     </button> -->
                     <div class="col mb-attached">
                        <!-- Hidden File Input -->
                        <input type="file" 
                               #imageInput 
                               accept="image/png, image/gif, image/jpeg" 
                               (change)="uploadPan($event)"
                               formControlName="images" 
                               id="fileInput" 
                               class="file-input">
                    
                       
                        <h6>(Attach Cancelled cheque / Passbook Copy) jpeg image, 20kb size</h6> 
                    </div>
                  <!-- <h6>(Attach PAN CARD) - jpeg image , 20kb size</h6> -->
                </div>
                <div class="mb-3 text-center">
                    <button class="btn btn-darkgray" type="submit">Update</button>
                </div>
                
              </div>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 pb-4 text-center">
          <small class="copy-right-text">All trademarks, logos and names are properties of their respective owners. Copyright © 2024 - All rights reserved.</small>
        </div>
      </div>
      
    </div>
    
    <footer>
      <nav class="navbar fixed-bottom bg-secondary">
        <div class="container-fluid">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Terms & Conditions </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#">Home</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>