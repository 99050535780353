import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-leads-for-pghostel',
  templateUrl: './leads-for-pghostel.component.html',
  styleUrls: ['./leads-for-pghostel.component.css']
})
export class LeadsForPghostelComponent implements OnInit {
  listings: Array<any> = [];

  constructor(private rsbService: RsbService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.rsbService.getLeadsForProperties().subscribe((res) => {
      if (res.contents && res.contents.length > 0) {
        console.log("Leads: ", res.contents);
        this.listings = res.contents;
      }
    })
  }
  getLeadsForProperties(){
    this.router.navigate(['leads-properties'])

  }
  getLeadsForProjects(){
    this.router.navigate(['leads-projects'])

  }
  getLeadsForPgHostels(){
    this.router.navigate(['leads-pgHostels'])

  }
  onSelectChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;

    switch (selectedValue) {
        case 'propertyLeads':
            this.getLeadsForProperties();
            break;
        case 'projectLeads':
            this.getLeadsForProjects();
            break;
        case 'pgHostelLeads':
            this.getLeadsForPgHostels();
            break;
        default:
            console.log('something went wrong');
            break;
    }
}


}
