import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-land-fencing',
  templateUrl: './land-fencing.component.html',
  styleUrls: ['./land-fencing.component.css']
})
export class LandFencingComponent implements OnInit {
  surveyList:Array<any> = [];
  landFencingForm:FormGroup;
  isMobileLayout:boolean=false;
  landFencingTableHeaders = ['Project Location', 'No Of Acrs', 'Budget','Customer Name','Mobile Number','Email Id','Description'];
  landFencingTableData: any[] = [];
  constructor(private fb:FormBuilder,
    private rsbService:RsbService,
    private toastr: ToastrService

  ) { }
  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1024;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1024;
    this.getFencing()
    this.landFencingForm=this.fb.group({
      acrs:['',[Validators.required]],
      Budget:['',[Validators.required]],
      location:['',[Validators.required]],
      cName:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required]],
      description:['',[Validators.required]]
      
    })
  }

  getFencing(): void {
    this.rsbService.getLandFencing().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.surveyList = rsp.contents;
        this.landFencingTableData = rsp.contents.map(record => ({
          'Project Location': record.location,
          'No Of Acrs': record.acrs || 'N/A',
          'Budget': record.budget,
          'Customer Name': record.cName,
          'Mobile Number':record.mobile,
          'Email Id': record.email,
          'Description': record.description,
        }));
        
      }
    })
  }
  submit(){
    if (this.landFencingForm.invalid) {
      Object.values(this.landFencingForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      acrs:this.acrs.value,
      budget:this.budget.value,
      location:this.location.value,
      cName:this.cName.value,
      mobile:this.mobile.value,
      email:this.email.value,
      description:this.description.value,
    }
    this.rsbService.fencingForLand(args).subscribe((rsp)=>{
      if(rsp.statusCode===0){
        this.toastr.success('Submitted successfully.');

        this.getFencing();
        this.landFencingForm.reset();

      }

    })

  }
  get acrs() {return this.landFencingForm.get('acrs')}
  get budget() {return this.landFencingForm.get('Budget')}
  get location() {return this.landFencingForm.get('location')}
  get cName() {return this.landFencingForm.get('cName')}
  get mobile() {return this.landFencingForm.get('mobile')}
  get email() {return this.landFencingForm.get('email')}
  get description() {return this.landFencingForm.get('description')}


}


