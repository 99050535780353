<ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center my-3" href="dashboard.html">
        <div class="sidebar-brand-icon">
            <img src="assets/img/apple-touch-icon.png" alt="">
        </div>
        <div class="sidebar-brand-text">
            <img src="assets/img/logo.png" alt="log" class="img-fluid">
        </div>
    </a>

    <!-- <ng-container *ngFor="let menu of menuOptions">
        
            <li class="nav-item" [ngClass]="{'active': menu.active}">
                <a  class="nav-link" (click)="openMenuItem(menu)">
                    <img  class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                    {{menu.label}}
                    
                </a>
            </li>
            <ng-container class="list_inner" *ngIf="menu.submenuList" [ngStyle]="{'display': menu.active ? 'block': 'none'}">
                <li class="nav-item" *ngFor="let submenu of menu.submenuList" [ngClass]="{'active': submenu.active}">
                    <img  class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;

                    <a class="nav-link" (click)="openSubmenuItem(menu,submenu)">{{submenu.label}}</a>
                </li>
            </ng-container>
        
    </ng-container> -->
    <ng-container *ngFor="let menu of menuOptions">
        <li class="nav-item" [ngClass]="{'active': menu.active}">
          <a class="nav-link" (click)="openMenuItem(menu)">
            <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
            <span 
            [ngStyle]="{'color': menu.label === 'Nexus Ad-On' && menu.active ? 'white' : 'white'}"
            *ngIf="menu.label === 'Nexus Ad-On'; else otherMenu"
            (click)="collapse()">
            {{ menu.label.split(' ')[0] }} <span class="addon">{{ menu.label.split(' ')[1] }}</span>
          </span>
          
          <ng-template #otherMenu>
            <span 
              [ngClass]="{'hover-black': true}" 
              [ngStyle]="{'color': menu.active ? 'black' : 'white'}"  (click)="closeCollapse()">
              {{menu.label}}
            </span>
          </ng-template>
          
            <span *ngIf="menu.label === 'Nexus Ad-On'">
              <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}" (click)="collapse()"></i>
            </span>
          </a>
      
          <ul *ngIf="subMenuLabelColl">
           
            <li *ngFor="let submenu of menu.submenuList" class="nav-item" [ngClass]="{'active': submenu.active}"  id="submenuItems">
              <a class="nav-link" style="background-color: #007bff;color: #ffffffcc;"  (click)="openSubmenuItem(menu, submenu)">
                <img style="width: 25px; height: auto;" [src]="submenu.sideSubMenuicon" alt="">&nbsp;
                {{submenu.label}}
              </a>
            </li>
       
          </ul>
      
        </li>
      </ng-container>
      



    
   
    <!-- <li class="nav-item" *ngFor="let menu of menuOptions">
        <a class="nav-link">
            <i class="ri-dashboard-fill"></i>
            <span> {{menu.label}}</span>
        </a>
          
    </li> -->
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle">
            <i class="ri-arrow-left-s-line"></i>
        </button>
    </div>
</ul>