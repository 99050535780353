<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h5 class="mb-0">Please Select Here Which Property You Want To Post</h5>
    </div>

    <!-- <div >
    <span>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2 mr-2" (click)="rentProperty()">RENT</button>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2 mr-2" (click)="SaleProperty()">SALE</button>
    <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2  mr-2" (click)="pgHostelProperty()">PG/HOSTELS</button>

    </span>
   </div> -->

        <h4>You are here to :</h4>
    
         <span *ngFor="let module of moduleList; let i =index">
                <button class="btn btn-primary rounded-pill border-0 btn-lg mb-2  mr-2" [ngClass]="{'active':module?.mainModuleId === selectedModule?.mainModuleId }"
                    (click)="selectModuleType(module)">{{module.moduleName}}</button>
            </span>
    

</div>