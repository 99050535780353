<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="mb-3">Project Reviews & Ratings</h2>
        <a href="" class="my-4 d-block">Ask Review & Rating</a>
        <ul class="reviewstar list-inline">
            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
            <li class="list-inline-item"><i class="ri-star-line"></i></li>
        </ul>
        <form class="agent-profile">
            <div class="form-row mb-4">
                <div class="col-md-4">
                    <label for="Company Name">How was your property experience? ( Upto 2000 words )</label>
                    <textarea class="form-control" rows="5" id="comment"></textarea>
                </div>
            </div>
            <button class="btn btn-primary pb-save">submit</button>
        </form>
        
            <div class="customer-reviews mt-4">
                <h3 class="mb-4">Customer Reviews</h3>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo1.png" alt="Hina Kumar">
                            <div class="ml-3">
                                <h3 class="name">Hina Kumar</h3>
                                <div class="stars">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo2.png" alt="Kishan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Kishan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo3.png" alt="Rohan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Rohan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo1.png" alt="Hina Kumar">
                            <div class="ml-3">
                                <h3 class="name">Hina Kumar</h3>
                                <div class="stars">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo2.png" alt="Kishan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Kishan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="review-card d-flex align-items-start">
                            <img src="../img/photo3.png" alt="Rohan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Rohan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    <!-- Content Row -->
    

</div>