export enum PropertyNames{
    ResidentialComplex="Residential Complex",
    aboutLocality="About Locality",
    banks ="Banks",
    colleges="Colleges",
    commercialHubs="Commercial Hubs",
    educationInst="Education Inst",
    hospitals="Hospitals",
    hotelResorts="Hotel Resorts",
    mallsMultiplex="Malls Multiplex",
    nearByLocality="Near by Locality",
    shoppingCenter="Shopping Center",
    touristSpots="Tourist Spots",
    transport="Transport"
    }