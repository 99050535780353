import { Component, OnInit, AfterViewInit  } from '@angular/core';
import Swiper from 'swiper';
@Component({
  selector: 'app-mb-choose-plans',
  templateUrl: './mb-choose-plans.component.html',
  styleUrls: ['./mb-choose-plans.component.css']
})
export class MbChoosePlansComponent implements OnInit,AfterViewInit {
  swiper: Swiper;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          const ariaLabel = `Go to slide ${index + 1}`;
          return `<span class="${className}" tabindex="0" role="button" aria-label="${ariaLabel}"></span>`;
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

}
