<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="my-4 p-5 bg-light rounded">
                    <h2 class="mb-3">Terms And Conditions</h2>
                    <p>These terms and conditions ("Terms") govern your use of Pillarblocks.com, a Unit Of Teamwork Innovations Pvt Ltds a real estate marketplace platform (the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Platform.
                    </p>
                    <p><b>1. Use of the Platform</b></p>
                    <p><b>1.1 Eligibility:</b> You must be at least 18 years old and have the legal capacity to enter
                        into
                        contracts to use the Platform. By using the Platform, you represent and warrant that you meet
                        these
                        eligibility requirements</p>
                    <p><b>1.2 License:</b> Subject to these Terms, nexus.pillarblocks.com grants you a limited, non-exclusive,
                        non-transferable, and revocable license to access and use the Platform for your personal or
                        commercial purposes.</p>
                    <p><b>1.3 Prohibited Activities:</b> You agree not to:Use the Platform for any illegal or
                        unauthorized purpose.
                        Post or transmit any content that is unlawful, defamatory, obscene, offensive, or otherwise
                        objectionable.
                        Interfere with or disrupt the operation of the Platform or servers or networks connected to the
                        Platform.
                        Attempt to gain unauthorized access to any part of the Platform or accounts of other users.
                        Use any robot, spider, scraper, or other automated means to access the Platform without
                        nexus.pillarblocks.com's express written permission.</p>
                    <p><b>2. User Accounts</b></p>
                    <p><b>2.1 Account Registration:</b> In order to access certain features of the Platform, you may be
                        required to
                        create an account. You agree to provide accurate, current, and complete information during the
                        registration process and to update such information to keep it accurate, current, and complete.
                    </p>
                    <p><b>2.2 Account Security:</b> You are responsible for maintaining the confidentiality of your
                        account
                        credentials and for all activities that occur under your account. You agree to notify
                        nexus.pillarblocks.com immediately of any unauthorized use of your account or any other breach of
                        security.</p>
                    <p><b>3. Listings and Transactions</b></p>
                    <p><b>3.1 Listing Content:</b> Users may create listings for properties available for sale, rent, or
                        lease on
                        the Platform. You are solely responsible for the accuracy, completeness, and legality of any
                        listings you create.</p>
                    <p><b>3.2 Transactions:</b>nexus.pillarblocks.com does not participate in or facilitate real estate
                        transactions
                        between users. Any transaction that occurs as a result of using the Platform is solely between
                        the
                        buyer, seller, landlord, and/or tenant. nexus.pillarblocks.com is not responsible for the performance
                        or
                        outcome of any such transactions.</p>
                    <p><b>4. Intellectual Property</b></p>
                    <p><b>4.1 Ownership:</b> The Platform and its entire contents, features, and functionality are owned
                        by
                        nexus.pillarblocks.com and are protected by copyright, trademark, and other intellectual property
                        laws.
                    </p>
                    <p><b>4.2 License:</b> Subject to these Terms, nexus.pillarblocks.com grants you a limited, non-exclusive,
                        non-transferable, and revocable license to access and use the Platform for your personal or
                        commercial purposes</p>

                    <p><b>5. Disclaimer of Warranties</b></p>
                    <p>THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER
                        EXPRESS OR
                        IMPLIED. nexus.pillarblocks.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                        LIMITED
                        TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT.
                    </p>
                    <p><b>6. Limitation of Liability</b></p>
                    <p>IN NO EVENT SHALL nexus.pillarblocks.com BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR
                        CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA, OR USE, INCURRED BY YOU OR
                        ANY
                        THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING FROM YOUR ACCESS TO, OR USE OF,
                        THE
                        PLATFORM.</p>
                    <p><b>7. Modifications to Terms</b></p>
                    <p>nexus.pillarblocks.com reserves the right to modify or replace these Terms at any time in its sole
                        discretion. Your continued use of the Platform after any such changes constitutes your
                        acceptance of
                        the new Terms.</p>
                    <p><b>8. Governing Law</b></p>
                    <p>These Terms shall be governed by and construed in accordance with the laws of Telangana -
                        Hyderabad,
                        India. without regard to its conflict of law provisions.</p>
                    <p><b>9. Contact Us</b></p>
                    <p>If you have any questions about these Terms, please contact us at info@pillarblocks.com
                    </p>
                  

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About -->
<!-- What We Provide -->

<!-- End What We Provide -->