import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
import { ForgotpasswordComponent } from './shared/components/forgotpassword/forgotpassword.component';
import { VendorDashboardComponent } from './components/vendor/vendor-dashboard/vendor-dashboard.component';
import { LoanComponent } from './components/vendor/loan/loan.component';
import { AuthGuard } from './common/guards/auth-guard';
import { MyPropertiesComponent } from './components/vendor/my-properties/my-properties.component';
import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { PgLocationComponent } from './components/PG-hostels/pg-location/pg-location.component';
import { AddPgComponent } from './components/PG-hostels/add-pg/add-pg.component';
import { AddAttributesCompnentComponent } from './components/PG-hostels/add-attributes-compnent/add-attributes-compnent.component';
import { ReviewsAndRatingsComponent } from './components/vendor/reviews-and-ratings/reviews-and-ratings.component';
import { MyProfileComponent } from './components/vendor/my-profile/my-profile.component';
import { CibilComponent } from './components/vendor/cibil/cibil.component';
import { ProjectFinanceComponent } from './components/vendor/project-finance/project-finance.component';
import { LoansComponent } from './components/vendor/loans/loans.component';
import { LeadsComponent } from './components/vendor/leads/leads.component';
import { SalesComponent } from './components/vendor/sales/sales.component';
import { CctvComponent } from './components/vendor/cctv/cctv.component';
import { InteriorDesignEnquiryComponent } from './components/vendor/interior-design-enquiry/interior-design-enquiry.component';
import { SolarEnquiryComponent } from './components/vendor/solar-enquiry/solar-enquiry.component';
import { AddRentComponent } from './components/Rent/add-rent/add-rent.component';
import { PropertyModulesComponent } from './components/vendor/property-modules/property-modules.component';
import { ChoosePlanComponent } from './components/associates/choose-plan/choose-plan.component';
import { SearchPropertiesComponent } from './components/associates/search-properties/search-properties.component';
import { AssociateMyProfileComponent } from './components/associates/associate-my-profile/associate-my-profile.component';
import { PropertyListingsComponent } from './components/property-listings/property-listings.component';
import { ProjectListingsComponent } from './components/project-listings/project-listings.component';
import { PgListingsComponent } from './components/pg-listings/pg-listings.component';
import { MyTeamComponent } from './components/associates/my-team/my-team.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { ProjectDescriptionComponent } from './project-description/project-description.component';
import { PgDescriptionComponent } from './pg-description/pg-description.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ShippingPolicyComponent } from './components/shipping-policy/shipping-policy.component';
import { CancellationRefundPolicyComponent } from './components/cancellation-refund-policy/cancellation-refund-policy.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FavoritePropertyListComponent } from './components/favorite-lists/favorite-property-list/favorite-property-list.component';
import { FavoriteListingsComponent } from './components/favorite-listings/favorite-listings.component';
import { LeadsForPropertiesComponent } from './components/leads-for-properties/leads-for-properties.component';
import { FavoriteProjectsComponent } from './components/favorite-projects/favorite-projects.component';
import { FavoritePgHostelsComponent } from './components/favorite-pg-hostels/favorite-pg-hostels.component';
import { LeadsForProjectsComponent } from './components/leads-for-projects/leads-for-projects.component';
import { LeadsForPghostelComponent } from './components/leads-for-pghostel/leads-for-pghostel.component';
import { LoginAssociateComponent } from './components/login-associate/login-associate.component';
import { PricingPolicyComponent } from './components/pricing-policy/pricing-policy.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MbProfileEditComponent } from './components/mobile-layout-components/mb-profile-edit/mb-profile-edit.component';
import { SupportHelpComponent } from './components/support-help/support-help.component';
import { LandSurveyComponent } from './components/vendor/land-survey/land-survey.component';
import { LandFencingComponent } from './components/vendor/land-fencing/land-fencing.component';
const routes: Routes = [

  {
    path: '', redirectTo: '/login', pathMatch: 'full' ,
  },
  {
    path: 'post-property/:propertyName',
    canActivate:[AuthGuard],
    component: PostPropertyComponent
  }, 
  {
    path: 'addPg/:propertyName',
    canActivate:[AuthGuard],
    component:AddPgComponent
  }, 
  {
    path: 'addRent/:propertyName',
    canActivate:[AuthGuard],
    component:AddRentComponent
  }, 
  {
    path: 'propertyModules',
    canActivate:[AuthGuard],
    component:PropertyModulesComponent
  }, 
  {
    path: 'addAttributes',
    canActivate:[AuthGuard],
    component:AddAttributesCompnentComponent
  }, 

  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    component: VendorDashboardComponent
  },
  {
    path:'myProperties',
    canActivate:[AuthGuard],
    component:MyPropertiesComponent
  },
  {
    path:'property-favorite-list/:location/:type',
    component:FavoritePropertyListComponent
  },

  {
    path: 'listings/:location/:type',
    canActivate:[AuthGuard],
    component: PropertyListingsComponent
  },
  {
    path: 'project/:location/:type',
    canActivate:[AuthGuard],
    component:ProjectListingsComponent
  },
  {
    path: 'pg/:location',
    canActivate:[AuthGuard],
    component:PgListingsComponent
  },
  // {
  //   path: 'property-details/:propertyName',
  //   component:PropertyDetailsComponent
  // },
  // {
  //   path: 'project-description/:propertyName',
  //   component:ProjectDescriptionComponent
  // },
  // {
  //   path: 'pg-description/:propertyName',
  //   component:PgDescriptionComponent
  // },
  {
    path: 'contactUs',
    canActivate:[AuthGuard],
    component:ContactUsComponent
  },
  {
    path: 'privacy-policy',
    canActivate:[AuthGuard],
    component:PrivacyPolicyComponent
  },
  {
    path: 'aboutUs',
    canActivate:[AuthGuard],
    component:AboutUsComponent
  },
  {
    path: 'pricing-policy',
    canActivate:[AuthGuard],
    component:PricingPolicyComponent
  },
  {
    path: 'terms-conditions',
    canActivate:[AuthGuard],
    component:TermsAndConditionsComponent
  },
  {
    path: 'shipping-policy',
    canActivate:[AuthGuard],
    component:ShippingPolicyComponent
  }, {
    path: 'Cancellation-Refundpolicy',
    canActivate:[AuthGuard],
    component:CancellationRefundPolicyComponent
  },
  {
    path:'myProjects',
    canActivate:[AuthGuard],
    component:MyProjectsComponent
  },
  {
    path:'reviewsAndRatings',
    canActivate:[AuthGuard],
    component:ReviewsAndRatingsComponent
  },
  {
    path:'myProfile',
    canActivate:[AuthGuard],
    component:MyProfileComponent
  },
  {
    path:'choosePlan',
    canActivate:[AuthGuard],
    component:ChoosePlanComponent
  },
  {
    path:'searchProp',
    canActivate:[AuthGuard],
    component:SearchPropertiesComponent
  },
  {
    path:'associateMyProfile',
    canActivate:[AuthGuard],
    component:AssociateMyProfileComponent
  },
  {
    path:'profile-edit',
    component:MbProfileEditComponent,


  },
  {
    path:'login',
    component:LoginAssociateComponent
  },
  {
    path:'change-password',
    canActivate:[AuthGuard],
    component:ChangePasswordComponent
  },
  {
    path:'favorite-listings',
    component:FavoriteListingsComponent
  },
  {
    path:'favorite-projects',
    component:FavoriteProjectsComponent
  },
  {
    path:'favorite-hostels',
    component:FavoritePgHostelsComponent
  },
  {
    path:'leads-properties',
    component:LeadsForPropertiesComponent
  },
  {
    path:'leads-projects',
    component:LeadsForProjectsComponent
  },{
    path:'leads-pgHostels',
    component:LeadsForPghostelComponent
  },
  {
    path:'projectFinance',
    canActivate:[AuthGuard],
    component:ProjectFinanceComponent
  },
  {
    path:'leads',
    canActivate:[AuthGuard],
    component:LeadsComponent
  },
  {
    path:'loans',
    canActivate:[AuthGuard],
    component:LoansComponent
  },
  {
    path:'Support&Help',
    canActivate:[AuthGuard],
    component:SupportHelpComponent
  },
  {
    path:'sales',
    canActivate:[AuthGuard],
    component:SalesComponent
  },
  {
    path:'cctvEnquiry',
    canActivate:[AuthGuard],
    component:CctvComponent
  },
  {
    path:'land-survey',
    canActivate:[AuthGuard],
    component:LandSurveyComponent
  },
  {
    path:'land-fencing',
    canActivate:[AuthGuard],
    component:LandFencingComponent
  },
  {
    path:'interiorDesignEnquiry',
    canActivate:[AuthGuard],
    component:InteriorDesignEnquiryComponent
  },
  {
    path:'solarEnquiry',
    canActivate:[AuthGuard],
    component:SolarEnquiryComponent
  },
  {
    path:'cibil',
    canActivate:[AuthGuard],
    component:CibilComponent
  },
  {
    path:'myTeam',
    canActivate:[AuthGuard],
    component:MyTeamComponent
  },
  {
    path:'addCompany/:companyName',
    canActivate:[AuthGuard],
    component: AddCompanyComponent
  },
  {
    path:'addProject/:propertyId',
    canActivate:[AuthGuard],
    component: AddProjectComponent
  },
  {
    path:'companyInfo',
    canActivate:[AuthGuard],
    component: CompanyinfoComponent
  },
  {
    path:'myLead',
    canActivate:[AuthGuard],
    component: MyLeadsComponent
  },
  {
    path:'myViewLead',
    canActivate:[AuthGuard],
    component:MyviewLeadsComponent
  },
  {
    path:'myCompany',
    canActivate:[AuthGuard],
    component:MyCompanyComponent
  },
  {
    path:'myCompany',
    canActivate:[AuthGuard],
    component:PostPropertyComponent
  },

  {
    path:'companyProfile',
    canActivate:[AuthGuard],
    component: CompanyProfileComponent
  },
  {
    path: 'loan',
    canActivate:[AuthGuard],
    component:LoanComponent
  },
  {
    path:'pgLocation',
    canActivate:[AuthGuard],
    component:PgLocationComponent

  },

  {
    path:'forgotpassword',
    // canActivate:[AuthGuard],
    component:ForgotpasswordComponent
  },
  { path: '**', redirectTo: '/login',pathMatch: 'full' } 
 
  // {
  //   path: '**',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
