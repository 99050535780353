<main class="pb-search" *ngIf="property">
    <div class="container">
        <div class="row">
            <section>
                <div class="prop_heading">
                    <h1 class="text-primary">{{property.projectName}}</h1>
                    <!-- <nav style="--bs-breadcrumb-divider: '>';">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="#">Home</a></li>
                          <li class="breadcrumb-item"><a href="#">Property For Sale </a></li>
                          <li class="breadcrumb-item"><a href="#">Flats for sale in Hyderabad </a></li>
                          <li class="breadcrumb-item"><a href="#">Flats for sale in Miyapur </a></li>
                          <li class="breadcrumb-item active"><a href="#"> 3BHK Flat</a></li>
                        </ol>
                      </nav> -->
                </div>
            </section>
            <div class="d-flex justify-content-between prop_subcat">
                <div class="prop_name">
                    <!-- <h2>GUPTA HEIGHTS</h2>
                    <button class="btn btn-primary bluebtn">miyapur, hyderabad</button>
                    <p>By <a href="#"> Aparna Homes Pvt.Ltd</a></p> -->
                </div>
                <div class="prop_price">
                    <h2 class="text-danger">{{property.priceMin | rsbcurrency : property.currency ? property.currency :
                        'INR' }} - {{property.priceMax | rsbcurrency : property.currency ? property.currency : 'INR' }}
                        <!-- <span class="emi">(EMI - ₹58k)</span> -->
                    </h2>
                    <a href="#" *ngIf="property.emi">Get Loan offers from 25+ banks</a>
                </div>
            </div>
            <div id="gallery" class="photos-grid-container gallery">
                <div class="main-photo img-box" *ngIf="property.images && property.images.length">
                    <a href="{{property.images[0]}}" class="glightbox" data-glightbox="type: image">
                        <img src="{{property.images[0]}}" alt="image" /></a>
                </div>
                <div>
                    <div class="sub">
                        <div class="img-box" *ngIf="property.images && property.images.length >1">
                            <a href="{{property.images[1]}}" class="glightbox" data-glightbox="type: image">
                                <img src="{{property.images[1]}}" alt="image" />
                            </a>
                        </div>
                        <div id="multi-link" class="img-box" *ngIf="property.images && property.images.length >2 ">
                            <a href="{{property.images[2]}}" class="glightbox" data-glightbox="type: image">
                                <img src="{{property.images[2]}}" alt="image" />
                                <div class="transparent-box">
                                    <div class="caption">
                                        +3 more
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="more-img" class="extra-images-container hide-element">
                    <a href="assets/img/image1.png" alt="image" class="glightbox" data-glightbox="type: image">
                        <img src="assets/img/image1.png" alt="image" />
                    </a>
                    <a href="assets/img/image2.png" alt="image" class="glightbox" data-glightbox="type: image">
                        <img src="assets/img/image2.png" alt="image" />
                    </a>
                </div>
            </div>
            <div class="prop_btns">
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <button (click)="contact(property)" class="btn btn-freesite">Request Site Visit</button>
                    </li>
                    <li class="list-inline-item">
                        <button class="btn btn-call" (click)="contact(property)"><i class="bi bi-whatsapp" (click)="contact(property)"></i> Request
                            Video call</button>
                    </li>
                    <!-- <li class="list-inline-item">
                        <button class="btn btn-call">Schedule Site Visit</button>
                    </li> -->
                    <!-- <li class="list-inline-item">
                        <button class="btn btn-freesite" (click)="contactOwner()" *ngIf="addFavoriteButton">Add To Favorites</button>
                    </li> -->

                    <li class="list-inline-item">
                        <button class="btn btn-freesite" (click)="addFavorites()" *ngIf="!addFavoriteButton">Added To
                            Favorites</button>
                    </li>

                    <li class="list-inline-item">
                        <button class="btn btn-freesite" (click)="contact(property)">Contact Builder</button>
                    </li>
                </ul>
            </div>
            <div class="detail_list">
                <ul class="list-group list-group-horizontal justify-content-center">
                    <li class="list-group-item" *ngFor="let textarr of textArray">
                        <h3>{{textarr.attributePropertyName}}</h3>
                        <p>{{textarr.attributeName}}</p>
                    </li>
                    <!-- <li class="list-group-item">
                        <h3>Sept 2025</h3>
                        <p>Possession Starts</p>
                    </li>
                    <li class="list-group-item">
                        <h3>₹7.4K/sq.ft</h3>
                        <p>Avg. Price</p>
                    </li>
                    <li class="list-group-item">
                        <h3>1590 sq.ft - 2060 sq.ft</h3>
                        <p>Sizes (Built up Area)</p>
                    </li> -->
                </ul>
            </div>
            <div class="col-lg-12">
                <div class="menu-container" [ngClass]="{'scrolled': tabScrolled}" id="overview">
                    <button class="prev-btn"><i class="ri-arrow-left-s-line"></i></button>
                    <div class="menu">
                        <ul class="menu-list" role="tablist">
                            <li *ngFor="let tab of rentTabData">
                                <a [ngClass]="{'active':tab.active}"
                                    (click)="scrollToSection(tab.tabSection,tab.tabName)"
                                    *ngIf="isSectionVisible(tab.sectionApiName)">{{tab.tabName}}</a>
                            </li>
                            <!-- <li>
                                <a (click)="scrollToSection('project')">Around This Project</a>
                            </li>
                            <li>
                                <a href="#moredetails" >More Details</a>
                            </li>
                            <li>
                                <a href="#floorplan">Floor Plan</a>
                            </li>
                            <li>
                                <a href="#amenities">Amenities</a>
                            </li>
                            <li>
                                <a href="#enquire">Enquire</a>
                            </li>
                            <li>
                                <a href="#agentoverview">Agent Overview</a>
                            </li>
                            <li>
                                <a href="#landmarks" >Landmarks</a>
                            </li>
                            <li>
                                <a href="#reviews">Reviews</a>
                            </li>
                            <li>
                                <a href="#Legal">Legal and Infra Status</a>
                            </li>
                            <li>
                                <a href="#faq">FAQs</a>
                            </li> -->
                        </ul>
                    </div>
                    <button class="next-btn"><i class="ri-arrow-right-s-line"></i></button>
                </div>
            </div>
            <div class="col-lg-10 my-5">
                <div class="property_content_wrapper">
                    <div class="prop_overview bg-white p-5 mb-4 rounded-2" id="project" #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>About This Property</h2>
                            <a href="javascript:void(0)" class="text-decoration-underline">View more on Maps <i
                                    class="bi bi-chevron-right"></i></a>
                        </div>
                        <div class="rating">
                            <ul class="list-inline">
                                <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                <li class="list-inline-item"><span>1.2K reviews</span></li>
                                <li class="list-inline-item">
                                    <p class="ranking">
                                        Rank 44 /1200 Localities
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p>{{property.projectDescription}}</p>
                        </div>
                        <div *ngIf="property.projectHighlights && property.projectHighlights.length">
                            <div *ngFor="let highlights of property.projectHighlights">
                                {{highlights.swatchName}}
                            </div>
                        </div>

                        <div id="about_slider" class="owl-carousel about_slider_wrapper owl-loaded owl-drag"
                            *ngIf="property.locationHighlights && getLocationLength(property.locationHighlights) > 0">
                            <ng-container *ngFor="let item of property.locationHighlights | keyvalue">
                                <ng-container *ngIf="item.value && item.value !== null && item.key !=='aboutLocality'">
                                    <div class="item">
                                        <div class="about_slider_content">
                                            <p>
                                                <i *ngIf="getIcon(item.key)" [class]="getIcon(item.key)"></i>
                                            </p>
                                            <h3>{{propertyNames[item.key]}}</h3>
                                            <ul class="list-inline" *ngIf="item.value">
                                                <li *ngFor="let value of item.value.split(',')">
                                                    <i class="ri-check-line"></i> <span> {{value}} </span>
                                                </li>
                                                <!-- <li>
                                        <i class="ri-check-line"></i> <span> Edge Global School </span>
                                    </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <!-- <div class="item">
                            <div class="about_slider_content">
                                <p><i class="ri-store-3-line"></i></p>
                                <h3>Shopping Centers</h3>
                                <ul class="list-inline">
                                    <li>
                                        <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                    </li>
                                    <li>
                                        <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="about_slider_content">
                                <p><i class="ri-hospital-line"></i></p>
                                <h3>Hospital</h3>
                                <ul class="list-inline">
                                    <li>
                                        <i class="ri-check-line"></i> <span> KIMS Hospital </span>
                                    </li>
                                    <li>
                                        <i class="ri-check-line"></i> <span> Dr Reddy’s Clinic </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                        <div class="about_slider_content">
                            <p> <i class="ri-school-line"></i> </p>
                            <h3>Educational Institutes</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Clair International School </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Edge Global School </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                            <div class="about_slider_content">
                                <p> <i class="ri-school-line"></i> </p>
                                <h3>Educational Institutes</h3>
                                <ul class="list-inline">
                                    <li>
                                        <i class="ri-check-line"></i> <span> Clair International School </span>
                                    </li>
                                    <li>
                                        <i class="ri-check-line"></i> <span> Edge Global School </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                                <div class="about_slider_content">
                                    <p><i class="ri-store-3-line"></i></p>
                                    <h3>Shopping Centers</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                        </li>
                                        <li>
                                            <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                        <div class="item">
                                <div class="about_slider_content">
                                    <p><i class="ri-hospital-line"></i></p>
                                    <h3>Hospital</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <i class="ri-check-line"></i> <span> KIMS Hospital </span>
                                        </li>
                                        <li>
                                            <i class="ri-check-line"></i> <span> Dr Reddy’s Clinic </span>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                        <div class="item">
                        <div class="about_slider_content">
                            <p> <i class="ri-school-line"></i> </p>
                            <h3>Educational Institutes</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Clair International School </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Edge Global School </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                                <div class="about_slider_content">
                                    <p><i class="ri-store-3-line"></i></p>
                                    <h3>Shopping Centers</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                        </li>
                                        <li>
                                            <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                        </li>
                                    </ul>
                                </div>
                        </div> -->
                        </div>
                    </div> <!--About section-->
                    <div class="more_details bg-white p-5 mb-4 rounded-2" id="moredetails" #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>More Details</h2>
                            <!-- <a href="javascript:void(0)" class="text-decoration-underline">Download Brochure <i class="bi bi-chevron-right"></i></a> -->
                        </div>
                        <div class="more_listitems">
                            <div class="more_list">
                                <ul class="list-group">
                                    <li class="list-group-item" *ngFor="let attribute of property.attributes">
                                        <div class="left">
                                            <p>{{attribute.attributeName}}</p>
                                            <p class="right">{{attribute.attributePropertyName}}</p>
                                        </div>
                                    </li>
                                    <!-- <li class="list-group-item">
                                    <div class="left">
                                        <p>Project Size</p>
                                        <p class="right">3 Buildings</p>
                                    </div>
                                    
                                </li>
                                <li class="list-group-item">
                                    <div class="left">
                                        <p>Launch Date</p>
                                        <p class="right">Sept 2022</p>
                                    </div>
                                    
                                </li>
                                <li class="list-group-item">
                                    <div class="left">
                                        <p>RERA ID</p>
                                        <p class="right text-decoration-underline">P02400006604</p>
                                    </div>
                                    
                                </li>
                                <li class="list-group-item">
                                    <div class="left">
                                        <p>Loan Offered</p>
                                        <p class="right">
                                            Estimated EMI: <span>₹57694</span>
                                            <a href="#" class="text-decoration-underline d-block">Get Loan offers from 25+ banks</a>
                                        </p>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="left">
                                        <p>Posession Starts</p>
                                        <p class="right">Sept 2025 </p>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex align-items-start">
                                    <button class="btn btn-primary rounded-pill me-3">Ask For Details</button>
                                    <button class="btn btn-outline-primary rounded-pill me-3"><i class="ri-share-fill"></i> Share</button>
                                    <button class="btn btn-outline-primary rounded-pill"><i class="ri-heart-line"></i> Save</button>
                                </li> -->
                                </ul>
                            </div>
                        </div>
                    </div> <!--more section-->
                    <ng-container *ngIf="property.floorPlanImages && property.floorPlanImages.length">
                        <div #section>
                            <div class="heading d-flex justify-content-between align-items-center">
                                <h2>{{property.projectName}} Floor Plan</h2>
                            </div>
                            <div class="owl-carousel Popluarslider owl-loaded owl-drag" id="floorplan">
                                <ng-container *ngFor="let propBhk of property.floorPlanImages; let i =index">
                                    <div class="item">
                                        <div class="floor_area d-flex justify-content-between">
                                            <div class="left">
                                                <ng-container>
                                                    <span>{{propBhk?.bhk?propBhk.bhk+'BHK'+' ':''}}</span>
                                                </ng-container>
                                                <!-- <h3 style="font-size: 15px;">Builtup Area : <span> 1688 sqft at ₹7.4K per sqft</span></h3> -->
                                            </div>
          
                                            <div class="prop_price">
                                                <h2 class="text-danger">{{propBhk.amount| rsbcurrency : property.currency ? property.currency :
                                                    'INR' }}
                                                    <!-- <span class="emi">(EMI - ₹58k)</span> -->
                                                </h2>
                                                <a href="#" *ngIf="property.emi">Get Loan offers from 25+ banks</a>
                                            </div>
                                            
                                        </div>
                                        <div class="floor_img">
                                            <div>
                                                <div class="enquire_wrapper d-flex">
                                                    <div>
                                                        <div class="img">
                                                            <!-- <img [src]="floorimg.image" alt="Floor Plan"> -->
                                                            <img src="{{propBhk.image}}" alt="">


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <img [src]="property.floorPlanImages[0].image" alt="Floor Plan"> -->
                                            <!-- <img src="{{property.floorPlanImages[0].image}}" alt=""> -->
                                            <ul class="list-inline my-3">
                                                <li class="list-inline-item">
                                                    <button class="btn btn-primary rounded-pill btn btn-freesite"  (click)="contact(property)">Contact
                                                        Sellers</button>
                                                </li>
                                               
                                                <!-- <li class="list-inline-item">
                                                    <button class="btn btn-outline-primary rounded-pill"><i
                                                            class="ri-share-fill"></i>
                                                        Share</button>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div> <!--floor section-->
                    </ng-container>
                    <!-- <div class="property_media bg-white p-5 mb-4 rounded-2"
                        *ngIf="property.floorPlanImages && property.floorPlanImages.length" #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>Gupta Heights Floor Plan</h2>
                        </div>
                        <div class="media_wrapper">
                            <h4><span class="verified"><i class="ri-verified-badge-fill"></i>VERIFIED</span> Photos and
                                Videos</h4>
                            <div class="photos-grid-container gallery">
                                <div>
                                    <div class="sub">
                                        <div class="img-box">
                                            <a href="assets/img/img1.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img1.png" alt="image" />
                                            </a>
                                        </div>
                                        <div class="img-box">
                                            <a href="assets/img/img1.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img1.png" alt="image" />
                                            </a>
                                        </div>
                                        <div id="multi-link" class="img-box">
                                            <a href="assets/img/img2.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img2.png" alt="image" />
                                                <div class="transparent-box">
                                                    <div class="caption">
                                                        +3 more
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="more-img" class="extra-images-container hide-element">
                                    <a href="assets/img/image1.png" alt="image" class="glightbox"
                                        data-glightbox="type: image">
                                        <img src="assets/img/image1.png" alt="image" />
                                    </a>
                                    <a href="assets/img/image2.png" alt="image" class="glightbox"
                                        data-glightbox="type: image">
                                        <img src="assets/img/image2.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                            <h4>Project Tour & Photos</h4>
                            <div class="photos-grid-container gallery">
                                <div>
                                    <div class="sub">
                                        <div class="img-box">
                                            <a href="assets/img/img1.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img1.png" alt="image" />
                                            </a>
                                        </div>
                                        <div class="img-box">
                                            <a href="assets/img/img1.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img1.png" alt="image" />
                                            </a>
                                        </div>
                                        <div id="multi-link" class="img-box">
                                            <a href="assets/img/img2.png" class="glightbox"
                                                data-glightbox="type: image">
                                                <img src="assets/img/img2.png" alt="image" />
                                                <div class="transparent-box">
                                                    <div class="caption">
                                                        +3 more
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="more-img" class="extra-images-container hide-element">
                                    <a href="assets/img/image1.png" alt="image" class="glightbox"
                                        data-glightbox="type: image">
                                        <img src="assets/img/image1.png" alt="image" />
                                    </a>
                                    <a href="assets/img/image2.png" alt="image" class="glightbox"
                                        data-glightbox="type: image">
                                        <img src="assets/img/image2.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> <!--media section-->
                    <!-- <div class="amenities bg-white p-5 mb-4 rounded-2" id="amenities" #section>
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>Amenities</h2>
                        </div>
                        <div class="amenities_list">
                            <ul class="list-inline">
                                <li class="list-inline-item" title="{{addattr.swatchNamenew}}"
                                    *ngFor="let addattr of additionalAttrProps | slice:0:6">
                                    <p><i class="ri-fridge-fill"></i> <span>{{addattr.attributeName}}</span></p>
                                </li>
                                     <li class="list-inline-item">
                            <p *ngIf="additionalAttrProps.length>6" (click)="toggleContentAmenities()" class="viewmore">View all Amenities <i class="bi bi-chevron-down"></i></p>
                            <div *ngIf="additionalAttrProps.length>6 && isContentVisible" class="more_amenities">
                                <li class="list-inline-item" title="{{addattr.swatchNamenew}}" *ngFor="let addattr of additionalAttrProps | slice:6:additionalAttrProps.length">
                                    <p><i class="ri-fridge-fill"></i> <span>{{addattr.attributeName}}</span></p>
                                </li>
                                </div>
                            </ul>
                        </div>
                    </div>  -->
                    <div class="amenities bg-white p-5 mb-4 rounded-2" id="{{add.propertyTabId}}" #section *ngFor="let add of additionalAttrProps">
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>{{add.propertyTabName}}</h2>
                        </div>
                        <div class="amenities_list food">
                            <ul class="list-inline">
                              <li class="list-inline-item" *ngFor="let prop of add.attributes">
                                <p class="text-center"><i *ngIf="getAttrIcon(prop.attributeName)"  [class]="getAttrIcon(prop.attributeName)"></i></p>
                                <p class="text-center">{{prop.attributeLabel}}</p>
                                  <div>
                                    <span *ngFor="let d of prop.swatchNameList"><i class="ri-checkbox-fill"></i> {{d}}</span>
                                   </div>
                                  <!-- <div><i class="ri-checkbox-fill"></i> {{}}</div> -->
                              </li>
                              <!-- <li class="list-inline-item">
                                <p class="text-center"><i class="ri-fridge-fill"></i></p>
                                <p class="text-center">Meals Provided</p>
                                <div><i class="ri-checkbox-fill"></i> Veg</div>
                                <div><i class="ri-checkbox-fill"></i> Non Veg</div>
                            </li>
                            <li class="list-inline-item">
                              <p class="text-center"><i class="ri-fridge-fill"></i></p>
                                <p class="text-center">Fridge</p>
                          </li> -->
                         
                          </ul>
                        </div>
                        </div>
                    <!--amenities section-->
                    <div class="specifications bg-white p-5 mb-4 rounded-2" id="specifications" #section
                        *ngIf="property.specifications && getLocationLength(property.specifications) > 0">
                        <div class="heading d-flex justify-content-between align-items-center">
                            <h2>Specifications</h2>
                            <!-- <a href="javascript:void(0)" class="text-decoration-underline">Download Brochure <i class="bi bi-chevron-right"></i></a> -->
                        </div>
                        <div class="specification_wrapper">
                            <ul class="list-group">
                                <li class="list-group-item"
                                    *ngFor="let specification of property.specifications | keyvalue ">
                                    <div class="left">
                                        <ng-container *ngIf="specification.value !=''">
                                            <p>{{specPropertyNames[specification.key]}}</p>
                                            <p class="right">{{specification.value}}</p>
                                        </ng-container>
                                    </div>
                                </li>
                                <!-- <li class="list-group-item">
                                <div class="left">
                                    <p>Exterior</p>
                                    <p class="right">Texture Paint</p>
                                </div>
                                
                            </li> -->
                        </ul>
                    </div>
                </div> <!--specifications section-->
                <div *ngIf="rentTabData[6].IsExists" class="enquire bg-white p-5 mb-4 rounded-2" id="enquire" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Enquire</h2>
                    </div>
                    <div class="enquire_div d-flex">
                        <div id="enquire_carousel" class="owl-carousel Popluarslider owl-loaded owl-drag">
                            <div class="enquire_wrapper d-flex" *ngFor="let item of enquiresList">
                                <div class="img">
                                    <img src="{{item.images[0]}}" alt="">
                                </div>
                                <div class="enquire_details">
                                    <h4>{{item.projectName}}</h4>
                                    <!-- <p>Developer</p> -->
                                    <h5>{{item.priceMin}} - {{item.priceMax}}</h5>
                                </div>
                                <button (click)="openProjectProperty(item)"
                                    class="btn btn-primary rounded-pill">Contact</button>
                            </div>
                        </div>
                        <!-- <div class="enquire_wrapper d-flex">
                                <div class="img">
                                    <img src="assets/img/company_image.PNG" alt="">
                                </div>
                                <div class="enquire_details">
                                    <h4>Aparna Homes</h4>
                                    <p>Developer</p>
                                    <h5>₹1.27 Cr - ₹1.52 Cr</h5>
                                </div>
                                <button class="btn btn-primary rounded-pill">Contact</button>
                            </div>
                            <div class="enquire_wrapper d-flex">
                                <div class="img">
                                    <img src="assets/img/company1.png" alt="">
                                </div>
                                <div class="enquire_details">
                                    <h4>Kolaey Spaces</h4>
                                    <p>Seller</p>
                                    <h5>₹1.27 Cr - ₹1.52 Cr</h5>
                                </div>
                                <button class="btn btn-primary rounded-pill">Contact</button>
                            </div> -->
                    </div>


                </div> <!--enquire section-->
                <div class="brochure bg-white p-5 mb-4 rounded-2" *ngIf="property.brochure" id="brochure" #section>
                    <div class="heading d-flex align-items-center">
                        <h2>Gupta Heights : </h2>
                        <span> Brochure</span>
                    </div>
                    <div class="brochure_wrapper">
                        <div class="row">
                            <div class="col-lg-4">
                                <img src="assets/img/image1.png" alt="">
                            </div>
                            <div class="col-lg-4">
                                <img src="assets/img/image2.png" alt="">
                            </div>
                            <div class="col-lg-4">
                                <img src="assets/img/image3.png" alt="">
                            </div>
                        </div>
                        <button class="btn btn-primary">View Brochure</button>
                        <div class="overlay"></div>
                    </div>
                    <button class="btn btn-outline-primary rounded-pill">Download Brochure</button>

                </div> <!--brochure section-->
                <div class="Bank_offers bg-white p-5 mb-4 rounded-2" id="loans" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Bank Offers</h2>
                    </div>
                    <div class="bank_wrapper">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="bank_details">
                                    <img src="assets/img/bank1.png" alt="">
                                    <h3>Punjab National Bank</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 6.55% Floating</p>
                                        </li>
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 2 Months Free EMI</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="bank_details">
                                    <img src="assets/img/bank2.png" alt="">
                                    <h3>Kotak Bank</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 7.15% Floating</p>
                                        </li>
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 2 Months Free EMI</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="bank_details">
                                    <img src="assets/img/bank3.png" alt="">
                                    <h3>Andhra Bank</h3>
                                    <ul class="list-inline">
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 6.98% Floating</p>
                                        </li>
                                        <li>
                                            <p><i class="ri-checkbox-fill"></i> 2 Months Free EMI</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> <!--Banks section-->
                <div class="help_tool bg-white p-5 mb-4 rounded-2" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Helpful Tools</h2>
                    </div>
                    <div class="help_wrapper">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="help_details">
                                    <img src="assets/img/EMI_Calculator_png.png" style="text-align: center;padding-left: 75px;" alt="">
                                    <i class=""></i>
                                    <div class="help_text">
                                        <h3>EMI Calculator</h3>
                                        <p>This EMI Calculator can give you quick info plan monthly payments of a home loan, based on the home's sale price, the term of the loan desired and ROI.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="help_details">
                                    <img src="assets/img/Affordability-Calculator-png.png" style="text-align: center;padding-left: 75px;" alt="">
                                    <div class="help_text">
                                        <h3>Affordability Calculator</h3>
                                        <p>Get the peace of mind by knowing all the details with the Home Loan Affordability Calculator.
                                            Quickly assess your financial capacity, determine loan eligibility and Monthly Repayment.</p>                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="help_details">
                                    <img src="assets/img/Eligibility-Calculator-png.png" style="text-align: center;padding-left: 75px;" alt="">
                                    <div class="help_text">
                                        <h3>Eligibility Calculator</h3>
                                        <p>Calculate Home Loan Eligibility. Use this calculator to know the required loan amount plan and decide the amount of loan that you can borrow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> <!--help tool section-->
                <div class="explore_map bg-white p-5 mb-4 rounded-2" id="locationHighlights" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Explore Neighbourhood - Map View</h2>
                        <a href="javascript:void(0)" class="text-decoration-underline"> Explore Locality <i
                                class="bi bi-chevron-right"></i></a>
                    </div>
                    <div class="map_rating">
                        <ul class="list-inline">
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>1.2K reviews</span></li>
                            <li class="list-inline-item">
                                <p class="ranking">
                                    Rank 44 /1200 Localities
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="map_embed">
                        <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [restriction]="mapRestriction">
                            <agm-marker [latitude]="lat" [longitude]="long" [markerDraggable]="false"></agm-marker>
                        </agm-map>
                    </div>
                    <!-- <div class="map-aminites">
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Environment</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>3.7</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Commuting</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>3.7</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                        <div class="map_box">
                        <ul class="list-inline">
                            <li class="list-inline-item"><h3>Places of interest</h3></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                            <li class="list-inline-item"><span>4.8</span></li>
                        </ul>
                        <div class="map_text">
                            <p>Roads - 3.9</p>
                            <p>Safety - 3.6</p>
                            <p>Neighbourhood - 3.6</p>
                            <p>Cleanliness - 4.0</p>
                        </div>
                        </div>
                    </div> -->
                        <div id="map_slider" class="owl-carousel about_slider_wrapper owl-loaded owl-drag">
                            <ng-container *ngFor="let item of property.locationHighlights | keyvalue">
                                <ng-container *ngIf="item.value && item.value !== null && item.key !=='aboutLocality'">
                                    <div class="item prop_overview">
                                        <div class="about_slider_content">
                                            <p>
                                                <i *ngIf="getIcon(item.key)" [class]="getIcon(item.key)"></i>
                                              </p> 
                                            <h3>{{propertyNames[item.key]}}</h3>
                                            <ul class="list-inline" *ngIf="item.value">
                                                <li *ngFor="let value of item.value.split(',')">
                                                    <i class="ri-check-line"></i> <span> {{value}} </span>
                                                </li>
                                                <!-- <li>
                                        <i class="ri-check-line"></i> <span> Edge Global School </span>
                                    </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <!-- <div class="item">
                        <div class="map_slider_content">
                            <p><i class="ri-store-3-line"></i></p>
                            <h3>Shopping Centers</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p><i class="ri-hospital-line"></i></p>
                            <h3>Hospital</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> KIMS Hospital </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Dr Reddy’s Clinic </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p> <i class="ri-school-line"></i> </p>
                            <h3>Educational Institutes</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Clair International School </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Edge Global School </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p> <i class="ri-school-line"></i> </p>
                            <h3>Educational Institutes</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Clair International School </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Edge Global School </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p><i class="ri-store-3-line"></i></p>
                            <h3>Shopping Centers</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p><i class="ri-hospital-line"></i></p>
                            <h3>Hospital</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> KIMS Hospital </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Dr Reddy’s Clinic </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p> <i class="ri-school-line"></i> </p>
                            <h3>Educational Institutes</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Clair International School </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Edge Global School </span>
                                </li>
                            </ul>
                        </div>
                        </div>
                        <div class="item">
                        <div class="map_slider_content">
                            <p><i class="ri-store-3-line"></i></p>
                            <h3>Shopping Centers</h3>
                            <ul class="list-inline">
                                <li>
                                    <i class="ri-check-line"></i> <span> Inorbit Mall </span>
                                </li>
                                <li>
                                    <i class="ri-check-line"></i> <span> Ratnadeep Supermart </span>
                                </li>
                            </ul>
                        </div>
                        </div> -->
                    </div>
                </div> <!--Map section-->

                <div class="rating_reviews bg-white p-5 mb-4 rounded-2" id="reviews" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Rating and Reviews <small>(16 reviews)</small></h2>
                    </div>
                    <div class="ratingreview_details">
                        <div id="rateRevie_Slider" class="owl-carousel Popluarslider owl-loaded owl-drag">
                            <div class="ratingreview_card green_bg" *ngFor="let professional of professionalTerminal">
                                <p>{{professional.testModule}}</p>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                    <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                    <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                    <li class="list-inline-item"><i class="ri-star-fill"></i></li>
                                    <li class="list-inline-item">3.8</li>
                                </ul>
                                <div class="testmonial_profile">
                                    <div class="user_img">
                                        <img src="{{professional.image}}" alt="">
                                    </div>
                                    <div class="username">
                                        <h3>{{professional.name}}</h3>
                                        <p>{{professional.profession}}</p>
                                        <p>{{professional.location}}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rating_cons">
                        <div class="good_rating">
                            <h3>What's Good Here:</h3>
                            <ul>
                                <li>Well developed locality </li>
                                <li>Its located in one of hubs of the city </li>
                                <li>Well developed locality </li>
                                <li>Good & Educated Neighborhood</li>
                                <li>Professional locality</li>
                                <li>Cultural activities</li>
                                <li>The lush green lanes</li>
                                <li>Security and Safety</li>
                                <li>Opulent Living</li>
                            </ul>
                        </div>
                        <div class="vline"></div>
                        <div class="good_rating">
                            <h3 class="ylcolor">What's Can Be Better:</h3>
                            <ul>
                                <li> Civic issues
                                <li> Minimal Shops </li>
                                <li>Frequent plaguing the area</li>
                                <li>One way roads</li>
                                <li> Not enough Streetlights</li>
                                <li> Development works </li>
                                <li> Urban Crowd </li>





                            </ul>
                        </div>
                    </div>
                    <div class="know_more">
                        <a href="#">Know More About Miyapur <i class="bi bi-chevron-right"></i></a>
                    </div>
                </div> <!--Rating and review section-->
                <div class="locality_guide bg-white p-5 mb-4 rounded-2" id="landmarks" #section>
                    <div class="heading d-flex justify-content-between align-items-center">
                        <h2>Locality Guide</h2>
                        <a href="javascript:void(0)" class="text-decoration-none"> <i class="bi bi-share-fill"></i>
                            Share</a>
                    </div>
                    <div class="guide_wrapper">
                        <div class="photos-grid-container gallery">
                            <div class="main-photo img-box">
                                <a href="assets/img/prop_img_big.png" class="glightbox" data-glightbox="type: image">
                                    <img src="assets/img/prop_img_big.png" alt="image" /></a>
                            </div>
                            <div>
                                <div class="sub">
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img1.png" alt="image" />
                                        </a>
                                    </div>
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img2.png" alt="image" />
                                        </a>
                                    </div>
                                    <div class="img-box">
                                        <a href="assets/img/img1.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img3.png" alt="image" />
                                        </a>
                                    </div>
                                    <div id="multi-link" class="img-box">
                                        <a href="assets/img/img2.png" class="glightbox" data-glightbox="type: image">
                                            <img src="assets/img/img2.png" alt="image" />
                                            <div class="transparent-box">
                                                <div class="caption">
                                                    +3 more
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id="more-img" class="extra-images-container hide-element">
                                <a href="assets/img/image1.png" alt="image" class="glightbox"
                                    data-glightbox="type: image">
                                    <img src="assets/img/image1.png" alt="image" />
                                </a>
                                <a href="assets/img/image2.png" alt="image" class="glightbox"
                                    data-glightbox="type: image">
                                    <img src="assets/img/image2.png" alt="image" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="locality_details">
                        <p style="font-size: 12px;">This is a commercial and residential hub in Hyderabad,
                            Telangana. Located at the
                            crossroads of the main city, it has new residential projects coming up in its vicinity.
                            <a (click)="toggleOpt()" *ngIf="togleOption">Read More</a>

                        </p>

                    </div>
                    <ng-container *ngIf="toggle" class="locality_details">

                        <p style="font-size: 12px; color:#606060">A number of TSRTC buses ply through this area
                            regularly, thus,
                            enabling smooth access to the city and its adjacent suburbs. It is also a major stop and
                            pick up point for long distance buses to Chennai Vijayawada, Rajamahendravaram, Guntur,
                            Nellore, Visakhapatnam, and many other prominent places. </p>
                        <p>Real estate</p>
                        <p style="font-size: 12px;color:#606060">There are several apartment and plot projects
                            coming up in the
                            area by renowned real estate developers.</p>

                        <p> Social infrastructure </p>
                        <p style="font-size: 12px;color:#606060"> Pallavi International School, CBSE Model School,
                            Krishnaveni
                            Talent School, and many others boast of imparting quality education.</p>

                        <p style="font-size: 12px;color:#606060">Sai Sharan Hospitals, Medicare Hospital, Maruthi
                            Hospital,
                            Galaxy Hospitals, Tirumala Multispeciality Hospital, SVS Hospitals, Sri Sai Sagar
                            Hospital, Krishna Sai Multispeciality Hospital,
                            State Bank of India, ICICI Bank, Vijaya Bank, Federal Bank, etc. are some of the major
                            banks that serve this locality.
                            It also boasts of a fair share of other basic social amenities such as ATMs, petrol
                            pumps, bus stops, etc.<a (click)="lessToggle()"
                                style="color: blue; font-size: 11px;"><u>Show Less</u></a>
                        </p>

                    </ng-container>
                </div> <!--Locality guide-->
                <div class="Popluarsection bg-white p-5 mb-4 rounded-2" *ngIf="newListings && newListings.length">
                    <div class="heading">
                        <h2>Exclusive Owner Properties only on Pillar Blocks</h2>
                    </div>
                    <div id="Popluar_slider" class="owl-carousel Popluarslider owl-loaded owl-drag">

                        <div class="item" *ngFor="let listings of newListings">

                            <img src="assets/img/img1.png" alt="curated img" class="img-fluid" *ngIf="!listings.images">
                            <img src="{{listings.images[0]}}" alt="curated img" class="img-fluid"
                                *ngIf="listings.images && listings.images.length">
                            <div class="curatebadge">
                                Easy Home Loans
                            </div>

                            <div class="popluarbottom">
                                <div class="popluarration">
                                    <div class="ratingsec">
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star-fill"></i>
                                        <i class="bi bi-star-fill"></i>
                                        <span>78 reviews</span>
                                    </div>
                                    <div class="location">
                                        <p>{{listings.area}}, {{listings.city}}</p>
                                    </div>
                                </div>
                                <div class="pricetag">
                                    <h2>₹7000 / sqft</h2>
                                    <p>482 Properties for sale</p>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="item">
                        <div class="overlaybg"></div>
                        <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                        <div class="curatebadge">
                            Easy Home Loans
                        </div>
                        <div class="popluarbottom">
                            <div class="popluarration">
                                <div class="ratingsec">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                </div>
                                <div class="location">
                                    <p>Kokapet</p>
                                </div>
                            </div>
                            <div class="pricetag">
                                <h2>₹3000 / sqft</h2>
                                <p>482 Properties for sale</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="overlaybg"></div>
                        <img src="assets/img/image4.png" alt="curated img" class="img-fluid">
                        <div class="curatebadge">
                            Easy Home Loans
                        </div>
                        <div class="popluarbottom">
                            <div class="popluarration">
                                <div class="ratingsec">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                </div>
                                <div class="location">
                                    <p>Kokapet</p>
                                </div>
                            </div>
                            <div class="pricetag">
                                <h2>₹16000 / sqft</h2>
                                <p>482 Properties for sale</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="overlaybg"></div>
                        <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                        <div class="curatebadge">
                            Easy Home Loans
                        </div>
                        <div class="popluarbottom">
                            <div class="popluarration">
                                <div class="ratingsec">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                </div>
                                <div class="location">
                                    <p>Kokapet</p>
                                </div>
                            </div>
                            <div class="pricetag">
                                <h2>₹7000 / sqft</h2>
                                <p>482 Properties for sale</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="overlaybg"></div>
                        <img src="assets/img/image1.png" alt="curated img" class="img-fluid">
                        <div class="curatebadge">
                            Easy Home Loans
                        </div>
                        <div class="popluarbottom">
                            <div class="popluarration">
                                <div class="ratingsec">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                </div>
                                <div class="location">
                                    <p>Kokapet</p>
                                </div>
                            </div>
                            <div class="pricetag">
                                <h2>₹7000 / sqft</h2>
                                <p>482 Properties for sale</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="overlaybg"></div>
                        <img src="assets/img/image4.png" alt="curated img" class="img-fluid">
                        <div class="curatebadge">
                            Easy Home Loans
                        </div>
                        <div class="popluarbottom">
                            <div class="popluarration">
                                <div class="ratingsec">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                </div>
                                <div class="location">
                                    <p>Kokapet</p>
                                </div>
                            </div>
                            <div class="pricetag">
                                <h2>₹7000 / sqft</h2>
                                <p>482 Properties for sale</p>
                            </div>
                        </div>
                    </div> -->

                    </div>
                </div> <!--end Exclusive Owner Properties section-->
                <div class="feature_property bg-white p-5 mb-4 rounded-2" *ngIf="newListings && newListings.length">
                    <div class="heading">
                        <h2>Verified on Pillar Blocks</h2>
                    </div>
                    <div id="verified_slider" class="owl-carousel owl-theme featureText owl-loaded owl-drag">
                        <div class="item" *ngFor="let listings of newListings">
                            <img src="assets/img/img1.png" alt="curated img" class="img-fluid" *ngIf="!listings.images">
                            <img src="{{listings.images[0]}}" alt="curated img" class="img-fluid"
                                *ngIf="listings.images && listings.images.length">

                            <div class="curatebadge">
                                <img src="assets/img/money_with_hand_icon.svg" alt="EMI">
                                2 EMIs Free
                            </div>
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg" *ngFor="let image of listings.images">
                                        <img src="{{image}}" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>{{listings.propertyTitle}}</p>
                                    <!-- <p><span>{{listings.area}}, {{listings.city}}</span></p> -->
                                </div>
                                <h2 class="price">{{property.price | rsbcurrency : property.currency}}</h2>
                            </div>
                        </div>
                        <!-- <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image3.png" alt="curated img" class="img-fluid">
                            <div class="curatebadge">
                                <img src="assets/img/money_with_hand_icon.svg" alt="EMI">
                                2 EMIs Free
                            </div>
                            <label>UNDER CONSTRUCTION</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image4.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image1.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="thumbnail_gallery">
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image2.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image3.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image4.png" alt="feature img">
                                    </div>
                                    <div class="thumbimg">
                                        <img src="assets/img/image1.png" alt="feature img">
                                    </div>
                                </div>
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>     -->
                    </div>
                </div> <!--end Verified on Pillar Blocks section-->
                <div class="feature_property bg-white p-5 mb-4 rounded-2" *ngIf="newListings && newListings.length">
                    <div class="heading">
                        <h2>Properties in Similar Projects</h2>
                    </div>
                    <div id="Similar_slider" class="owl-carousel owl-theme featureText owl-loaded owl-drag">
                        <div class="item" *ngFor="let listings of newListings">
                            <img src="assets/img/img1.png" alt="curated img" class="img-fluid" *ngIf="!listings.images">
                            <img src="{{listings.images[0]}}" alt="curated img" class="img-fluid"
                                *ngIf="listings.images && listings.images.length">

                            <div class="curatebadge">
                                <img src="assets/img/money_with_hand_icon.svg" alt="EMI">
                                2 EMIs Free
                            </div>
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>{{listings.propertyTitle}}</p>
                                    <!-- <p><span>{{listings.area}}, {{listings.city}}</span></p> -->
                                </div>
                                <h2 class="price">{{listings.price | rsbcurrency : property.currency}}</h2>
                            </div>
                        </div>
                        <!-- <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image3.png" alt="curated img" class="img-fluid">
                            <div class="curatebadge">
                                <img src="assets/img/money_with_hand_icon.svg" alt="EMI">
                                2 EMIs Free
                            </div>
                            <label>UNDER CONSTRUCTION</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image4.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image2.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>
                        <div class="item">
                            <div class="overlaybg"></div>
                            <img src="assets/img/image1.png" alt="curated img" class="img-fluid">
                            <label>READY TO MOVE</label>
                            <div class="feature_bottomsec">
                                <div class="feature_area">
                                    <p>2 BHK Flat</p>
                                    <p><span>Miyapur, Hyderabad</span></p>
                                </div>
                                <h2 class="price">₹78 Lac</h2>
                            </div>
                        </div>     -->
                    </div>
                </div> <!--end similar properties section-->
                <div class="details_propservices property_service bg-white px-2 py-5 mb-4 rounded-2">
                    <div class="section_heading">
                        <h2>Property Services</h2>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngFor="let service of ourServices.data">
                            <div class="propertycontent">
                                <div class="service_img">
                                    <img src={{service.image}} alt="packers & movers">
                                </div>
                                <h3>{{service.name}}</h3>
                                <div class="rating">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <span>78 reviews</span>
                                    <p>{{service.content}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> <!--end properties services section-->
                <div class="faq_sec bg-white p-5 mb-4 rounded-2" id="faq">
                    <div class="heading">
                        <h2>Frequently asked Questions</h2>
                    </div>
                    <div class="faq_content">
                        <div class="accordion accordion-flush" id="faqlist1">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-1">
                                        What is a Pre Approved Property ?
                                    </button>
                                </h2>
                                <div id="faq-content-1" class="accordion-collapse collapse show"
                                    data-bs-parent="#faqlist1">
                                    <div class="accordion-body">
                                        Pillarblcoks & builders get their projects ‘pre-approved’ by specific Banks
                                        which do Home loans. The Banker examines the legal documents of the title of
                                        that project, the experience of builder and stage of construction also track
                                        record of builder to complete the project on time. Upon satisfactory they
                                        declares Project or properties in the project to be ‘pre-approved’.
                                        Property buyer need not to go for legal and technical checks in case of a
                                        ‘pre-approved’ property.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-content-2">

                                        What is Pre EMI ?
                                    </button>
                                </h2>
                                <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div class="accordion-body">
                                        You’ve chosen a property that’s yet under construction. So the lender makes
                                        the disbursement in parts based on the progress of the construction of your
                                        property.
                                        However till the housing loan is fully disbursed you have to pay simple
                                        interest at the rate you have agreed upon with the lender. This is known as
                                        the Pre EMI. And from the month following in which the full disbursement is
                                        made you will start paying your EMI. </div>
                                </div>
                            </div>
                            <ng-container *ngIf="seeMoreToggle">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            What is stamp duty ?
                                        </button>
                                    </h2>
                                    <div id="faq-content-3" class="accordion-collapse collapse"
                                        data-bs-parent="#faqlist1">
                                        <div class="accordion-body">
                                            Stamp duty refers to the tax paid to the government just like sales tax
                                            or income tax and
                                            should be paid on time and in full.A stamp duty paid document is an
                                            important and legal
                                            instrument to be taken care of.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            How to choose project / Property ? </button>
                                    </h2>
                                    <div id="faq-content-4" class="accordion-collapse collapse"
                                        data-bs-parent="#faqlist1">
                                        <div class="accordion-body">
                                            A buyer can choose a good Project / Property by existing references from
                                            people, finding out how happy
                                            they are with their homes, construction quality and builder’s conduct
                                            and track record. Knowing costing
                                            factors & value addition from all shortlisted builders in writing,
                                            mentioning all the details & growth
                                            factors of the project. Check quote details like maintenance,
                                            necessary approvals and permissions, required clearance, material
                                            supplies, charges, etc to be verified.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                            Besides the per Sqfeet charges, what are the othet charges in flats and
                                            apartments ? </button>
                                    </h2>
                                    <div id="faq-content-5" class="accordion-collapse collapse"
                                        data-bs-parent="#faqlist1">
                                        <div class="accordion-body">
                                            In the case of multi-storey apartment, the apartment owners and tenants
                                            have to pay for the services and
                                            amenities enjoyed like Preferential Location Charges, External
                                            electrification charges, Firefighting
                                            equipment charges, Infrastructure development charges,
                                            External development charges, Car parking space, Club membership etc.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                            What is stamp duty ?
                                        </button>
                                    </h2>
                                    <div id="faq-content-6" class="accordion-collapse collapse"
                                        data-bs-parent="#faqlist1">
                                        <div class="accordion-body">
                                            Stamp duty refers to the tax paid to the government just like sales tax
                                            or income tax and
                                            should be paid on time and in full.A stamp duty paid document is an
                                            important and legal
                                            instrument to be taken care of.
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="see_more">
                        <a (click)="seeMore()">{{ seeMoreToggle ? 'See less' : 'See more' }} <i
                                class="accordion-button collapsed bi bi-chevron-{{ seeMoreToggle ? 'up' : 'down' }}"
                                data-bs-parent="#faqlist1"></i></a>
                    </div>
                </div> <!---end faq sec-->
                <div class="bottompropertytabs close_search bg-white p-5 mb-4 rounded-2">
                    <div class="heading">
                        <h2>Closer to Your Search</h2>
                    </div>
                    <div id="bottomproperty_slider" class="owl-carousel bottomproplistslider">
                        <div class="item">
                            <div class="property_bottomList">
                                <h2>Popular Residential Searches</h2>
                                <ul class="list-inline">
                                    <li>
                                        <a href="javascript:void(0)">Property for Sale in Hyderabad</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Flats in Hyderabad</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Studio Apartments in Hyderabad</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="property_bottomList">
                                <h2>Popular BHK Searches</h2>
                                <ul class="list-inline">
                                    <li>
                                        <a href="javascript:void(0)">1 BHK Flats in Hyderabad</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">2 BHK Flats in Hyderabad</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">3 BHK Flats in Hyderabad</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">4 BHK Flats in Hyderabad</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="property_bottomList">
                                <h2>Popular Flat Searches</h2>
                                <ul class="list-inline">
                                    <li>
                                        <a href="javascript:void(0)">Flats for Sale in Attapur</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Flats for Sale in Kondapur</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Flats for Sale in Miyapur</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Flats for Sale in Kompally</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="property_bottomList">
                                <h2>Popular House Searches</h2>
                                <ul class="list-inline">
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Jubilee Hills</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Kukatpally</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="item">
                            <div class="property_bottomList">
                                <h2>Popular House Searches</h2>
                                <ul class="list-inline">
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Jubilee Hills</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Kukatpally</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Uppal</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">House for Sale in Banjara Hills</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="view_more">
                        <a href="#">View more</a>
                    </div>
                </div> <!--end closer search-->
                <ng-container *ngIf="property.professionalDetails">
                    <div class="builder_details bg-white p-5 mb-4 rounded-2">
                        <div class="heading">
                            <h2>Builder Details</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="builder_cont">
                                    <img [src]="property.professionalDetails.profilePic ? property.professionalDetails.profilePic : 'assets/img/noimagepic.jpg'">
                                    <button type="button" class="btn btn-primary rounded-pill mt-3" (click)="contact(property)" >Contact
                                        Builder</button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="builder_details">
                                    <h2>{{property.customerName}}</h2>
                                    <p>{{property.professionalDetails.agency}}</p>

                                    <ul class="list-inline label_builder">
                                        <li class="list-inline-item">
                                        </li>
                                        <li class="list-inline-item">
                                        </li>
                                    </ul>
                                    <ul class="list-inline builder_age">
                                        <li class="list-inline-item" *ngIf="property.professionalDetails && property.professionalDetails.city ">
                                            <p><small>Operating in</small></p>
                                            <p>{{property.professionalDetails.city}},{{property.professionalDetails.pincode}}</p>
                                        </li>
                                        <li class="list-inline-item"  *ngIf="property.professionalDetails && property.professionalDetails.operatingSince">
                                            <p><small>Operating Since</small></p>
                                            <p>{{property.professionalDetails.operatingSince}}</p>
                                        </li>
                                        <li class="list-inline-item" *ngIf="property.professionalDetails && property.professionalDetails.soldProperties">
                                            <p><small>Properties for Sale</small></p>
                                            <p>{{property.professionalDetails.soldProperties}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </ng-container> <!--Builder details end-->
                <div class="find_propertysec bg-white p-5 mb-4 rounded-2">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="find_content">
                                <h2>Find Property</h2>
                                <p>Select from 1000s of options, without brokerage.</p>
                                <button class="btn btn-outline-primary rounded-pill">Find Now</button>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="find_content">
                                <h2>List your Property</h2>
                                <p>For free, without any brokerage.</p>
                                <button class="btn btn-outline-primary rounded-pill">Free Posting</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="disclaimer" id="disclaimer" #section>
                        <h4>Disclaimer:</h4>
                        <p>Pillar Blocks has endeavoured to ascertain the requirement of RERA registration. However, the
                            advertiser claims that there is no requirement for At vero eos et accusamus et iusto odio
                            dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos
                            dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                            in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                            quidem rerum facilis est et expedita distinctio. </p>
                    </div> -->
            </div>
        </div>
        <div class="col-lg-2 my-5" *ngIf="!lookupModel.getLoggedUser()">
            <div class="right_fixed" [ngClass]="{'scrolled': isScrolled}">
                <form [formGroup]="signupForm" (ngSubmit)="register()">
                    <div class="contact_seller bg-white px-2 py-3 rounded-3 border">
                        <h4 class="badge">Contact Sellers :</h4>
                        <h5>Please share your contact</h5>
                        <div class="mb-3">
                            <input type="text" name="" class="form-control border-0 border-bottom rounded-0"
                                placeholder="Name" formControlName="firstName">
                            <span *ngIf="firstName.touched && firstName.errors && firstName.errors.required"
                                style="color: red">First Name is requried.</span>
                        </div>
                        <div class="mb-3">
                            <ngx-intl-tel-input [cssClass]="'form-control'" [onlyCountries]="onlyCountries"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="false"
                                [selectFirstCountry]="false" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
                                style="width: 100%;" [phoneValidation]="true" [separateDialCode]="true" id="mobile"
                                [selectedCountryISO]="selectedISOCountry" name="Mobile" formControlName="mobile">
                            </ngx-intl-tel-input>
                            <span class="text-danger" *ngIf="mobile.touched && mobile.errors && mobile.errors.required">
                                Mobile Number is required.
                            </span>
                            <span class="text-danger"
                                *ngIf="mobile.touched && mobile.errors && mobile.errors.validatePhoneNumber">
                                Enter Valid Mobile Number.
                            </span>
                            <span *ngIf="mobile.valid && mobileExists" style="color:red;" id="mobileexists">
                                Mobile Already Exists, Please Login
                            </span>
                            <span *ngIf="mobile.touched && mobile.errors && mobile.errors.required" style="color:red;"
                                id="emailerror">
                                Mobile is required.
                            </span>
                            <span
                                *ngIf="mobile.touched && !mobile.valid && mobile.errors && mobile.errors.validatePhoneNumber"
                                style="color:red;" id="mobileNotValid">
                                Enter Valid Mobile Number.
                            </span>
                            <span *ngIf="!mobileOtpResent && mobileOtpSent && !mobileOtpVerified && !mobileOtpMaxFlag"
                                style="color:green;" id="mobileotpsuccessmsg1">
                                OTP Sent to mobile successfully.
                            </span>
                            <span *ngIf="mobileOtpResent && !mobileOtpVerified" style="color:green;"
                                id="mobileotpsuccessmsg1">
                                OTP Resent to mobile successfully.
                            </span>
                            <span *ngIf="mobileOtpMaxFlag" style="color:red;" id="maxOtpError">
                                Your OTP sending limit is over for today, please try after 24 hours again.
                            </span>
                            <span *ngIf="mobileOtpVerified" style="color:green;" id="emailotpsuccessmsg3">
                                mobile Verified Successfully
                            </span>
                            <span *ngIf="mobileOtpError" style="color:red;" id="exists">
                                Error in sending OTP. Please try again.
                            </span>
                            <span class="text-danger" *ngIf="error_message">{{error_message}}</span>
                            <span class="text-success" *ngIf="success_message">{{success_message}}</span>
                            <ul class="list-inline sendbtn mt-3">
                                <li class="list-inline-item">
                                    <button type="button" class="btn btn-default2" *ngIf="!mobileOtpSent"
                                        [disabled]="mobile.invalid" (click)="sendMobileOtp(mobile.value)">
                                        SEND OTP</button>
                                    <button type="button" class="btn btn-default2" *ngIf="mobileOtpSent"
                                        [disabled]="mobile.invalid" (click)="resendMobileOtp(mobile.value)">
                                        RE-SEND
                                        OTP</button>
                                </li>

                                <li class="list-inline-item">
                                    <p (click)="changeMobile()" class="change_number">Change
                                        Mobile Number</p>
                                    <!-- <button type="button" class="btn btn-warning resend"
                                (click)="changeMobile()">Change
                                Mobile Number</button> -->
                                </li>
                            </ul>
                        </div>

                        <div class="mb-3">
                            <input type="text" class="form-control border-0 border-bottom rounded-0" placeholder="OTP"
                                name="mobileOtp" formControlName="mobileOtp">
                            <p *ngIf="mobileOtp.touched && mobileOtp.errors && mobileOtp.errors.required"
                                style="color:red;" id="emailOtperror">
                                OTP is required.
                            </p>
                            <p *ngIf="mobileOtpCheck && !mobileOtpVerified" style="color:red;" id="mobileOtperror2">
                                OTP is incorrect.
                            </p>

                        </div>

                        <div class="mb-3">
                            <input type="text" name="email" formControlName="email"
                                class="form-control border-0 border-bottom rounded-0" placeholder="Email" name="email"
                                formControlName="email">
                            <p *ngIf="email.touched && email.errors && email.errors.required" style="color:red;"
                                id="emailRequired">
                                Email is required.
                            </p>
                            <p *ngIf="email.errors && email.errors.email" style="color:red;" id="emailerror">
                                Enter a valid Email.
                            </p>
                        </div>


                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="agree" checked>
                            <label class="form-check-label" for="agree">
                                I agree to be contacted by agents.
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="Loans">
                            <label class="form-check-label" for="Loans">
                                I am interested in Home Loans
                            </label>
                        </div>
                        <div class="condetails">
                            <button class="btn btn-primary">Get contact details</button>
                        </div>
                    </div>
                </form>
                <!-- <div class="saveproperty bg-white px-2 py-3 rounded-3 border mt-3 text-center">
                        <h2>Save this property for now & view it anytime.</h2>
                        <button class="btn btn-outline-primary rounded-pill"><i class="bi bi-heart"></i> save</button>
                    </div>
                    <button class="btn btn-share"><i class="bi bi-share"></i> Share</button> -->
            </div>
        </div>
    </div>
    </div>

</main>
