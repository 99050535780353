<div class="card padding-card t-align-l no-radius border">
    <form [formGroup]="locationForm" (ngSubmit)="submit(locationForm.value)" #locForm="ngForm">
        <div class="card-body" *ngIf="loadView">
            <h5 class="text-primary card-title mb-4">PG Location</h5>
            <h6 class="text-dark mb-3">Where is your Pg Hostel Located? <small class="text-danger">*
                    All fields in this section are required</small> </h6>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="country">Country <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <!-- <input type="text" class="form-control" placeholder="Country" id="country" name="country"
                            formControlName="country"> -->
                        <select name="country" id="country" class="form-control" formControlName="country">
                            <option value="">Select</option>
                            <option *ngFor="let count of countryList; let i = index;" [value]="count.codeId">{{count.countryName}}</option>
                        </select>
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" [disabled]="changeCountryFlag" (click)="changeCountry()">
                                <i class="far fa-edit"></i> Change Country
                            </button>
                        </div>
                    </div>
                    <span *ngIf="(locForm.submitted || country.touched) && country.errors && country.errors.required"
                        style="color: red">Country is required.</span>
                </div>
                <ng-container *ngIf="!changeCountryFlag">
                    <div class="form-group col-md-4">
                        <label for="city">City <span class="text-danger">*</span></label>
                        <input type="text" #cityText class="form-control" placeholder="Enter City" id="city" name="city"
                            formControlName="city">
                        <span *ngIf="(locForm.submitted || city.touched) && city.errors && city.errors.required" style="color: red">City
                            is required.</span>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="locality">Locality <span class="text-danger">*</span></label>
                        <input type="text" #localityText class="form-control" placeholder="Enter Locality/Area" id="locality" name="locality"
                            formControlName="locality">
                        <span *ngIf="(locForm.submitted || locality.touched) && locality.errors && locality.errors.required"
                            style="color: red">Locality is required.</span>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="pincode">{{selectedCountry.codeType}}<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter Zip/Postal" id="pincode" name="pincode"
                            formControlName="pincode">
                        <span *ngIf="(locForm.submitted || pincode.touched) && pincode.errors && pincode.errors.required"
                            style="color: red">{{selectedCountry.codeType}} is required.</span>
                            <span *ngIf="pincode.dirty && pincode.errors && pincode.errors.pattern"
                style="color: red"> Only numbers are allowed.</span>
                    </div>
                    <div class="form-group col-md-8">
                        <label for="addressLine1">Address </label>
                        <input type="text" class="form-control" placeholder="Enter Address" id="addressLine1" name="addressLine1"
                            formControlName="addressLine1">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="landmark">Landmark </label>
                        <input type="text" #landmarkText class="form-control" placeholder="Landmark" id="landmark" name="landmark"
                            formControlName="landmark">
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="!changeCountryFlag">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [restriction]="mapRestriction">
                            <agm-marker [latitude]="lat" [longitude]="long" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                        </agm-map>
                    </div>
                </div>
            </ng-container>

            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30443.899733975584!2d78.37310644165628!3d17.484228666411095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91b5be1f29c3%3A0xf5af71d23e422328!2sKukatpally%20Housing%20Board%20Colony%2C%20Kukatpally%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1588059216344!5m2!1sen!2sin"
                width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe> -->
            <h6>Fill out all required information fields to move forward. </h6>
        </div>


        <button type="submit" class="btn btn-primary btn-lg mb-3 float-right mr-2">Next</button>
    </form>

</div>