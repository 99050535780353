<div class="container-fluid" *ngIf="!isMobileLayout">
  <div class="pb-wrapper bg-white p-4">
    <form #agentForm="ngForm" class="agent-profile" (ngSubmit)="onSubmit(agentForm)">
      <div class="form-row mb-5">
        <div class="col">
          <input type="text" name="name" class="form-control" placeholder="Name" [(ngModel)]="member.name" required>
        </div>
        <div class="col">
          <input type="text" name="mobileNumber" class="form-control" placeholder="Mobile Number"
            [(ngModel)]="member.mobileNumber" required>
        </div>
        <div class="col">
          <button type="submit" class="btn btn-primary" [disabled]="!agentForm.valid">Add Member</button>
        </div>
      </div>
    </form>



    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="all-leads mt-5">
          <div class="card shadow mb-4">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h3 class="d-inline-block mr-3 mb-0" style="color: rgb(177, 128, 128);">Team Members</h3>
                <span>Search:
                  <input type="text" placeholder="Search Members" [(ngModel)]="searchText"
                    (ngModelChange)="onSearchChange()">
                </span>
              </div>
              <div class="table-responsive">
                <table class="table table-hover agent-table">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Email ID</th>
                      <th>Date Added</th>
                      <th>City</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let member of filteredMembers | paginate: { itemsPerPage: itemsPerPage, currentPage: p };let i=index">
                      <td>{{i+1}}</td>
                      <td>{{ member.personalDetails.firstName }}</td>
                      <td>{{ member.personalDetails.mobile || 'N/A' }}</td>
                      <td>{{ member.personalDetails.email || 'N/A' }}</td>
                      <td>{{ member.createdAt | date: 'dd-MM-yyyy' }}</td>
                      <td>{{ member.city || 'N/A' }}</td>
                      <td>
                        <span class="badge" [ngClass]="member.activeStatus === 1 ? 'bg-success' : 'bg-info'">
                          {{ member.activeStatus === 1 ? 'Active' : 'In Active' }}
                        </span>
                      </td>
                       </tr>
                  </tbody>
                </table>
              </div>
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-mb-my-team *ngIf="isMobileLayout"></app-mb-my-team>