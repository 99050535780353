import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-mb-land-survey',
  templateUrl: './mb-land-survey.component.html',
  styleUrls: ['./mb-land-survey.component.css']
})
export class MbLandSurveyComponent implements OnInit {
  surveyList:Array<any> = [];
  landSurveyForm:FormGroup;
  landsurveyTableHeaders = ['Project Location', 'No Of Acrs', 'Budget','Customer Name','Mobile Number','Email Id','Description','Actions'];
  landsurveyTableData: any[] = [];
  constructor(private fb:FormBuilder,
    private rsbService:RsbService
  ) { }
  ngOnInit(): void {
    this.getSurvey()
    this.landSurveyForm=this.fb.group({
      acrs:['',[Validators.required]],
      Budget:['',[Validators.required]],
      location:['',[Validators.required]],
      cName:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required]],
      description:['',[Validators.required]]
      
    })
  }

  getSurvey(): void {
    this.rsbService.getLandSurvey().subscribe((rsp) => {
      if(rsp.statusCode === 0) {
        this.surveyList = rsp.contents;
        this.landsurveyTableData = rsp.contents.map(record => ({
          'Project Location': record.location,
          'No Of Acrs': record.acrs,
          'Budget': record.budget,
          'Customer Name': record.cName,
          'Mobile Number':record.mobile,
          'Email Id': record.email,
          'Description': record.description,
          'Actions': 'delete'
        }));
        
      }
    })
  }
  submit(){
    if (this.landSurveyForm.invalid) {
      Object.values(this.landSurveyForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return;
    }
    const args={
      acrs:this.acrs.value,
      budget:this.budget.value,
      location:this.location.value,
      cName:this.cName.value,
      mobile:this.mobile.value,
      email:this.email.value,
      description:this.description.value,
    }
    this.rsbService.surveyForLand(args).subscribe((rsp)=>{
      if(rsp.statusCode===0){
        this.getSurvey();
        this.landSurveyForm.reset()

      }

    })

  }
  get acrs() {return this.landSurveyForm.get('acrs')}
  get budget() {return this.landSurveyForm.get('Budget')}
  get location() {return this.landSurveyForm.get('location')}
  get cName() {return this.landSurveyForm.get('cName')}
  get mobile() {return this.landSurveyForm.get('mobile')}
  get email() {return this.landSurveyForm.get('email')}
  get description() {return this.landSurveyForm.get('description')}


}


