<header>
    <nav class="navbar navbar-light fixed-top bg-danger" *ngIf="!navSideBar">
        <div class="container">
            <div id="main-nav">
                <ul class="nav">
                    <li class="head-nav">
                        <div class="d-flex align-items-center my-2">
                            <img src="{{personalDetails.profilePic}}" width="50px" alt="profile image pillarBlock"
                                class="rounded-4 p-1 bg-white me-2">
                            <h5>{{personalDetails.firstName}}</h5>
                        </div>
                    </li>
                    <ng-container *ngFor="let menu of menuOptions">
                        <!-- <li class="nav-item" [ngClass]="{'active': menu.active}"> -->
                            <!-- <a class="nav-link" (click)="openMenuItem(menu)">
                                <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                {{menu.label}}
                                <span *ngIf="menu.label === 'Nexus AddOn'" (click)="isNexusAddonOpen = !isNexusAddonOpen">
                                    <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}"></i>
                                </span>
                            </a> -->
                            <li class="nav-item" [ngClass]="{'active': menu.active}">
                                <a class="nav-link" (click)="openMenuItem(menu)">
                                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                    <span>{{menu.label}}</span>
                                    <span *ngIf="menu.label === 'Nexus AddOn'" (click)="isNexusAddonOpen = !isNexusAddonOpen">
                                        <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}"></i>
                                    </span>
                                </a>
                            
                            
                                <!-- <ul *ngIf="menu.label === 'Nexus AddOn' && isNexusAddonOpen" class="submenu-list"> -->
                                <!-- <li *ngFor="let submenu of menu.submenuList" class="nav-item"  [ngClass]="{'active': submenu.active}">
                                                                <a class="nav-link" (click)="openSubmenuItem(menu, submenu)">
                                                                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                                
                                                                    {{submenu.label}}
                                                                </a>
                                                            </li> -->
                                <ul *ngIf="menu.label === 'Nexus AddOn' && isNexusAddonOpen" class="submenu-list">
                            
                                    <li *ngFor="let submenu of menu.submenuList" class="nav-item" [ngClass]="{'active': submenu.active}">
                                        <a class="nav-link" (click)="openSubmenuItem(menu, submenu)">
                                            <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                            <span>{{submenu.label}}</span>
                            
                                        </a>
                                    </li>
                                </ul>
                                <!-- </ul> -->
                            </li>
                    </ng-container>
               
             
                </ul>
                <ul class="bottom-nav">
                    <li class="notification-icon">
                        <a href="#">
                            <i class="ri-notification-2-line"></i>
                        </a>
                    </li>
                    <li class="email-icon">
                        <a href="#">
                            <i class="ri-question-line"></i>
                        </a>
                    </li>
                    <li class="setting-icon">
                        <a href="#">
                            <i class="ri-settings-3-line"></i>
                        </a>
                    </li>

                </ul>
            </div>

            <a class="toggle toggle hc-nav-trigger hc-nav-1" (click)="toggleNav()">
                <span></span>
            </a>
            <div class="brand-logo"><a href="javascript:;"><img src="assets/img/pillar-block-logo-white.png"
                        alt="PillarBlock"></a></div>
        </div>
    </nav>
    <nav class="hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-left disable-body is-ios touch-device" [class.nav-open]="navSideBar">
        <div class="nav-container">
     <div class="nav-wrapper nav-wrapper-0">
        <div class="nav-content">
            <!-- <button type="button" (click)="closeNav()">&times;</button> -->
            <!-- <span (click)="closeNav()" style="float: right;"><img src="assets/mobile/img/closeicon.png" style="width: 25px;height: auto;"></span> -->
                  <!-- <img src="assets/mobile/img/closeicon.png" (click)="closeNav()" style="width: 25px; height: auto;"> -->
                  <div style="position: relative;">
                    <span class="hide" style="position: absolute; right: 0;">
                      <img src="assets/mobile/img/closeicon.png" style="width: 36px; height: auto;" (click)="closeNav()">
                    </span>
                  </div>
              
              
                  <ul class="nav">
                    <li class="head-nav">
                        <div class="d-flex align-items-center my-2">
                            <img src="{{personalDetails.profilePic}}" width="50px" alt="profile image pillarBlock"
                                class="rounded-4 p-1 bg-white me-2">
                            <h5>{{personalDetails.firstName}}</h5>
                        </div>
                    </li>
                    <ng-container *ngFor="let menu of menuOptions">
                        <!-- <li class="nav-item" [ngClass]="{'active': menu.active}"> -->
                            <!-- <a class="nav-link" (click)="openMenuItem(menu)">
                                <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                {{menu.label}}
                                <span *ngIf="menu.label === 'Nexus AddOn'" (click)="isNexusAddonOpen = !isNexusAddonOpen">
                                    <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}"></i>
                                </span>
                            </a> -->
                            <li class="nav-item" [ngClass]="{'active': menu.active}">
                                <a class="nav-link" (click)="openMenuItem(menu)">
                                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                    <span>{{menu.label}}</span>
                                    <span *ngIf="menu.label === 'Nexus AddOn'" (click)="isNexusAddonOpen = !isNexusAddonOpen">
                                        <i [ngClass]="{'icon-chevron-down': !isNexusAddonOpen, 'icon-chevron-up': isNexusAddonOpen}"></i>
                                    </span>
                                </a>
                            
                            
                                <!-- <ul *ngIf="menu.label === 'Nexus AddOn' && isNexusAddonOpen" class="submenu-list"> -->
                                <!-- <li *ngFor="let submenu of menu.submenuList" class="nav-item"  [ngClass]="{'active': submenu.active}">
                                                                <a class="nav-link" (click)="openSubmenuItem(menu, submenu)">
                                                                    <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                                
                                                                    {{submenu.label}}
                                                                </a>
                                                            </li> -->
                                <ul *ngIf="menu.label === 'Nexus AddOn' && isNexusAddonOpen" class="submenu-list">
                            
                                    <li *ngFor="let submenu of menu.submenuList" class="nav-item" [ngClass]="{'active': submenu.active}">
                                        <a class="nav-link" (click)="openSubmenuItem(menu, submenu)">
                                            <img class="icon icon-small" [src]="menu.sideMenuicon" alt="">&nbsp;
                                            <span>{{submenu.label}}</span>
                            
                                        </a>
                                    </li>
                                </ul>
                                <!-- </ul> -->
                            </li>
                    </ng-container>
               
             
                </ul>
            <ul class="bottom-nav">
                <li class="notification-icon"><a href="#" class="nav-item">
                        <i class="ri-notification-2-line"></i>
                    </a></li>
                <li class="email-icon"><a href="#" class="nav-item">
                        <i class="ri-question-line"></i>
                    </a></li>
                <li class="setting-icon"><a href="#" class="nav-item">
                        <i class="ri-settings-3-line"></i>
                    </a></li>
            </ul>
        </div>
    </div>
</div>
    </nav>
</header>