<div class="container-fluid">
    <div class="pb-wrapper bg-white p-4">
        <h2 class="mb-4">Sales</h2>
        
        <form class="agent-profile">
            <div class="form-row mb-5">
                <div class="col">
                    <label for="Name">Client Name</label>
                    <input type="text" class="form-control" placeholder="Type here">
                </div>
                <div class="col">
                    <label for="Agency Name">Mobile Number</label>
                    <input type="text" class="form-control" placeholder="Type here">
                </div>
                <div class="col">
                    <label for="RERA Number">Email Id</label>
                    <input type="text" class="form-control" placeholder="Type here">
                </div>
                <div class="col">
                    <label for="Operating since">City</label>
                    <select class="form-control">
                        <option value="">Select</option>
                        <option value="">Hyderabad</option>
                        <option value="">Vizag</option>
                        <option value="">Vijayawada</option>
                        <option value="">Benagulur</option>
                    </select>
                </div>
            </div>
            <div class="form-row my-4">
                <div class="col-lg-3">
                    <label for="Operating since">Satus</label>
                    <select class="form-control">
                        <option value="">Select</option>
                        <option value="">In Progress</option>
                        <option value="">New</option>
                        <option value="">Closed</option>
                    </select>
                </div>
            </div>
            <button class="btn btn-primary pb-save">save</button>
            <button class="btn  pb-addproject ml-3"><i class="ri-add-line"></i> create another lead</button>
        </form>
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="all-leads mt-5">
                    <div class="card shadow mb-4">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <form>
                                    <h3 class="d-inline-block mr-3 mb-0">All Leads <span class="badge badge-pill badge-secondary">26</span></h3>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" checked id="Leads" name="example" value="customEx">
                                        <label class="custom-control-label" for="Leads">All Leads</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="Viewed" name="example" value="customEx">
                                        <label class="custom-control-label" for="Viewed">Viewed Leads</label>
                                    </div>
                                </form>
                                <div class="right-search">
                                  <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover agent-table">
                                <thead class="">
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>Date Added</th>
                                        <th>Follow up Date</th>
                                        <th>City</th>
                                        <th>Remarks</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Priya Kumar</td>
                                        <td>81475 35541</td>
                                        <td>priyakumar@gmail.com</td>
                                        <td>15-03-2024</td>
                                        <td>25-03-2024</td>  
                                        <td>Hyderabad</td> 
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td>
                                            <select class="form-control btn btn-light text-left" id="staus">
                                                <option>In Progress</option>
                                                <option>New</option>
                                                <option>Lost</option>
                                                <option>closed</option>
                                            </select>
                                        </td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>Priya Kumar</td>
                                        <td>81475 35541</td>
                                        <td>priyakumar@gmail.com</td>
                                        <td>15-03-2024</td>
                                        <td>25-03-2024</td>  
                                        <td>Hyderabad</td> 
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td>
                                            <select class="form-control btn btn-light text-left" id="staus">
                                                <option>New</option>
                                                <option>In Progress</option>
                                                <option>Lost</option>
                                                <option>closed</option>
                                            </select>
                                        </td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>Priya Kumar</td>
                                        <td>81475 35541</td>
                                        <td>priyakumar@gmail.com</td>
                                        <td>15-03-2024</td>
                                        <td>25-03-2024</td>  
                                        <td>Hyderabad</td> 
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td>
                                            <select class="form-control btn btn-light text-left" id="staus">
                                                <option>Lost</option>
                                                <option>In Progress</option>
                                                <option>New</option>
                                                <option>closed</option>
                                            </select>
                                        </td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                    <tr>
                                        <td>Priya Kumar</td>
                                        <td>81475 35541</td>
                                        <td>priyakumar@gmail.com</td>
                                        <td>15-03-2024</td>
                                        <td>25-03-2024</td>  
                                        <td>Hyderabad</td> 
                                        <td>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ..</td>
                                        <td>
                                            <select class="form-control btn btn-light text-left" id="staus">
                                                <option>closed</option>
                                                <option>In Progress</option>
                                                <option>New</option>
                                                <option>Lost</option>
                                            </select>
                                        </td>
                                        <td class="text-center"><a href=""><i class="ri-edit-line"></i></a></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    

</div>