import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-associate',
  templateUrl: './add-associate.component.html',
  styleUrls: ['./add-associate.component.css']
})
export class AddAssociateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
