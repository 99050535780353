<section class="profile">
    <div class="container">
        <form [formGroup]="profileForm" (ngSubmit)="update()" #proForm="ngForm">
            <div class="p-4">
                <h3>Update Password</h3>
                <div class="col-lg-6">
                    <div class="profile-head">

                        <div>
                            <!-- <div class="form-group">
                                <label>New Password<span class="text-danger">*</span></label>
                                <input type="{{ hidePassword ? 'password' : 'text' }}" class="form-control" id="newpassword" name="newpassword" formControlName="newpassword">
                                <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                    <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                </a>
                                <span
                                    *ngIf="(proForm.submitted || newpassword.touched) && newpassword.errors && newpassword.errors.required"
                                    style="color: red">New Password is requried.</span>
                            </div> -->
                            <div class="form-group">
                                <label>New Password<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input type="{{ hidePassword ? 'password' : 'text' }}" class="form-control" id="newpassword" name="newpassword"
                                        formControlName="newpassword">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0)" class="input-group-text" (click)="togglePassword()">
                                            <i class="bi" [ngClass]="hidePassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                        </a>
                                    </div>
                                </div>
                                <span *ngIf="(proForm.submitted || newpassword.touched) && newpassword.errors?.required" style="color: red">
                                    New Password is required.
                                </span>
                            </div>
                            
                            <div class="form-group">
                                <label>Confirm Password<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input type="{{ hideConfirmPassword ? 'password' : 'text' }}" class="form-control" id="confirmpassword"
                                        name="confirmpassword" formControlName="confirmpassword">
                                    <div class="input-group-append">
                                        <a href="javascript:void(0)" class="input-group-text" (click)="toggleConfirmPassword()">
                                            <i class="bi" [ngClass]="hideConfirmPassword ? 'bi-eye' : 'bi-eye-slash'"></i>
                                        </a>
                                    </div>
                                </div>
                                <span *ngIf="(proForm.submitted || confirmpassword.touched) && confirmpassword.errors?.required" style="color: red">
                                    Confirm Password is required.
                                </span>
                            </div>
                              
                            
                            
                        


                        </div>
                        <div class="col-lg-2">
                            <button type="submit" class="btn btn-warning" name="btnAddMore">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>